import moment from "moment";
import {
  checkArray,
  checkNull,
  getRandomString,
  parseRound,
} from "../../../helpers";


export const item_detail = (
  paymentBody,
  table_detail,
  recent_order_detail,
  selectedTip
) => {
  let res = [];
  if (checkArray(paymentBody?.menu_item)) {
    res = paymentBody?.menu_item.map((item) => {
      return {
        quantity: item?.menu_item_quantity ?? 0,
        item_name: checkNull(item?.menu_item_name),
        price: `${table_detail?.currency ?? ""}${parseRound(
          parseFloat(item?.menu_item_price) *
            parseInt(item?.menu_item_quantity ?? 0)
        )}`,
      };
    });
  }
  return res;
};

export const getTotalAmount = (
  paymentType,
  splitPaymentArr,
  splitPayIndex,
  paymentTip,
  cartAmount,
  customSplitInput,
  splitType
) => {
  let res = 0;
  if (paymentType == "whole") {
    res = parseFloat(paymentTip) + parseFloat(cartAmount);
  } else if (paymentType == "split") {
    if (splitType == 1) {
      res = parseFloat(paymentTip) + parseFloat(cartAmount);
    } else if (splitType == 2) {
      let totalChargableAmt = 0;
      if (checkArray(splitPaymentArr?.[splitPayIndex]?.split_items)) {
        splitPaymentArr[splitPayIndex].split_items
          .filter((p) => p.quantity > 0)
          .map((item) => {
            totalChargableAmt +=
              parseFloat(item?.quantity) * parseFloat(item?.menu_total_price);
          });
      }
      res = parseFloat(paymentTip) + parseFloat(totalChargableAmt);
    } else if (splitType == 3) {
      res = parseFloat(paymentTip) + parseFloat(customSplitInput);
    }
  }
  return parseRound(res, 2, true);
};

export const getBillNo = (
  recent_order_detail,
  paymentType,
  splitPaymentArr,
  splitPayIndex
) => {
  const orderId = recent_order_detail?.order_list?.[0]?.order_id ?? undefined;
  let res = `N/A`;
  if (orderId) {
    res = `${orderId}-1`;
    if (paymentType == "split") {
      res = `${orderId}-${splitPaymentArr?.[splitPayIndex]?.split_number}`;
    }
  }
  return res;
};

export const mailOrderDetail = (
  merchant_id,
  amount,
  table_detail,
  paymentBody,
  recent_order_detail,
  selectedTip,
  paymentType,
  splitPaymentArr,
  splitPayIndex,
  paymentTip,
  customSplitInput,
  splitType,
  payWith,
  setPayWith,
  cartAmount
) => {
  // const paymentMethodObj = table_detail?.QrPaymentMethod?.[payment_method];

  return {
    // email: customer_email,
    logo: table_detail?.Merchant?.logo,
    date: moment().format("MMMM D, yyyy  h:mma"),
    paid_amount: `${table_detail?.currency ?? ""}${getTotalAmount(
      merchant_id,
      amount,
      table_detail,
      paymentBody,
      recent_order_detail,
      selectedTip,
      paymentType,
      splitPaymentArr,
      splitPayIndex,
      paymentTip,
      cartAmount,
      customSplitInput,
      splitType,
      payWith,
      setPayWith,
    )}`,
    storeName: table_detail?.Merchant?.account_name,
    item_detail: item_detail(
      table_detail,
      paymentBody,
      recent_order_detail,
      selectedTip,
      paymentType,
      splitPaymentArr,
      splitPayIndex
    ),
    billId: getBillNo(
      table_detail,
      paymentBody,
      recent_order_detail,
      selectedTip,
      paymentType,
      splitPaymentArr,
      splitPayIndex
    ),
    table_number: table_detail?.PosTableMaster?.table_number,
    // payment_method_name: checkNull(
    //   paymentMethodObj?.payment_method_name,
    //   "N/A"
    // ),
    payment_method_name: 
      "N/A",
    subTotal: `${table_detail?.currency ?? ""}${parseRound(
      paymentBody?.order_total
    )}`,
    discount: `(${table_detail?.currency ?? ""}${parseRound(
      paymentBody?.discount
    )})`,
    service_charge: `${table_detail?.currency ?? ""}${parseRound(
      paymentBody?.service_charge
    )}`,
    sales_tax: `${table_detail?.currency ?? ""}${parseRound(
      paymentBody?.sales_tax
    )}`,
    tips: `${table_detail?.currency ?? ""}${parseRound(selectedTip ?? 0)}`,
  };
};
export const finserverPaymentPayload = (
  payment,
  merchant_id,
  amount,
  table_detail,
  paymentBody,
  recent_order_detail,
  selectedTip,
  paymentType,
  splitPaymentArr,
  splitPayIndex,
  paymentTip,
  customSplitInput,
  splitType,
  payWith,
  setPayWith,
  cartAmount
) => {
  return {
    temp_transaction_id: getRandomString(),
    order_detail: mailOrderDetail(
      merchant_id,
      amount,
      table_detail,
      paymentBody,
      recent_order_detail,
      selectedTip,
      paymentType,
      splitPaymentArr,
      splitPayIndex,
      paymentTip,
      customSplitInput,
      splitType,
      payWith,
      setPayWith,
      cartAmount
    ),
    merchant_id: merchant_id,
    store_id: table_detail?.QrOrdering?.merchant_partnerid,
    data: {
      paymentData: payment?.token?.paymentData,
      paymentMethod: payment?.token?.paymentMethod,
      transactionIdentifier: payment?.token?.transactionIdentifier,
      currency: "SGD",
      amount: amount,
    },
  };
};
