
import { finserverPaymentPayload } from "./helper";
import { finservePayment, getApplePaySession, getPaymentDetail } from "../../../Redux/actions";

 export const applePayButtonClicked =  (
      amount,
      payWith,
      setPayWith,
      selectedTip,
      splitPayIndex,
      paymentTip,
      cartAmount,
      customSplitInput,
      paymentBody,
      recent_order_detail,
      paymentType,
      splitPaymentArr,
      splitType,
      table_detail,
      dispatch
  ) => {

    const paymentMethod = table_detail?.QrPaymentMethod
    const supported_cards = paymentMethod.map(data=>data.payment_method_name.toLowerCase().trim())
    // const cleanedArray1 = array1.map(item => item.toLowerCase().trim());

    // const array1 = ["amex", "Mastercard", "stripe"];
    

const array2 = ["amex", "visa", "mastercard","discover","jcb","maestro","electron"];
for (let i = supported_cards.length - 1; i >= 0; i--) {
  if (!array2.includes(supported_cards[i])) {
    supported_cards.splice(i, 1);
  }
}


   
    try {
      var paymentRequest = {
        countryCode: "SG",
        currencyCode: "SGD",
        total: {
          label: "Total",
          type: "final",
          amount:amount,
        },
        supportedNetworks:supported_cards,
        merchantCapabilities: ["supports3DS"],
      };

      var session = new window.ApplePaySession(2, paymentRequest);
      session.oncancel = function (event) {
        console.log("oncancel event triggered");
        console.log(event);
      };

      session.onvalidatemerchant = function (event) {
        var validationURL = event.validationURL;
        const payload = {
          url: validationURL,
          merchant_id: table_detail?.Merchant?.id,
        };
        dispatch(
          getApplePaySession(payload, (response) => {
            session.completeMerchantValidation(response);
          })
        );
      };

      session.onpaymentauthorized = function (event) {
        const payment = event.payment;
        if (payment) {
          // setPaymentData(payment);
          const payload = finserverPaymentPayload(
            payment,
            table_detail?.Merchant?.id,
            amount,
            table_detail,
            paymentBody,
            recent_order_detail,
            selectedTip,
            paymentType,
            splitPaymentArr,
            splitPayIndex,
            paymentTip,
            cartAmount,
            customSplitInput,
            splitType,
            payWith,
            setPayWith,
            cartAmount,
            customSplitInput
          );
          dispatch(
            finservePayment(payload, (data) => {
              
              if (data?.transactionStatus == "APPROVED") {
                session?.completePayment(
                  window?.ApplePaySession?.STATUS_SUCCESS
                );
                const getPaymentBody = {
                  merchant_id: table_detail?.Merchant?.id,
                  storeId: table_detail?.QrOrdering?.merchant_partnerid,
                  transactionId: data?.ipgTransactionId,
                };
                dispatch(getPaymentDetail(getPaymentBody, true));
              } else {
                session?.completePayment(
                  window?.ApplePaySession?.STATUS_FAILURE
                );
              }
            })
          );
        }
      };
      session.begin();
    } catch (e) {
      console.log("Apple pay error:", e);
      alert(
        `The payment cannot be completed using Apple Pay on your device. Please check for active payment methods in your wallet' : ${e}`
      );
    }
  };