import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Motion, spring, presets } from "react-motion";

import Header from "../Components/Header";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";
import { checked, linkHeart } from "../../Assets";
import { checkArray, checkNull, parseRound } from "../../helpers";
import moment from "moment";
import { changePaymentDetail, updateRatingDetail } from "../../Redux/actions";
import RatingInput from "../Components/RatingInput";
import PaymentConfirm from "./PaymentConfirm";
import { StyledSVG } from "../../Utils/style";
import { useSwipeable, config } from "react-swipeable";


function PaymentSuccess({ setShowPaySuccessPage, style, successPageState }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const recent_order_detail = useSelector(
    (state) => state.cartDetailReducer?.recent_order_detail
  );
  const user_detail = useSelector(
    (state) => state.authDetailReducer.user_detail
  );
  const { splitPaymentArr, paymentBody, selected_payment_method, paymentType } =
    useSelector((state) => state.paymentDetailReducer);
  const location = useLocation();

  const [paymentDetail, setPaymentDetail] = useState(null);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);

  const [selectedSplitPay, setSelectedSplitPay] = useState(null);
  const isRemainingpayment =
    checkArray(splitPaymentArr) &&
    checkArray(
      splitPaymentArr.filter((p) => p?.isPaid === 0 || p?.isPaid === false)
    )
      ? true
      : false;

  const [slidePayConfirmPage, setSlidePayConfirmPage] = useState(false);
  const [showPayConfirmPage, setShowPayConfirmPage] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);

  const [pageStyle, setPageStyle] = useState({
    ...style,
    zIndex: 12,
  });

  useEffect(() => {
    if (successPageState) {
      setPaymentDetail(successPageState);
      setSelectedSplitPay(
        splitPaymentArr[successPageState?.splitPayIndex] ?? null
      );
    }
  }, [successPageState]);

  useEffect(() => {
    setPageStyle({ ...pageStyle, ...style });
  }, [style]);

  const togglePayConfirmPopUp = useCallback(
    (event) => {
      // event.preventDefault();
      if (showPayConfirmPage) {
        setPageStyle({ ...pageStyle, zIndex: 10 });
        setSlidePayConfirmPage(false);
        setTimeout(() => {
          setShowPayConfirmPage(!showPayConfirmPage);
        }, 500);
      } else {
        setPageStyle({ ...pageStyle, zIndex: 12 });
        setShowPayConfirmPage(!showPayConfirmPage);
        setTimeout(() => {
          setSlidePayConfirmPage(true);
        }, 100);
      }
    },
    [showPayConfirmPage, slidePayConfirmPage, style, pageStyle]
  );

  const ratingChangeHandler = useCallback(
    (value) => {
      const merchantId = paymentBody?.merchant_id;
      const customerId = paymentBody?.qr_customer_id;

      dispatch(
        updateRatingDetail({
          rating: value ?? "",
          merchant_id: merchantId ?? "",
          customer_id: customerId ?? "",
          order_id: paymentBody?.order_offline_id ?? "",
          review: "",
        })
      );
    },
    [paymentBody]
  );

  const getItemsList = () => {
    let res = null;
    if (checkArray(paymentBody?.menu_item)) {
      res = paymentBody?.menu_item.map((item) => {
        return (
          <tr>
            <td>
              <strong>{item?.menu_item_quantity ?? 0}</strong> x{" "}
              {checkNull(item?.menu_item_name)}
            </td>
            <td className="text-right">
              {" "}
              {`${table_detail?.currency ?? ""}${parseRound(
                parseFloat(item?.menu_item_price) *
                  parseInt(item?.menu_item_quantity ?? 0)
              )}`}{" "}
            </td>
          </tr>
        );
      });
    }
    return res;
  };

  const calculateIsToolBarStatus = () => {
    let res = false;
    let maxHeight = window.outerHeight - window.innerHeight;
    if (maxHeight > 120) {
      res = true;
    }
    return res;
  };

  const [totalHeight, setTotalHeight] = useState(window.innerHeight);
  const totalHeightRef = useRef(totalHeight);
  totalHeightRef.current = totalHeight;
  const [isToolBarHide, setIsToolBarHide] = useState(
    calculateIsToolBarStatus()
  );

  useEffect(() => {
    setIsToolBarHide(calculateIsToolBarStatus());
  }, [totalHeight]);

  useEffect(() => {
    const rootEl = document.getElementById("root");
    if (rootEl) {
      rootEl.classList.add("success-ms");
    }
    window.addEventListener("resize", function (event) {
      setTotalHeight(window.innerHeight);
    });

    return () => {
      if (rootEl) {
        rootEl.classList.remove("success-ms");
      }
      if(!isRemainingpayment)
      {
         history.push("/session-expired");
      }

      window.removeEventListener("resize", (e) => {});
    };
  }, []);

  const getBillNo = () => {
    const orderId = recent_order_detail?.order_list?.[0]?.order_id ?? "";
    let res = `${orderId}-1`;
    if (paymentType == "split") {
      res = `${orderId}-${selectedSplitPay?.split_number}`;
    }
    return res;
  };

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        isHeaderAllUp === true
      ) {
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setHideHeader(true);
      }
    },
    ...config,
  });


  function getCartDetail() {
    if (table_detail?.PosTableMaster && table_detail?.Merchant) {
      return (
        <>
          <Motion
            defaultStyle={{ x: 100 }}
            style={{
              x: spring(slidePayConfirmPage === true ? 0 : 100, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => {
              return showPayConfirmPage ? (
                <PaymentConfirm
                  style={{
                    right: `-${x}%`,
                  }}
                  setShowPayConfirmPage={undefined}
                />
              ) : null;
            }}
          </Motion>
          <div
            className={`wrapper cart_wrapper custom_pb overflow-auto w-100 success-con gry-bg ${
              isToolBarHide ? "" : "toolbar-vh"
            }`}
            style={pageStyle}
            onScroll={scrolledElement}
            {...handlers}
          >
            <div className=" item-align-center">
              <Header
                backTitle={"Add New Items"}
                onTabProfile={() => {}}
                hideHeader={true}
                backPath={setShowPaySuccessPage}
                rtComponent={
                  <>
                    <div className="hotel_name">
                      <div className="circle-img">
                        <figure className="">
                          <ImageTag src={table_detail?.Merchant?.logo} alt="" />
                        </figure>
                      </div>
                      <div className="naming-sec text-center">
                        <h4> {table_detail?.Merchant?.account_name} </h4>
                        <p> Payment Successful </p>
                      </div>
                    </div>
                  </>
                }
              />
            </div>

            <div className="confirm-sec">
              <span className="cofirmation-icon">
              {/* <StyledSVG color={table_detail?.QrOrdering?.brand_main_color} src={checked} width="20" />{" "} */}

                 </span>
              <h2 className="lg-title">
                {" "}
                Thank you, {checkNull(selectedSplitPay?.cardHolderName)}!{" "}
              </h2>
              <p>
                {" "}
                {isRemainingpayment ? (
                  <>
                    You've paid your part. <br /> Click below to make your next
                    payment on the order
                  </>
                ) : (
                  <>
                    Your order is paid in full.
                    <br /> We hope you had a great experience with us!
                  </>
                )}
              </p>{" "}
              <div className="mt-3 px-5 w-100">
                {isRemainingpayment ? (
                  <button
                    className="input-btn"
                    onClick={() => {
                      setShowPaySuccessPage();
                      dispatch(changePaymentDetail({ is_next_portion: true }));
                      // history.push("/payment-confirm");
                    }}
                  >
                    Pay Next Portion
                  </button>
                ) : (
                  <div className="rating_row">
                    <p className="text-center">
                      Tap to rate, slide for half star
                    </p>
                    <div className="rating_outer">
                      <RatingInput
                        // star={parseFloat(user_detail?.rating ?? 0)}
                        star={0}
                        ratingChangeHandler={ratingChangeHandler}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div class="form-group total">
                <label class="mb-0">
                  <strong> TOTAL </strong>
                </label>
                <label className="secondary">
                  {table_detail?.currency ?? ""}
                  <strong>
                    {paymentType == "split"
                      ? parseRound(selectedSplitPay?.paid_amount ?? 0)
                      : parseRound(paymentBody?.paid_amount ?? 0)}
                  </strong>
                </label>
              </div>
              <label>
                {checkNull(selectedSplitPay?.split_finish_time, false)
                  ? moment(selectedSplitPay?.split_finish_time).format(
                      "MMMM D, yyyy  h:mma"
                    )
                  : ""}
              </label>
            </div>

            <div className="order-details wht-box w-100">
              <h2 className="h2"> ORDER DETAILS </h2>
              <div className="col-12 mb-2">
                <label> Store Name </label>
                <span> {table_detail?.Merchant?.account_name} </span>
              </div>
              <div className="col-12 mb-2">
                <label> Bill ID </label>
                <span>#{getBillNo()}</span>
              </div>
              <div className="col-12 mb-2">
                <label> Table </label>
                <span> {table_detail?.PosTableMaster?.table_number} </span>
              </div>
            </div>

            <div className="order-details wht-box mt-5 w-100">
              <h2 className="h2"> RECEIPT SUMMARY </h2>
              <div className="col-12">
                <label> Payment Method </label>
                <span>
                  {checkNull(
                    selected_payment_method?.payment_method_name,
                    "N/A"
                  )}
                </span>
              </div>

              <table className="summary-table">
                <tr>
                  <th> Description </th>
                  <th className="text-right"> Amount </th>
                </tr>

                {getItemsList()}
                <tr>
                  <td colspan="2">
                    {" "}
                    <hr />{" "}
                  </td>
                </tr>
                <tr>
                  <td> Subtotal </td>
                  <td className="text-right">
                    {`${table_detail?.currency ?? ""}${parseRound(
                      paymentBody?.order_total
                    )}`}{" "}
                  </td>
                </tr>
                <tr>
                  <td> Discount </td>
                  <td className="text-right">
                    {/* ${paymentBody?.discount > 0?"-":""} */}
                    {`(${table_detail?.currency ?? ""}${parseRound(
                      paymentBody?.discount
                    )})`}{" "}
                  </td>
                </tr>
                <tr>
                  <td> Service Charges </td>
                  <td className="text-right">
                    {" "}
                    {`${table_detail?.currency ?? ""}${parseRound(
                      paymentBody?.service_charge
                    )}`}{" "}
                  </td>
                </tr>
                <tr>
                  <td> Tax </td>
                  <td className="text-right">
                    {" "}
                    {`${table_detail?.currency ?? ""}${parseRound(
                      paymentBody?.sales_tax
                    )}`}{" "}
                  </td>
                </tr>
                <tr>
                  <td> Tip </td>
                  <td className="text-right">
                    {" "}
                    {`${table_detail?.currency ?? ""}${parseRound(
                      selectedSplitPay?.tips ?? 0
                    )}`}{" "}
                  </td>
                </tr>
              </table>

              <div className="total-paid">
                TOTAL PAID |{" "}
                <strong>
                  {`${table_detail?.currency ?? ""}${
                    paymentType == "split"
                      ? parseRound(selectedSplitPay?.paid_amount ?? 0)
                      : parseRound(paymentBody?.paid_amount ?? 0)
                  }`}
                </strong>
              </div>
            </div>
            <div className="sm-txt my-5">
              ©{moment().format("yyyy")} GoGMGo Pte Ltd. All Rights Reserved.{" "}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getCartDetail()}</>;
}

export default PaymentSuccess;
