import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoginLayout from "../Components/LoginLayout";
import LoginInput from "../Components/LoginInput";
import { useHistory } from "react-router-dom";
import {
  getUserDetail,
  sendOtpDetail,
  updateEmailInput,
  updateOtpInput,
} from "../../Redux/actions";
import { StyledSVG } from "../../Utils/style";
import { envelope, newUser } from "../../Assets";
import Modal from "../Components/Modal";
import { Terms } from "../Components/Terms";

const LoginNewCustomer = ({ style, customerType, setCustomerType }) => {
  var uuid = "not-valid";
  let history = useHistory();
  const loaderReducer = useSelector((state) => state.loaderReducer);
  const tableReducer = useSelector((state) => state.tableDetailReducer);
  const merchantId = tableReducer?.table_detail?.Merchant?.id;
  const loyaltyEnabled =
    tableReducer?.table_detail?.Merchant?.loyalty_enabled ?? false;
  const loyaltyPackage =
    tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting
      ?.LoyaltyPackage ?? null;

  const tableNumber =
    tableReducer?.table_detail?.PosTableMaster?.table_number ?? "";
  const deviceId = uuid;
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authDetailReducer);
  const email = authReducer.email_input;
  const otp = authReducer.otp_input;
  const [showTermsPopup, setShowTermsPopup] = useState(false);

  // const [customerType, setCustomerType] = useState("");
  const backBtnHandler = () => {
    setCustomerType("");
  };

  const guestSubmitHandler = (el) => {
    dispatch(
      getUserDetail({
        email: "",
        merchantId: merchantId,
        deviceId: deviceId,
        tableNumber: tableNumber,
      })
    );
  };

  useEffect(() => {
    // redirect / if not have table detail
    if (
      loaderReducer?.loading === false &&
      tableReducer?.table_detail?.show_table_not_found_modal === false &&
      (tableReducer?.table_detail?.Merchant?.id === "" ||
        tableReducer?.table_detail?.Merchant?.id === null ||
        tableReducer?.table_detail?.Merchant?.id === undefined ||
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id === "" ||
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id === null ||
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id === undefined)
    ) {
      history.push("/404");
    }
    // if already login send to menu page
    if (
      tableReducer?.table_detail?.PosTableMaster?.qr_code_id !== undefined &&
      loaderReducer?.loading === false &&
      authReducer?.user_detail !== null &&
      authReducer?.user_detail !== undefined
    ) {
      history.push(
        `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
      );
    }
  }, []);

  const emailSubmitHandler = (el) => {
    el.preventDefault();
    dispatch(sendOtpDetail(email, otp, merchantId, deviceId, tableNumber));
  };

  const resendSubmitHandler = (el) => {
    dispatch(sendOtpDetail(email, "", merchantId, deviceId));
  };
  const emailChangeHandler = (el) => {
    dispatch(updateEmailInput(el.target.value));
  };

  const otpChangeHandler = (el) => {
    dispatch(updateOtpInput(el.target.value));
  };
  return (
    <LoginLayout
      style={style}
      divClass="cart_sidebar"
      backHandler={backBtnHandler}
      showBackBtn={true}
    >
      <div className="login-form pt-4 px_15">
        <h3 className="merchant-title d-flex mt-2 justify-content-center">
          {tableReducer?.table_detail?.Merchant?.account_name}
        </h3>
        <form onSubmit={emailSubmitHandler} className="mx-5">
          <div className="group-sec">
            <div
              className="login-new d-flex align-items-center my-5"
              // style={{color:tableReducer?.table_detail?.QrOrdering?.brand_main_color}}
            >
              <StyledSVG src={newUser}/>
              <span className="ml-3">NEW CUSTOMER</span> 
            </div>
            <div
              className="green-box"
              style={{
                textAlign:"center",
                border: `2px solid ${tableReducer?.table_detail?.QrOrdering?.brand_main_color}`,
              }}
            >
             <p className="new-customer-text "> For <strong>new customers,</strong> you may choose to provide your
              email to receive non-loyalty member future offers and promotions
              or simply continue as a guest.</p>
            </div>
          </div>
          <div className="title-heading d-flex align-items-center ">
            <StyledSVG src={envelope}/>
            <span className="mx-2 otp-text">Submit email for OTP verification{" "}</span>
          </div>
          <LoginInput
            placeholder={"Email"}
            value={authReducer?.email_input ?? ""}
            onChange={emailChangeHandler}
          />
          {authReducer?.show_otp_input === true && (
            <LoginInput
              placeholder="Enter OTP"
              value={authReducer?.otp_input ?? ""}
              onChange={otpChangeHandler}
            />
          )}
          <div className="resend_btn_row my-4">
            {authReducer?.show_otp_input === true && (
              <button
                type="button"
                className="btn green-btn new-green"
                onClick={resendSubmitHandler}
              >
                Resend
              </button>
            )}
            <button
              type="submit"
              className="btn green-btn new-green"
              style={{
                backgroundColor:
                  tableReducer?.table_detail?.QrOrdering?.brand_main_color,
              }}
            >
              Submit
            </button>
          </div>
          <div className="col-12">
            <p className="my-1 text-center">
              By clicking <b>'Submit'</b>, I agree and accept to GoGMGo's{" "}
              <b onClick={() => setShowTermsPopup(true)}>Terms of Use</b> and{" "}
              <b
                onClick={() =>
                  window.open(
                    "https://www.gogmgo.com/privacy-policy/",
                    "_newtab"
                  )
                }
              >
                Privacy Policy
              </b>
            </p>
          </div>
        </form>
        <div className="or">
          {" "}
          <span> Or </span>{" "}
        </div>
        <div className="guest_login mt-3 mb-80" >
          <a
            href="javascript:void(0)"
            className="btn green-btn new-green"
            onClick={guestSubmitHandler}
            style={{
              backgroundColor:
                tableReducer?.table_detail?.QrOrdering?.brand_main_color,
            }}
          >
            Continue as a guest
          </a>
        </div>
      </div>
      <Modal
        show={showTermsPopup}
        title={""}
        extraClass={"top50"}
        modalbody={<Terms />}
        OnSubmit={() => setShowTermsPopup(false)}
        submitBtnText="ok"
      />
    </LoginLayout>
  );
};

export default LoginNewCustomer;
