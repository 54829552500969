import moment from "moment";
import {
  checkArray,
  getPosCartBody,
  getRandomString,
  percentage,
} from "../helpers";

// export function getCartBody({
//   recent_order_id,
//   cart_detail,
//   pos_cart_detail,
//   user_detail,
//   table_detail,
//   totalTip,
//   applied_voucher,
//   totalDiscount,
//   promoDiscount,
//   finalTotalAmt,
//   applied_promocode,
//   xgateDetails,
//   onlyTip = false,
//   uuid = undefined,
//   qr_customer_id = undefined,
//   loyaltyToken = undefined,
//   newOrderItems = false,
//   server_detail,
//   server_user_detail,
//   Cancelmenu_item,
//   void_menu_ticket,
//   printjobCount,
//   is_server_fired = true,
// }) {


//   const defaultServiceCharge =
//     cart_detail && cart_detail.length > 0
//       ? parseFloat(
//           cart_detail
//             .filter((cartItem) => cartItem.is_service_charge == true)
//             .reduce(function (sum, current) {
//               return sum + parseFloat(current.service_charge_amount);
//             }, 0)
//         )
//       : 0;
//   const defaultTotalSaleTax =
//     cart_detail && cart_detail.length > 0
//       ? parseFloat(
//           cart_detail
//             .filter((cartItem) => cartItem.is_sales_tax == true)
//             .reduce(function (sum, current) {
//               return sum + parseFloat(current.sales_tax_amount);
//             }, 0)
//         )
//       : 0;
//   const defaultTotalCartAmount =
//     cart_detail && cart_detail.length > 0
//       ? cart_detail.reduce(function (sum, current) {
//           return sum + parseFloat(current.total);
//         }, 0)
//       : 0;
//   let items = [];

//       const offlineId =
//       recent_order_id !== undefined && recent_order_id !== ""
//         ? recent_order_id
//         : getRandomString();
//   const isNewOrder =
//     recent_order_id !== undefined && recent_order_id !== "" ? "no" : "yes";
//   if (
//     cart_detail &&
//     onlyTip !== true &&
//     cart_detail.filter((p) => p.is_added !== true || newOrderItems === true)
//       .length > 0
//   ) {
//     cart_detail
//       .filter((p) => p.is_added !== true || newOrderItems === true)
//       .map((item) => {
//         const modifierDetail = [];
//         let discountModifierId = "";
//         let discountModifierQty = null;
//         if (item.modifierArray.length > 0) {
//           item.modifierArray.map((modifier, index) => {
//             if (modifier?.action == "discount") {
//               discountModifierQty = modifier?.modifier_quantity ?? 1;
//               discountModifierId = modifier?.modifier_id ?? "";
//             } else {
//               modifierDetail.push({
//                 name: modifier?.name ?? "",
//                 modifier_id: modifier?.modifier_id ?? "",
//                 modifier_group_id: modifier?.modifier_group_id ?? ""pos  items -----------------,
//                 modifier_price: modifier?.modifier_price ?? 0,
//                 actual_modifier_price: modifier?.modifier_price ?? 0,
//                 action: modifier?.action ?? "",
//                 // modifier_quantity: modifier?.modifier_quantity ?? 1,
//                 modifier_quantity: item?.quantity ?? 1,
//               });
//             }
//           });
//         }
//         let promoItem = item?.promotions;
//         const amountWithoutModifiers = promoItem?.accutalPrice
//           ? parseFloat(promoItem?.accutalPrice)
//           : item?.amount_without_modifiers;
//         const menuItemPrice =
//           discountModifierQty !== null
//             ? amountWithoutModifiers / discountModifierQty
//             : amountWithoutModifiers ?? 0;

//         let itemDetail = {
//           BundledMenuItem:
//             item?.menu_item_type == "bundled" &&
//             item?.BundledMenuItem?.length > 0
//               ? item?.BundledMenuItem
//               : [],
//           comment: item?.comment ?? "",
//           image: item?.image ?? "",
//           discount: 0,
//           doubleActualAmout: item?.doubleActualAmout,
//           firetime: item?.firetime ?? null,
//           createDate: item?.createDate ?? "",
//           is_fired: item?.is_fired ?? false,

//           // order_type: server_detail ? "server" : "qr",
//           // is_server_fired:is_server_fired ? "yes" : "no",

//           fired_by: server_user_detail?.nickname
//             ? server_user_detail?.nickname
//             : server_user_detail?.first_name,
//           is_server_fired:is_server_fired ? "yes" : "no",
//           viwek:"check6",
//           username: server_user_detail?.nickname
//             ? server_user_detail?.nickname
//             : server_user_detail?.first_name,
//           is_hold: item?.is_hold ?? false,
//           // viwek: " check 123456789 ",

//           menu_item_id: item?.id ?? "",
//           menu_class_name: item?.menu_class_name ?? "",
//           menu_item_name: item?.name ?? "",

//           pos_name: item?.pos_name,
//           void: item?.void,
//           posordering: item?.posordering ?? 0,
//           bundleName:
//             item?.menu_item_type == "bundled" ? item?.bundleName ?? "" : "",
//           discountModifierID: discountModifierId,
//           menu_item_price: item?.doubleActualAmout,
//           menu_item_quantity:
//             discountModifierQty !== null && item?.quantity
//               ? discountModifierQty * item?.quantity
//               : item?.quantity ?? 0,
//           quantityMultipleBy:
//             discountModifierQty !== null ? discountModifierQty : 1,
//           menu_item_type: item?.menu_item_type ?? "",
//           offlineID: item?.offline_id,
//           promo141Quantity: 0,
//           promotionTotaldiscount: promoItem?.promotionTotaldiscount ?? 0,
//           promotions: promoItem ? [promoItem] : [],
//           salesTaxAmount: item?.sales_tax_amount ?? 0,
//           sales_tax: item?.is_sales_tax == true ? 1 : 0,
//           serviceChargeAmount: item?.service_charge_amount ?? 0,
//           service_charge: item?.is_service_charge == true ? 1 : 0,
//           menu_price: item?.menu_price !== undefined ? item?.menu_price : "{}",
//           subCategoryid: item?.sub_category_id ?? "",
//           catID: item?.catID ?? "",
//           modifiers: modifierDetail?.length > 0 ? modifierDetail : [],
//         };
//         // if (item?.menu_item_type == "standard") {
//         //   if (promoItem) {
//         //     // itemDetail obj changes
//         //     if (promoItem?.type == "Re-Price") {
//         //       itemDetail.menu_item_price = item?.amount_without_modifiers;
//         //     }
//         //   }
//         // }
//         if (item?.menu_item_type == "bundled") {
//           itemDetail.bundlePosName = item?.bundlePosName ?? "";
//           itemDetail.hide_fixed_items = item?.hide_fixed_items ?? "";
//           itemDetail.hide_fixed_items_qr = item?.hide_fixed_items_qr ?? "";
//           // itemDetail.menu_item_price =
//           //   discountModifierQty !== null
//           //     ? item?.amount / discountModifierQty
//           //     : item?.amount ?? 0;
//           // itemDetail.doubleActualAmout =
//           //   discountModifierQty !== null
//           //     ? item?.amount / discountModifierQty
//           //     : item?.amount ?? 0;
//           itemDetail.actual_menu_price =
//             discountModifierQty !== null
//               ? item?.actual_menu_price / discountModifierQty
//               : item?.actual_menu_price ?? 0;
//           itemDetail.kitchen_print_preference =
//             item?.kitchen_print_preference ?? null;
//           itemDetail.modifiers = [];
//         }
//         // promotion changes
//         if (promoItem) {
//           // itemDetail obj changes
//           if (promoItem?.type == "Re-Price") {
//             if (item?.menu_item_type == "bundled") {
//               itemDetail.menu_item_price =
//                 item.amount_without_modifiers + item.modifersPrice;
//               // itemDetail.actual_menu_price = promoItem?.accutalPrice;
//             } else {
//               itemDetail.menu_item_price = item?.amount_without_modifiers;
//               // itemDetail.menu_item_price = promoItem.discountPerItem;
//             }
//           }
//           // itemDetail obj changes END

//           promoItem.discountPerItem =
//             promoItem?.type == "Re-Price" ? 0 : promoItem.discountPerItem;
//           promoItem.promotionTotaldiscount =
//             promoItem.discountPerItem * item?.quantity;
//           // loyality voucher
//           const voucherKey = applied_voucher.findIndex(
//             (p) => p.Code == promoItem?.code
//           );
//           if (voucherKey > -1) {
//             const voucherItem = applied_voucher[voucherKey];
//             promoItem.isLoyaltyVoucher = "yes";
//             promoItem.voucherName = voucherItem?.Code;
//           }
//           // loyality voucher END
//         }
//         // promotion changes END

//         items.push(itemDetail);
//       });
//   }

//   if (pos_cart_detail?.items && pos_cart_detail?.items.length > 0) {
//     if (pos_cart_detail?.items) {
//       for (const item of pos_cart_detail.items) {
//         if (item.hasOwnProperty('is_server_fired')) {
//           // if(item.is_server_fired=="no")
//           // {

//           // }
//           item.is_server_fired = is_server_fired?"yes":"no";
//         }
//         // if (item.hasOwnProperty('is_fired') && item.is_fired === true) {
//         //   item.is_server_fired = "yes";
//         //   items.push(item);
//         // }
//       }
//     }
//     // items = [...items, ...pos_cart_detail?.items];

//   }

//   const loyalityCardName =
//     applied_voucher.length > 0
//       ? applied_voucher.map((voucher) => voucher?.VoucherNo)
//       : [];
//   let totalPromoArr = [];
//   if (
//     checkArray(applied_voucher) &&
//     checkArray(applied_voucher.filter((p) => p.Type == "Discount"))
//   ) {
//     const newTotalPromoArr = applied_voucher
//       .filter((p) => p.Type == "Discount")
//       .map((voucher) => {
//         return {
//           amount: voucher?.TypeValue ?? 0,
//           code: voucher?.VoucherNo ?? "",
//           combo_allowed: "",
//           id: getRandomString(),
//           isLoyaltyVoucher: "",
//           name: voucher?.Name ?? "",
//           promotionTotaldiscount: voucher?.discountAmt ?? 0,
//           type: "loyalty",
//           voucherName: "",
//         };
//       });
//     totalPromoArr = [...newTotalPromoArr];
//   }
//   if (applied_promocode && applied_promocode !== null) {
//     const newTotalPromoArr = [
//       {
//         amount: applied_promocode?.amount ?? "0",
//         code: applied_promocode?.code ?? "",
//         combo_allowed: applied_promocode?.combo_allowed ?? "",
//         id: getRandomString(),
//         isLoyaltyVoucher: applied_promocode?.loyalty_only_promotion ?? "",
//         name: applied_promocode?.name ?? "",
//         promotionTotaldiscount: totalDiscount ?? 0,
//         type: applied_promocode?.type ?? "",
//         voucherName: applied_promocode?.promotion_name,
//       },
//     ];
//     totalPromoArr = [...totalPromoArr, ...newTotalPromoArr];
//   }
//   const itemLevelDiscount = checkArray(items)
//     ? parseFloat(
//         items.reduce(function (sum, current) {
//           let discountedPrc = current?.discount ?? 0;
//           const itemTotalAmt =
//             parseFloat(current?.amount ?? 0) *
//             parseFloat(current?.quantity ?? 0);
//           if (
//             current?.discountType != "amt" &&
//             discountedPrc > 0 &&
//             itemTotalAmt > 0
//           ) {
//             discountedPrc = percentage(discountedPrc, itemTotalAmt, 4);
//           }
//           return sum + discountedPrc;
//         }, 0)
//       )
//     : 0;
//   const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";
//   const currDateTime = moment(new Date())
//     .utc()
//     .utcOffset(timezone)
//     .format("yyyy/MM/DD HH:mm:ss");
//   let body = {
//     uuid: uuid ?? user_detail?.device_id,
//     qr_customer_id: qr_customer_id ?? user_detail?.id,
//     // qr_customer_id:"",
//     merchant_id: table_detail?.Merchant?.id,
//     is_new_order: isNewOrder,
//     no_of_seats: table_detail?.PosTableMaster?.maximum_seat,
//     order_offline_id: offlineId,
//     order_time: currDateTime,
//     promotions: totalPromoArr,
//     payment_type: pos_cart_detail?.payment_type ?? [],
//     order_status: "new",
//     is_refund: false,
//     service_charge: defaultServiceCharge,
//     finishTime: null,
//     tips: totalTip,
//     refund: 0,
//     discount: itemLevelDiscount + totalDiscount + promoDiscount,
//     method_type_id: "",
//     payment_method: "",
//     payment_method_id: "",
//     paid_amount: 0,
//     sales_tax: defaultTotalSaleTax,
//     order_total:
//       defaultTotalCartAmount +
//       itemLevelDiscount +
//       totalDiscount +
//       promoDiscount,
//     table_no: table_detail?.PosTableMaster?.table_number ?? "",
//     channel_name: "Walk-in",
//     Cancelmenu_item: Cancelmenu_item || [],
//     void_menu_ticket: void_menu_ticket || [],
//     printjobCount: printjobCount || 1,

//     finalTotalAmt:
//       parseFloat(finalTotalAmt) - itemLevelDiscount > 0
//         ? parseFloat(finalTotalAmt) - itemLevelDiscount
//         : 0,
//     receiver: {
//       customer_name: null,
//       customer_phone: null,
//       customer_email: user_detail?.email ?? null,
//       customer_address: null,
//     },
//     menu_item: items,

//   };
//   if (loyaltyToken || xgateDetails?.membership?.code) {
//     body.loyaltyToken = loyaltyToken ?? xgateDetails?.membership?.code;
//   }
//   if (applied_voucher && applied_voucher.length > 0) {
//     body.pointToRedeem = applied_voucher.reduce(function (sum, current) {
//       return sum + parseFloat(current.TypeValue);
//     }, 0);
//     body.redeemPointDiscount = totalDiscount;
//     body.loyaltyToken = user_detail?.card_token ?? "";
//     body.loyaltyCardNumber = user_detail?.CardNo ?? "";
//     body.cvc = user_detail?.card_cvc ?? "";
//     body.loyaltyVouchers =
//       loyalityCardName.length > 0 ? loyalityCardName.join(",") : "";
//   }

//   return body;
// }

// const is_Submitted_items_exits = (pos_cart_detail) => {
//   pos_cart_detail?.items.filter((p) => p.is_fired === true).length > 0
// };

const is_active_items_exits = (cart_detail) => {
  for (const item of cart_detail) {
    if (item.hasOwnProperty("is_fired") && item.is_fired == false) {
      return true;
    }
  }
  return false;
};

const check_status_be_cancled = (pos_cart_detail) => {
  if (pos_cart_detail && pos_cart_detail?.items) {
    return pos_cart_detail?.items.filter((p) => p.is_fired === true).length <= 0
      ? true
      : false;
  } else return false;
};

const filter_menu_items = (items) => {
  return items.filter((data) => data?.is_fired === true);
};

const genegateCancleItems = (items) => {
  const cancleMenuData = [];
  items.map((data) => {
    const obj = {
      id: getRandomString(),
      menu_offline_Id: data?.offlineID || data?.offline_id,
      menu_price: data?.menu_item_price,
      menuitemtype: data?.menu_item_type,
      quantity: data?.menu_item_quantity || data?.quantity,
      menuid: data?.menu_item_id,
      menuname: data?.menu_item_name || data?.name,
    };
    cancleMenuData.push(obj);
  });
  return cancleMenuData;
};

export function getCartBody({
  recent_order_id,
  cart_detail,
  pos_cart_detail,
  user_detail,
  table_detail,
  totalTip,
  applied_voucher,
  totalDiscount,
  promoDiscount,
  finalTotalAmt,
  applied_promocode,
  xgateDetails,
  onlyTip = false,
  uuid = undefined,
  qr_customer_id = undefined,
  loyaltyToken = undefined,
  newOrderItems = false,
  server_detail,
  server_user_detail,
  Cancelmenu_item,
  void_menu_ticket,
  KitchenOrders,
  course_List,
  printjobCount,
  is_server_fired = true, //  order will be fired if is_server_fired  is true
  exitsScreenCondition,
  localCartDetails,
  qr_order,
  open_order
}) {


  console.log(exitsScreenCondition,check_status_be_cancled(pos_cart_detail),pos_cart_detail,open_order)
  
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  let items = [];

  const offlineId =
    recent_order_id !== undefined && recent_order_id !== ""
      ? recent_order_id
      : getRandomString();
  const isNewOrder =
    recent_order_id !== undefined && recent_order_id !== "" ? "no" : "yes";

  // const mergedArray = [...cart_detail, ...array2].filter(
  //   (item, index, self) => self.findIndex((el) => el.id === item.id) === index
  // );
  const missingObjects =
    localCartDetails && localCartDetails.length > 0
      ? localCartDetails.filter(
          (obj1) => !cart_detail.some((obj2) => obj2?.id === obj1?.id)
        )
      : [];

  if (
    cart_detail &&
    onlyTip !== true &&
    cart_detail.filter((p) => p.is_added !== true).length > 0
  ) {
    cart_detail
      .filter((p) => p.is_added !== true)
      .map((item) => {
        const modifierDetail = [];
        let discountModifierId = "";
        let discountModifierQty = null;
        if (item.modifierArray.length > 0) {
          item.modifierArray.map((modifier, index) => {
            if (modifier?.action == "discount") {
              discountModifierQty = modifier?.modifier_quantity ?? 1;
              discountModifierId = modifier?.modifier_id ?? "";
            } else {
              modifierDetail.push({
                name: modifier?.name ?? "",
                modifier_id: modifier?.modifier_id ?? "",
                modifier_group_id: modifier?.modifier_group_id ?? "",
                modifier_price: modifier?.modifier_price ?? 0,
                actual_modifier_price: modifier?.modifier_price ?? 0,
                action: modifier?.action ?? "",
                // modifier_quantity: modifier?.modifier_quantity ?? 1,
                modifier_quantity: item?.quantity ?? 1,
              });
            }
          });
        }
        let promoItem = item?.promotions;
        const amountWithoutModifiers = promoItem?.accutalPrice
          ? parseFloat(promoItem?.accutalPrice)
          : item?.amount_without_modifiers;
        const menuItemPrice =
          discountModifierQty !== null
            ? amountWithoutModifiers / discountModifierQty
            : amountWithoutModifiers ?? 0;

        let itemDetail = {
          BundledMenuItem:
            item?.menu_item_type == "bundled" &&
            item?.BundledMenuItem?.length > 0
              ? item?.BundledMenuItem
              : [],
          comment: item?.comment ?? "",
          image: item?.image ?? "",
          default_course:item?.default_course??"",
          discount: 0,
          doubleActualAmout: item?.doubleActualAmout,
          firetime: item?.firetime ?? null,
          createDate: item?.createDate ?? "",
          is_fired: item?.is_fired ?? false, //if fired from QR it will be true always
          fired_by: server_user_detail?.nickname
            ? server_user_detail?.nickname
            : server_user_detail?.first_name,
          is_server_fired: qr_order ? (is_server_fired ? "yes" : "no") : "",
          username: server_user_detail?.nickname
            ? server_user_detail?.nickname
            : server_user_detail?.first_name,
          is_hold: item?.is_hold ?? false,
          menu_item_id: item?.id ?? "",
          menu_class_name: item?.menu_class_name ?? "",
          menu_item_name: item?.name ?? "",
          pos_name: item?.pos_name,
          void: item?.void,
          posordering: item?.posordering ?? 0,
          bundleName:
            item?.menu_item_type == "bundled" ? item?.bundleName ?? "" : "",
          discountModifierID: discountModifierId,
          menu_item_price: item?.doubleActualAmout,
          menu_item_quantity:
            discountModifierQty !== null && item?.quantity
              ? discountModifierQty * item?.quantity
              : item?.quantity ?? 0,
          quantityMultipleBy:
            discountModifierQty !== null ? discountModifierQty : 1,
          menu_item_type: item?.menu_item_type ?? "",
          offlineID: item?.offline_id,
          promo141Quantity: 0,
          promotionTotaldiscount: promoItem?.promotionTotaldiscount ?? 0,
          promotions: promoItem ? [promoItem] : [],
          salesTaxAmount: item?.sales_tax_amount ?? 0,
          sales_tax: item?.is_sales_tax == true ? 1 : 0,
          serviceChargeAmount: item?.service_charge_amount ?? 0,
          service_charge: item?.is_service_charge == true ? 1 : 0,
          menu_price: item?.menu_price !== undefined ? item?.menu_price : "{}",
          subCategoryid: item?.sub_category_id ?? "",
          catID: item?.catID ?? "",
          modifiers: modifierDetail?.length > 0 ? modifierDetail : [],
        };
        // if (item?.menu_item_type == "standard") {
        //   if (promoItem) {
        //     // itemDetail obj changes
        //     if (promoItem?.type == "Re-Price") {
        //       itemDetail.menu_item_price = item?.amount_without_modifiers;
        //     }
        //   }
        // }
        if (item?.menu_item_type == "bundled") {
          itemDetail.bundlePosName = item?.bundlePosName ?? "";
          itemDetail.hide_fixed_items = item?.hide_fixed_items ?? "";
          itemDetail.hide_fixed_items_qr = item?.hide_fixed_items_qr ?? "";
          // itemDetail.menu_item_price =
          //   discountModifierQty !== null
          //     ? item?.amount / discountModifierQty
          //     : item?.amount ?? 0;
          // itemDetail.doubleActualAmout =
          //   discountModifierQty !== null
          //     ? item?.amount / discountModifierQty
          //     : item?.amount ?? 0;
          itemDetail.actual_menu_price =
            discountModifierQty !== null
              ? item?.actual_menu_price / discountModifierQty
              : item?.actual_menu_price ?? 0;
          itemDetail.kitchen_print_preference =
            item?.kitchen_print_preference ?? null;
          itemDetail.modifiers = [];
        }
        // promotion changes
        if (promoItem) {
          // itemDetail obj changes
          if (promoItem?.type == "Re-Price") {
            if (item?.menu_item_type == "bundled") {
              itemDetail.menu_item_price =
                item.amount_without_modifiers + item.modifersPrice;
              // itemDetail.actual_menu_price = promoItem?.accutalPrice;
            } else {
              itemDetail.menu_item_price = item?.amount_without_modifiers;
              // itemDetail.menu_item_price = promoItem.discountPerItem;
            }
          }
          // itemDetail obj changes END

          promoItem.discountPerItem =
            promoItem?.type == "Re-Price" ? 0 : promoItem.discountPerItem;
          promoItem.promotionTotaldiscount =
            promoItem.discountPerItem * item?.quantity;
          // loyality voucher
          const voucherKey = applied_voucher.findIndex(
            (p) => p.Code == promoItem?.code
          );
          if (voucherKey > -1) {
            const voucherItem = applied_voucher[voucherKey];
            promoItem.isLoyaltyVoucher = "yes";
            promoItem.voucherName = voucherItem?.Code;
          }
          // loyality voucher END
        }
        // promotion changes END
       
        items.push(itemDetail);
      });
  }

  const pos_cart_items = [];


  // if (
  //   pos_cart_detail?.items &&
  //   pos_cart_detail?.items.length > 0
  //   &&
  //   pos_cart_detail?.items.filter((p) => p.is_fired === true).length > 0
  // ) {

  //   pos_cart_detail?.items
  //     .filter((p) => p.is_fired === true)
  //     .map((items) => {
  //       // items.is_server_fired = server_detail ? "yes" : "no";qr_order? is_server_fired ? "yes" : "no":"",
  //       items.is_server_fired = qr_order
  //         ? items.is_server_fired
  //           ? "yes"
  //           : "no"
  //         : "";
  //       pos_cart_items.push(items);
  //     });
  // } else if (
  //   pos_cart_detail &&
  //   pos_cart_detail.length > 0&&
  //   // && pos_cart_detail[0].name
  //   // &&
  //   pos_cart_detail.filter((p) => p.is_fired === true).length > 0
  // ) {
  //   pos_cart_detail
  //   .filter((p) => p.is_fired === true)
  //    pos_cart_detail.map((items) => {
  //         const obj = getPosCartBody(items);
  //         pos_cart_items.push(obj);
  //   })

  // }

  if (pos_cart_detail?.items && pos_cart_detail?.items.length > 0) {
    pos_cart_detail?.items.map((items) => {
      const obj = getPosCartBody(items);
      // pos_cart_items.push(items)
      pos_cart_items.push(obj);
    });
  }


  Cancelmenu_item =
    missingObjects.length > 0
      ? genegateCancleItems(missingObjects)
      : genegateCancleItems(items);
  items = [...items, ...pos_cart_items];
  // items = [...items, ...pos_cart_detail];

  const loyalityCardName =
    applied_voucher.length > 0
      ? applied_voucher.map((voucher) => voucher?.VoucherNo)
      : [];
  let totalPromoArr = [];
  if (
    checkArray(applied_voucher) &&
    checkArray(applied_voucher.filter((p) => p.Type == "Discount"))
  ) {
    const newTotalPromoArr = applied_voucher
      .filter((p) => p.Type == "Discount")
      .map((voucher) => {
        return {
          amount: voucher?.TypeValue ?? 0,
          code: voucher?.VoucherNo ?? "",
          combo_allowed: "",
          id: getRandomString(),
          isLoyaltyVoucher: "",
          name: voucher?.Name ?? "",
          promotionTotaldiscount: voucher?.discountAmt ?? 0,
          type: "loyalty",
          voucherName: "",
        };
      });
    totalPromoArr = [...newTotalPromoArr];
  }
  if (applied_promocode && applied_promocode !== null) {
    const newTotalPromoArr = [
      {
        amount: applied_promocode?.amount ?? "0",
        code: applied_promocode?.code ?? "",
        combo_allowed: applied_promocode?.combo_allowed ?? "",
        id: getRandomString(),
        isLoyaltyVoucher: applied_promocode?.loyalty_only_promotion ?? "",
        name: applied_promocode?.name ?? "",
        promotionTotaldiscount: totalDiscount ?? 0,
        type: applied_promocode?.type ?? "",
        voucherName: applied_promocode?.promotion_name,
        identifier: applied_promocode?.identifier
          ? applied_promocode.identifier
          : "",
      },
    ];
    totalPromoArr = [...totalPromoArr, ...newTotalPromoArr];
  }
  const itemLevelDiscount = checkArray(items)
    ? parseFloat(
        items.reduce(function (sum, current) {
          let discountedPrc = current?.discount ?? 0;
          const itemTotalAmt =
            parseFloat(current?.amount ?? 0) *
            parseFloat(current?.quantity ?? 0);
          if (
            current?.discountType != "amt" &&
            discountedPrc > 0 &&
            itemTotalAmt > 0
          ) {
            discountedPrc = percentage(discountedPrc, itemTotalAmt, 4);
          }
          return sum + discountedPrc;
        }, 0)
      )
    : 0;
  const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";
  const currDateTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy/MM/DD HH:mm:ss");
  let body = {
    uuid: uuid ?? user_detail?.device_id,
    loyaltyToken: pos_cart_detail?.loyaltyToken,
    customer_name: pos_cart_detail?.customer_name,
    // qr_customer_id: pos_cart_detail?.online_customer_id,
    loyalty_login_type: pos_cart_detail?.loyalty_login_type,
    qr_customer_id: pos_cart_detail?.online_customer_id
      ? pos_cart_detail?.online_customer_id
      : qr_customer_id ?? user_detail?.id,
    // qr_customer_id:"",
    merchant_id: table_detail?.Merchant?.id,
    is_new_order: isNewOrder,
    no_of_seats: table_detail?.PosTableMaster?.maximum_seat,
    order_tags: pos_cart_detail?.order_tags,
    visit_no: pos_cart_detail?.visit_no
    ? parseInt(pos_cart_detail?.visit_no)
    : parseInt(user_detail?.total_visits) + 1,
    order_offline_id: offlineId,
    order_time: currDateTime,
    promotions: totalPromoArr,
    payment_type: pos_cart_detail?.payment_type ?? [],
    order_status:
      exitsScreenCondition == "no" && check_status_be_cancled(pos_cart_detail)
        ? "cancelled"
        : "new",
    // order_status: "new",
    is_refund: false,
    service_charge: defaultServiceCharge,
    finishTime: null,
    tips: totalTip,
    refund: 0,
    discount: itemLevelDiscount + totalDiscount + promoDiscount,
    method_type_id: "",
    payment_method: "",
    payment_method_id: "",
    paid_amount: 0,
    sales_tax: defaultTotalSaleTax,
    order_total:
      defaultTotalCartAmount +
      itemLevelDiscount +
      totalDiscount +
      promoDiscount,
    table_no: table_detail?.PosTableMaster?.table_number ?? "",
    channel_name: "Walk-in",
    KitchenOrders: KitchenOrders ?? [],
    course_List:course_List??[],
    
    Cancelmenu_item:
      exitsScreenCondition == "no" || missingObjects.length > 0
        ? Cancelmenu_item
        : [],
    void_menu_ticket: void_menu_ticket || [],
    printjobCount: printjobCount || 1,

    finalTotalAmt:
      parseFloat(finalTotalAmt) - itemLevelDiscount > 0
        ? parseFloat(finalTotalAmt) - itemLevelDiscount
        : 0,
    receiver: {
      customer_name: null,
      customer_phone: null,
      customer_email: user_detail?.email ?? null,
      customer_address: null,
    },
    menu_item: exitsScreenCondition == "no" ? filter_menu_items(items) : items,
  };
  if (loyaltyToken || xgateDetails?.membership?.code) {
    body.loyaltyToken = loyaltyToken ?? xgateDetails?.membership?.code;
  }
  if (applied_voucher && applied_voucher.length > 0) {
    body.pointToRedeem = applied_voucher.reduce(function (sum, current) {
      return sum + parseFloat(current.TypeValue);
    }, 0);
    body.redeemPointDiscount = totalDiscount;
    body.loyaltyToken = user_detail?.card_token ?? "";
    body.loyaltyCardNumber = user_detail?.CardNo ?? "";
    body.cvc = user_detail?.card_cvc ?? "";
    body.loyaltyVouchers =
      loyalityCardName.length > 0 ? loyalityCardName.join(",") : "";
  }
  return body;
}

export function getQRCartBody({
  recent_order_id,
  cart_detail,
  pos_cart_detail,
  user_detail,
  table_detail,
  totalTip,
  applied_voucher,
  totalDiscount,
  promoDiscount,
  finalTotalAmt,
  applied_promocode,
  xgateDetails,
  onlyTip = false,
  uuid = undefined,
  qr_customer_id = undefined,
  loyaltyToken = undefined,
  newOrderItems = false,
  qr_order,
}) {
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  let items = [];
  const offlineId =
    recent_order_id !== undefined && recent_order_id !== ""
      ? recent_order_id
      : getRandomString();
  const isNewOrder =
    recent_order_id !== undefined && recent_order_id !== "" ? "no" : "yes";
  if (
    cart_detail &&
    onlyTip !== true &&
    cart_detail.filter((p) => p.is_added !== true || newOrderItems === true)
      .length > 0
  ) {
    cart_detail
      .filter((p) => p.is_added !== true || newOrderItems === true)
      .map((item) => {
        const modifierDetail = [];
        let discountModifierId = "";
        let discountModifierQty = null;
        if (item.modifierArray.length > 0) {
          item.modifierArray.map((modifier, index) => {
            if (modifier?.action == "discount") {
              discountModifierQty = modifier?.modifier_quantity ?? 1;
              discountModifierId = modifier?.modifier_id ?? "";
            } else {
              modifierDetail.push({
                name: modifier?.name ?? "",
                modifier_id: modifier?.modifier_id ?? "",
                modifier_group_id: modifier?.modifier_group_id ?? "",
                modifier_price: modifier?.modifier_price ?? 0,
                actual_modifier_price: modifier?.modifier_price ?? 0,
                action: modifier?.action ?? "",
                // modifier_quantity: modifier?.modifier_quantity ?? 1,
                modifier_quantity: item?.quantity ?? 1,
              });
            }
          });
        }
        let promoItem = item?.promotions;
        const amountWithoutModifiers = promoItem?.accutalPrice
          ? parseFloat(promoItem?.accutalPrice)
          : item?.amount_without_modifiers;
        const menuItemPrice =
          discountModifierQty !== null
            ? amountWithoutModifiers / discountModifierQty
            : amountWithoutModifiers ?? 0;

        let itemDetail = {
          BundledMenuItem:
            item?.menu_item_type == "bundled" &&
            item?.BundledMenuItem?.length > 0
              ? item?.BundledMenuItem
              : [],
          comment: item?.comment ?? "",
          image: item?.image ?? "",
          discount: 0,
          doubleActualAmout: item?.doubleActualAmout,
          firetime: item?.firetime ?? null,
          createDate: item?.createDate ?? "",
          is_fired: item?.is_fired ?? false, //if fired from QR it will be true always
          is_hold: item?.is_hold ?? false,
          menu_item_id: item?.id ?? "",
          menu_class_name: item?.menu_class_name ?? "",
          menu_item_name: item?.name ?? "",
          pos_name: item?.pos_name,
          void: item?.void,
          posordering: item?.posordering ?? 0,
          bundleName:
            item?.menu_item_type == "bundled" ? item?.bundleName ?? "" : "",
          discountModifierID: discountModifierId,
          menu_item_price: item?.doubleActualAmout,
          menu_item_quantity:
            discountModifierQty !== null && item?.quantity
              ? discountModifierQty * item?.quantity
              : item?.quantity ?? 0,
          quantityMultipleBy:
            discountModifierQty !== null ? discountModifierQty : 1,
          menu_item_type: item?.menu_item_type ?? "",
          offlineID: item?.offline_id,
          promo141Quantity: 0,
          promotionTotaldiscount: promoItem?.promotionTotaldiscount ?? 0,
          promotions: promoItem ? [promoItem] : [],
          salesTaxAmount: item?.sales_tax_amount ?? 0,
          sales_tax: item?.is_sales_tax == true ? 1 : 0,
          serviceChargeAmount: item?.service_charge_amount ?? 0,
          service_charge: item?.is_service_charge == true ? 1 : 0,
          menu_price: item?.menu_price !== undefined ? item?.menu_price : "{}",
          subCategoryid: item?.sub_category_id ?? "",
          catID: item?.catID ?? "",
          modifiers: modifierDetail?.length > 0 ? modifierDetail : [],
        };
        // if (item?.menu_item_type == "standard") {
        //   if (promoItem) {
        //     // itemDetail obj changes
        //     if (promoItem?.type == "Re-Price") {
        //       itemDetail.menu_item_price = item?.amount_without_modifiers;
        //     }
        //   }
        // }
        if (item?.menu_item_type == "bundled") {
          itemDetail.bundlePosName = item?.bundlePosName ?? "";
          itemDetail.hide_fixed_items = item?.hide_fixed_items ?? "";
          itemDetail.hide_fixed_items_qr = item?.hide_fixed_items_qr ?? "";
          // itemDetail.menu_item_price =
          //   discountModifierQty !== null
          //     ? item?.amount / discountModifierQty
          //     : item?.amount ?? 0;
          // itemDetail.doubleActualAmout =
          //   discountModifierQty !== null
          //     ? item?.amount / discountModifierQty
          //     : item?.amount ?? 0;
          itemDetail.actual_menu_price =
            discountModifierQty !== null
              ? item?.actual_menu_price / discountModifierQty
              : item?.actual_menu_price ?? 0;
          itemDetail.kitchen_print_preference =
            item?.kitchen_print_preference ?? null;
          itemDetail.modifiers = [];
        }
        // promotion changes
        if (promoItem) {
          // itemDetail obj changes
          if (promoItem?.type == "Re-Price") {
            if (item?.menu_item_type == "bundled") {
              itemDetail.menu_item_price =
                item.amount_without_modifiers + item.modifersPrice;
              // itemDetail.actual_menu_price = promoItem?.accutalPrice;
            } else {
              itemDetail.menu_item_price = item?.amount_without_modifiers;
              // itemDetail.menu_item_price = promoItem.discountPerItem;
            }
          }
          // itemDetail obj changes END

          promoItem.discountPerItem =
            promoItem?.type == "Re-Price" ? 0 : promoItem.discountPerItem;
          promoItem.promotionTotaldiscount =
            promoItem.discountPerItem * item?.quantity;
          // loyality voucher
          const voucherKey = applied_voucher.findIndex(
            (p) => p.Code == promoItem?.code
          );
          if (voucherKey > -1) {
            const voucherItem = applied_voucher[voucherKey];
            promoItem.isLoyaltyVoucher = "yes";
            promoItem.voucherName = voucherItem?.Code;
          }
          // loyality voucher END
        }
        // promotion changes END

        items.push(itemDetail);
      });
  }
  if (pos_cart_detail?.items && pos_cart_detail?.items.length > 0) {
    items = [...items, ...pos_cart_detail?.items];
  }

  const loyalityCardName =
    applied_voucher.length > 0
      ? applied_voucher.map((voucher) => voucher?.VoucherNo)
      : [];
  let totalPromoArr = [];
  if (
    checkArray(applied_voucher) &&
    checkArray(applied_voucher.filter((p) => p.Type == "Discount"))
  ) {
    const newTotalPromoArr = applied_voucher
      .filter((p) => p.Type == "Discount")
      .map((voucher) => {
        return {
          amount: voucher?.TypeValue ?? 0,
          code: voucher?.VoucherNo ?? "",
          combo_allowed: "",
          id: getRandomString(),
          isLoyaltyVoucher: "",
          name: voucher?.Name ?? "",
          promotionTotaldiscount: voucher?.discountAmt ?? 0,
          type: "loyalty",
          voucherName: "",
        };
      });
    totalPromoArr = [...newTotalPromoArr];
  }

  if (applied_promocode && applied_promocode !== null) {
    const newTotalPromoArr = [
      {
        amount: applied_promocode?.amount ?? "0",
        code: applied_promocode?.code ?? "",
        combo_allowed: applied_promocode?.combo_allowed ?? "",
        id: getRandomString(),
        isLoyaltyVoucher: applied_promocode?.loyalty_only_promotion ?? "",
        name: applied_promocode?.name ?? "",
        promotionTotaldiscount: totalDiscount ?? 0,
        type: applied_promocode?.type ?? "",
        voucherName: applied_promocode?.promotion_name,
      },
    ];
    totalPromoArr = [...totalPromoArr, ...newTotalPromoArr];
  }
  const itemLevelDiscount = checkArray(items)
    ? parseFloat(
        items.reduce(function (sum, current) {
          let discountedPrc = current?.discount ?? 0;
          const itemTotalAmt =
            parseFloat(current?.amount ?? 0) *
            parseFloat(current?.quantity ?? 0);
          if (
            current?.discountType != "amt" &&
            discountedPrc > 0 &&
            itemTotalAmt > 0
          ) {
            discountedPrc = percentage(discountedPrc, itemTotalAmt, 4);
          }
          return sum + discountedPrc;
        }, 0)
      )
    : 0;
  const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";
  const currDateTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy/MM/DD HH:mm:ss");
  let body = {
    uuid: uuid ?? user_detail?.device_id,
    qr_customer_id: qr_customer_id ?? user_detail?.id,
    merchant_id: table_detail?.Merchant?.id,
    is_new_order: isNewOrder,
    no_of_seats: table_detail?.PosTableMaster?.maximum_seat,
    order_offline_id: offlineId,
    order_time: currDateTime,
    promotions: totalPromoArr,
    payment_type: pos_cart_detail?.payment_type ?? [],
    order_status: "new",
    is_refund: false,
    service_charge: defaultServiceCharge,
    finishTime: null,
    tips: totalTip,
    refund: 0,
    discount: itemLevelDiscount + totalDiscount + promoDiscount,
    method_type_id: "",
    payment_method: "",
    payment_method_id: "",
    paid_amount: 0,
    sales_tax: defaultTotalSaleTax,
    order_total:
      defaultTotalCartAmount +
      itemLevelDiscount +
      totalDiscount +
      promoDiscount,
    table_no: table_detail?.PosTableMaster?.table_number ?? "",
    channel_name: "Walk-in",
    finalTotalAmt:
      parseFloat(finalTotalAmt) - itemLevelDiscount > 0
        ? parseFloat(finalTotalAmt) - itemLevelDiscount
        : 0,
    receiver: {
      customer_name: null,
      customer_phone: null,
      customer_email: user_detail?.email ?? null,
      customer_address: null,
    },
    menu_item: items,
  };
  if (loyaltyToken || xgateDetails?.membership?.code) {
    body.loyaltyToken = loyaltyToken ?? xgateDetails?.membership?.code;
  }
  if (applied_voucher && applied_voucher.length > 0) {
    body.pointToRedeem = applied_voucher.reduce(function (sum, current) {
      return sum + parseFloat(current.TypeValue);
    }, 0);
    body.redeemPointDiscount = totalDiscount;
    body.loyaltyToken = user_detail?.card_token ?? "";
    body.loyaltyCardNumber = user_detail?.CardNo ?? "";
    body.cvc = user_detail?.card_cvc ?? "";
    body.loyaltyVouchers =
      loyalityCardName.length > 0 ? loyalityCardName.join(",") : "";
  }

  return body;
}

// {
//   "BundledMenuItem": [],
//   "comment": "",
//   "image": "https://uat.gogmgo.com/content/236654786672.jpg",
//   "discount": 0,
//   "doubleActualAmout": 250,
//   "firetime": null,
//   "createDate": "2023/09/02 15:55:48",
//   "is_fired": false,
//   "fired_by": "John",
//   "is_server_fired": "no",
//   "username": "John",
//   "is_hold": false,
//   "menu_item_id": "690",
//   "menu_class_name": "Food",
//   "menu_item_name": "Lasagna",
//   "pos_name": "Lasagna",
//   "void": 0,
//   "posordering": 0,
//   "bundleName": "",
//   "discountModifierID": "",
//   "menu_item_price": 250,
//   "menu_item_quantity": 1,
//   "quantityMultipleBy": 1,
//   "menu_item_type": "standard",
//   "offlineID": "1693641349.515",
//   "promo141Quantity": 0,
//   "promotionTotaldiscount": 0,
//   "promotions": [],
//   "salesTaxAmount": 22,
//   "sales_tax": 1,
//   "serviceChargeAmount": 25,
//   "service_charge": 1,
//   "menu_price": "{\"Dine-In\":\"250.00000\",\"Dine-Inservice_charge\":\"1\",\"Dine-Insales_tax\":\"1\",\"Dine-Inbutton_color\":null}",
//   "subCategoryid": "182",
//   "catID": "5",
//   "modifiers": []
// }
