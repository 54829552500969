import React, { useState } from "react";
import Rating from "react-rating";

const RatingInput = ({ star, ratingChangeHandler }, props) => {
  const [rating, setRating] = useState(star);

  function changeRating(rate) {
    setRating(rate);
    ratingChangeHandler(rate);
  }
  function HoverRating(rate) {
    setRating(rate);
  }
  return (
    <Rating
      fractions={2}
      emptySymbol={"fa fa-star-o fa-2x"}
      fullSymbol="fa fa-star fa-2x"
      placeholderSymbol="fa fa-star fa-2x"
      placeholderRating={rating}
      initialRating={rating}
      quiet={false}
      onChange={(rate) => changeRating(rate)}
      className={"inner_ratings"}
      // onHover={(rate) => HoverRating(rate)}
    />
  );
};

export default RatingInput;
