import moment from "moment";
import axios from "axios";
import { encode } from "base-64";
import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import uniqid from "uniqid";
import CryptoJS from "crypto-js";
import { encode as base64_encode } from "base-64";
import { saveCartTemporary } from "./Redux/actions";
import { API } from "./Utils/api";
import ImageTag from "./Scenes/Components/ImageTag";
import { envelope, idbadge, phonerotary, qrcode } from "./Assets";
import UAParser from 'ua-parser-js';

const getNewDeviceId = async () => {
  const fpPromise = FingerprintJS.load({
    apiKey: "bjqpKdV8NBZaxve6JPwz",
    region: "ap",
  });
  const fp = await fpPromise;
  const result = await fp.get();
  return result;
};

export const getDeviceId = async () => {
  let res = "";
  const checkExist = getSessionStorageOrDefault("deviceId", undefined);
  if (checkExist) {
    return checkExist;
  } else {
    const keyPromise = await getNewDeviceId().then((resp) => {
      const visitorId = resp.visitorId ?? "";
      sessionStorage.setItem("deviceId", JSON.stringify(visitorId));
      return visitorId;
    });
    
    return keyPromise;
  }
};

export const getDeviceName=()=>{
  const parser = new UAParser();
  const device = parser.getDevice();
  const os = parser.getOS();
  const browser = parser.getBrowser();
  return {device,os,browser}
}

export function capitalizeFirstLetter(string) {
  if (string && typeof string === "string" && string !== "") {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
}
export function percentage(percent, total, limit = 2, isDecimal = true) {
  return parseRound(parseFloat((percent / 100) * total), limit, isDecimal);
}
export function howMuchPercentage(percent, total, limit = 2, isDecimal = true) {
  return parseRound(parseFloat((percent / total) * 100), limit, isDecimal);
}

export function generateQuickGuid() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export const scrollToBottom = () => {
  window.scrollTo(0, document.body.scrollHeight);
};

function checkString(strng) {
  let res = false;
  if (typeof strng === "string" || strng instanceof String) {
    res = true;
  }
  return res;
}

export function generateMessageUuid(data) {
  const { apiKey, clientId, timestamp, body, secret } = data;
  const mssg = apiKey + clientId + timestamp + JSON.stringify(body);
  const hmac = CryptoJS.algo.HMAC.create(CryptoJS.algo.SHA256, secret);
  hmac.update(mssg);
  return Buffer.from(hmac.finalize().toString()).toString("base64");
}

export function getInitialPaymentObj() {
  return {
    appCode: "",
    cardHolderName: "",
    cardNumber: "",
    cardType: "",
    cardmerchantID: "",
    cardname: "",
    cashier_name: "",
    changeAmount: 0,
    excessAmount: 0,
    isPaid: 0,
    is_refund: 0,
    method_type_id: "",
    orderPromodis: 0,
    paid_amount: 0,
    payment_method: "",
    payment_method_id: "",
    payment_type: "", //FISERV
    referenceNo: "",
    refund: 0,
    refund_reasons: "",
    sales_tax: 0,
    service_charge: 0,
    splitCreateDate: moment().format("yyyy-MM-DD HH:mm:ss"),
    splitOfflineID: "",
    splitPrice: 0,
    splitType: "", //Split Evenly
    split_finish_time: "", //moment().format("yyyy-MM-DD HH:mm:ss")
    split_items: [],
    split_number: 0,
    tips: 0,
    voucherBalance: 0,
    xGatepointRedeem: 0,
    voucherID: "",
  };
}

export function truncateString(str, n) {
  return str.length > n ? str.substr(0, n - 1) + ".." : str;
}

function replaceDateStringFrom(date) {
  let res = undefined;
  try {
    if (date && typeof date === "string" && date !== "") {
      res = date.replace("/Date(", "");
      res = res.replace(")/", "");
      res = moment(parseInt(res));
    }
  } catch (e) {}
  return res;
}

export const scrollToDiv = (id, top = false) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollTo({
      left: 0,
      top: top ? 0 : element.scrollHeight,
      behavior: "smooth",
    });
  }
};

export async function avocadoAuthToken() {
  // Advocado API
  const ad_api_url = process?.env?.REACT_APP_AVOCADO_API_URL;
  const ad_username = process?.env?.REACT_APP_AVOCADO_API_USERNAME;
  const ad_password = process?.env?.REACT_APP_AVOCADO_API_PASSWORD;
  let myHeaders = new Headers({
    "Content-Type": "application/json",
    Accept: "application/json",
    // "Access-Control-Allow-Origin": "*",
  });
  myHeaders.set(
    "Authorization",
    "Basic " + encode(ad_username + ":" + ad_password)
  );
  const json = await fetch(`${ad_api_url}/oauth2/tokenCredential`, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify({
      grant_type: "client_credentials",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("authToken failed : " + error.message));
  return json;
}

export async function orderHistoryApi({
  token,
  merchantId,
  customerId,
  deviceId,
}) {
  // Advocado API
  const base_url = process?.env?.REACT_APP_API_URL;
  let myHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    token: token,
    // "Access-Control-Allow-Origin": "*",
  };
  const json = await fetch(`${base_url}/qr_code_apis/get_order_history`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify({
      merchant_id: merchantId,
      customer_id: customerId,
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("authToken failed : " + error.message));
  return json;
}

export function getReOrderItemIds(data) {
  let allItems = [];
  if (data && data.length > 0) {
    data.map((order, orderIndex) => {
      if (order?.PosOrderItem && order?.PosOrderItem.length > 0) {
        order?.PosOrderItem.map((item, itemIndex) => {
          const modifierArr =
            item?.PosOrderItemDetail && item?.PosOrderItemDetail.length > 0
              ? pluckArray(item?.PosOrderItemDetail, "pos_modifier_id")
              : [];
          const bundleItemArr =
            item?.PosOrderBundleItem && item?.PosOrderBundleItem.length > 0
              ? item?.PosOrderBundleItem.map((bundleItem) => {
                  const resItem = {
                    menu_item_id: bundleItem?.menu_item_id,
                    modifiers_id: pluckArray(
                      bundleItem?.PosOrderItemDetail,
                      "pos_modifier_id"
                    ),
                  };
                  return resItem;
                })
              : [];
          const checkExist = allItems.findIndex(
            (p) => p.menu_item_id == item?.menu_item_id
          );
          if (checkExist > -1) {
            allItems[checkExist].count = allItems[checkExist].count + 1;
            // allItems[checkExist].bundle_item_ids = bundleItemArr;
          } else {
            allItems.push({
              menu_item_id: item?.menu_item_id,
              count: 1,
              created_on: 
                order?.PosOrder?.order_create_date,
              quantity: parseInt(item?.menu_item_quantity),
              modifier_ids: modifierArr,
              bundle_item_ids: bundleItemArr,
            });
          }
        });
      }
    });
  }
  allItems.sort(function (a, b) {
    return b.count - a.count;
  });
  if (allItems.length > 0) {
    allItems = allItems.slice(0, 10);
  }
  return allItems;
}

export function getUnixDateFormat(dateNum, format = "MMMM D, YYYY") {
  let res = "N/A";
  const getDate = replaceDateStringFrom(dateNum);
  if (getDate && getDate !== undefined) {
    res = getDate.format(format);
  }
  return res;
}
export function getCartNewItemCount(cartItems) {
  let res = 0;
  if (cartItems && cartItems?.length > 0) {
    res = cartItems
      .filter(
        (p) =>
          p?.is_added == false || (p.is_fired == false && p.is_fired == true)
      )
      .reduce(function (sum, current) {
        return sum + parseInt(current.quantity);
      }, 0);
  }
  return res;
}

export function getCartFiredItemCount(cartItems) {
  let res = 0;
  if (cartItems && cartItems?.length > 0) {
    res = cartItems
      .filter((p) => p?.is_added == true)
      .reduce(function (sum, current) {
        return sum + parseInt(current.quantity);
      }, 0);
  }
  return res;
}

export const customFilterArray = [
  {
    color: "#63C4B4",
    id: -2,
    name: "Popular",
    symbol: "P",
  },
  {
    color: "#63C4B4",
    id: -1,
    name: "Trending",
    symbol: "T",
  },
];

export function getTypeOfVoucher(type, voucher) {
  let res = false;
  let todayDate = moment();
  const fromDateObj = replaceDateStringFrom(voucher?.ValidTo);
  if (fromDateObj && fromDateObj !== undefined) {
   
    if (type == 0 && fromDateObj.diff(todayDate, "days") > 0) {
      res = true;
    } else if (
      type == 1 &&
      fromDateObj.diff(todayDate, "days") > 0 &&
      voucher?.IsRedeemable == false
    ) {
      res = true;
    } else if (type == 2 && fromDateObj.diff(todayDate, "days") < 0) {
      res = true;
    }
  }
  return res;
}

export function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

export function getModifierActionName(modifierAction) {
  let res = "";
  switch (modifierAction) {
    case "add":
      res = "Add";
      break;
    case "remove":
      res = "Remove";
      break;
    default:
      break;
  }
  return res;
}

export function checkNull(string, val = "") {
  if (typeof string === "number") {
    string = string.toString();
  }

  return string && string !== "" && string !== null ? string : val;
}

export function checkIsNum(num, val = 0) {
  try {
    if (typeof num === "string") {
      num = parseFloat(num);
    }
  } catch (error) {}

  return num && typeof num == "number" ? num : val;
}

export function parseTwoDigitNumber(val) {
  if (val !== undefined && !isNaN(parseFloat(val)) && isFinite(val)) {
    if (countDecimals(val) > 0) {
      return parseFloat(val).toFixed(3).slice(0, -1);
    }
    return `${val}.00`;
  }
  return `0.00`;
}

export function getBundleItemOptionsPrice(bundleItem, currency = "") {
  let res = null;
  if (
    bundleItem?.action_price !== null &&
    bundleItem?.action_price !== "" &&
    bundleItem?.amount !== "" &&
    bundleItem?.amount !== null
  ) {
    res = (
      <span className="price-sm">
        {parseFloat(bundleItem.amount) !== 0
          ? checkNegNum(bundleItem.amount)
            ? "-"
            : "+"
          : ""}
        {`${currency}`}
        {parseFloat(bundleItem.amount) < 0
          ? makeNegToPosNum(bundleItem.amount)
          : parseFloat(bundleItem.amount).toFixed(2)}
      </span>
    );
  } else if (
    bundleItem?.unit_price !== null &&
    bundleItem?.unit_price !== "" &&
    parseFloat(bundleItem?.unit_price) > 0
  ) {
    res = (
      <span className="price-sm">
        {"+"}
        {`${currency}`}
        {parseFloat(bundleItem.unit_price).toFixed(2)}
      </span>
    );
  }
  return res;
}

export function getModifierActionSign(modifierAction, sizeMultipler = null) {
  let res = "";
  switch (modifierAction) {
    case "add":
      res = "+";
      break;
    case "remove":
      res = "-";
      break;
    case "swap":
      res = "+";
      break;
    case "size":
      res = "+";
      if (checkNull(sizeMultipler, false)) {
        if (parseFloat(sizeMultipler) < 1) {
          res = "-";
        }
      }
      break;
    default:
      break;
  }
  return res;
}

export function checkNegNum(num) {
  return parseFloat(num) < 0;
}

export function makeNegToPosNum(num) {
  return -1 * parseFloat(num);
}

export function getModifierTypeName(modifierType, modifier) {
  let res = "";
  res = modifier?.modifier_name ?? "";
  // if (modifier?.action == "size") {
  //   res = modifier?.modifier_name ?? "";
  // } else {
  //   switch (modifierType) {
  //     case "ingredient":
  //       res = `${modifier?.Ingredient?.ingredient_name ?? ""} ${
  //         modifier?.action == "swap"
  //           ? " with " + modifier?.SwapIngredient?.ingredient_name ?? ""
  //           : ""
  //       }`;
  //       break;
  //     case "recipe":
  //       res = `${modifier?.Recipe?.recipe_name ?? ""} ${
  //         modifier?.action == "swap"
  //           ? " with " + modifier?.SwapRecipe?.recipe_name ?? ""
  //           : ""
  //       }`;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  return res;
}

export function setPageDetails(data = {}) {
  if (data.title) {
    document.title = data.title;
  }
  if (data.favIcon) {
    document.getElementById("fav-icon").setAttribute("href", data.favIcon);
    document
      .getElementById("apple-touch-icon")
      .setAttribute("href", data.favIcon);
  }
}

export function pluckArray(arr, key) {
  return arr.map((i) => i[key]);
}

export function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export function checkItemExistInCart(item, cart) {
  let res = -1;
  if (cart?.length > 0) {
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      if (
        item?.id == element?.id &&
        element?.is_added == false &&
        JSON.stringify(item?.modifierArray) ==
          JSON.stringify(element?.modifierArray)
      ) {
        res = i;
        break;
      }
    }
  }
  return res;
}

export function checkBundleItemExistInCart(item, cart) {
  let res = -1;
  if (
    cart?.length > 0 &&
    ((item?.modifierArray && item?.modifierArray?.length > 0) ||
      (item?.BundledMenuItem && item?.BundledMenuItem?.length > 0))
  ) {
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      if (
        item?.id == element?.id &&
        element?.is_added == false &&
        JSON.stringify(item?.modifierArray) ==
          JSON.stringify(element?.modifierArray) &&
        JSON.stringify(item?.selectedBundleItem) ==
          JSON.stringify(element?.selectedBundleItem)
      ) {
        res = i;
        break;
      }
    }
  }
  return res;
}

export function getItemById(itemId, subId, table_detail) {
  let res = null;
  if (table_detail?.SubCategory?.length > 0) {
    const subIndex = table_detail?.SubCategory?.findIndex((p) => p.id == subId);
    if (subIndex > -1) {
      const subCat = table_detail?.SubCategory?.[subIndex];
      const itemIndex = subCat?.MenuItem?.findIndex((p) => p.id == itemId);
      if (itemIndex > -1) {
        res = subCat?.MenuItem?.[itemIndex];
      }
    }
  }
  return res;
}

export function getPosCartSubmittedItems(orders, table_detail) {
  let items = [];
  orders.map((data) => {
    if (data?.menu_item && data?.menu_item?.length > 0) {
      data?.menu_item?.map((order) => {
        let item = order;
        items.push(item);
      });
    }
  });

  return items;
}

export function getCartSubmittedItems(orders, table_detail) {
  let items = [];
  orders.map((data) => {
    if (data?.menu_item && data?.menu_item?.length > 0) {
      data?.menu_item?.map((order) => {
        const menuItemQyt = order?.menu_item_quantity
          ? parseFloat(order?.menu_item_quantity)
          : 1;
        const quantityMultipleBy = order?.quantityMultipleBy
          ? parseFloat(order?.quantityMultipleBy)
          : 1;
        const exactQyt = parseFloat(menuItemQyt / quantityMultipleBy);
        const menuItemPrice = order?.menu_item_price
          ? parseFloat(order?.menu_item_price)
          : 0;
        // const modifersPrice = checkArray(order?.modifiers)
        //   ? parseFloat(
        //       order?.modifiers.reduce(function (sum, current) {
        //         return sum + parseFloat(current.unit_price);
        //       }, 0)
        //     )
        //   : 0;
        const modifierArray =
          order?.modifiers?.length > 0
            ? order?.modifiers?.map((modifier) => {
                return {
                  id: modifier?.modifier_id ?? "",
                  modifier_id: modifier?.modifier_id ?? "",
                  modifier_group_id: modifier?.modifier_group_id ?? "",
                  action: modifier?.action ?? "",
                  name: modifier?.name ?? "",
                  unit_price: parseFloat(modifier?.modifier_price ?? 0),
                  modifier_price: parseFloat(modifier?.modifier_price ?? 0),
                  modifier_quantity: parseFloat(
                    modifier?.modifier_quantity ?? 0
                  ),
                };
              })
            : [];
        const actualMenuPrice =
          order?.menu_item_type == "bundled"
            ? parseFloat(order?.actual_menu_price ?? 0)
            : parseFloat(order?.menu_item_price ?? 0);
        const selectedBundleItem = order?.BundledMenuItem;
        const modifierAddActionPrice =
          selectedBundleItem.length > 0
            ? selectedBundleItem
                .filter((item) => item.action_price == "add")
                .map((item) => parseFloat(item?.amount ?? 0))
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierRemoveActionPrice =
          selectedBundleItem.length > 0
            ? selectedBundleItem
                .filter((item) => item.action_price == "remove")
                .map((item) => parseFloat(item?.amount ?? 0))
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierAddPrice =
          modifierArray.length > 0
            ? modifierArray
                .filter((item) => item.action == "add" || item.action == "swap")
                .map((item) => item.unit_price)
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierRemovePrice =
          modifierArray.length > 0
            ? modifierArray
                .filter((item) => item.action == "remove")
                .map((item) => item.unit_price)
                .reduce((prev, curr) => prev + curr, 0)
            : 0;
        const modifierSizePrice =
          modifierArray.length > 0
            ? modifierArray
                .filter((item) => item.action == "size")
                .map((item) => item.unit_price)
                .reduce((prev, curr) => prev + curr, 0)
            : 0;

        const modifersPrice =
          modifierAddActionPrice -
          modifierRemoveActionPrice +
          modifierAddPrice -
          modifierRemovePrice -
          modifierSizePrice;
        const menuDoubleActualAmout =
          checkArray(order.promotions) &&
          order.promotions.findIndex((p) => p?.type == "Re-Price") > -1
            ? order?.menu_item_price
            : order?.doubleActualAmout
            ? parseFloat(order?.doubleActualAmout)
            : 0;
        const menuTotalPrice = menuDoubleActualAmout
          ? parseFloat(menuDoubleActualAmout * exactQyt)
          : 0;
        const menuPriceForOne = menuItemPrice - modifersPrice;
      

        // for discount modifier
        if (order?.discountModifierID !== "") {
          const modiItem = getItemById(
            order?.menu_item_id,
            order?.subCategoryid,
            table_detail
          );
          if (modiItem !== null) {
            let disModifierIndex = -1;
            let disModifierItem = null;
            if (modiItem?.menu_item_type === "standard") {
              disModifierIndex = modiItem?.PosMenuitemsModifier?.findIndex(
                (p) => p.id == order?.discountModifierID
              );
              if (disModifierIndex > -1) {
                disModifierItem =
                  modiItem?.PosMenuitemsModifier?.[disModifierIndex];
              }
            } else {
              disModifierIndex = modiItem?.DiscountModifiers?.findIndex(
                (p) => p.id == order?.discountModifierID
              );
              if (disModifierIndex > -1) {
                disModifierItem =
                  modiItem?.DiscountModifiers?.[disModifierIndex];
              }
            }
            if (disModifierIndex > -1 && disModifierItem !== null) {
              modifierArray.push({
                id: disModifierItem?.id ?? "",
                modifier_id: disModifierItem?.id ?? "",
                modifier_group_id: disModifierItem?.modifier_group_id ?? "",
                action: disModifierItem?.action ?? "",
                name: disModifierItem?.modifier_name ?? "",
                unit_price: parseFloat(disModifierItem?.unit_price ?? 0),
                modifier_price: parseFloat(disModifierItem?.unit_price ?? 0),
                modifier_quantity: parseFloat(
                  disModifierItem?.amount_per_item ?? 0
                ),
              });
            }
          }
        }
        // for discount modifier end
        const BundledMenuItemArray =
          order?.BundledMenuItem?.length > 0
            ? order?.BundledMenuItem?.map((bundledItem) => {
                const bundleModifiers =
                  bundledItem?.bundledMenuitemModifers?.length > 0
                    ? bundledItem?.bundledMenuitemModifers.map(
                        (bundleModifier) => {
                          return {
                            id: bundleModifier?.id ?? "",
                            modifier_id: bundleModifier?.modifier_id ?? "",
                            modifier_group_id:
                              bundleModifier?.modifier_group_id ?? "",
                            action: bundleModifier?.action ?? "",
                            name: bundleModifier?.name ?? "",
                            unit_price: bundleModifier?.unit_price
                              ? parseFloat(bundleModifier?.unit_price)
                              : 0,
                            modifier_price: bundleModifier?.unit_price
                              ? parseFloat(bundleModifier?.modifier_price)
                              : 0,
                            modifier_quantity: bundleModifier?.unit_price
                              ? parseFloat(bundleModifier?.modifier_quantity)
                              : 0,
                            menu_item_id: bundleModifier?.menu_item_id ?? "",
                          };
                        }
                      )
                    : [];
                return {
                  action_price: bundledItem?.action_price ?? "",
                  amount: bundledItem?.amount
                    ? parseFloat(bundledItem?.amount)
                    : 0,
                  amount_without_modifiers:
                    bundledItem?.amount_without_modifiers
                      ? parseFloat(bundledItem?.amount_without_modifiers)
                      : 0,
                  bundleName: bundledItem?.bundleName ?? "",
                  sort: bundledItem?.sort ?? "",
                  type: bundledItem?.type ?? "",
                  category_id: bundledItem?.category_id ?? "",
                  sub_category_id: bundledItem?.sub_category_id ?? "",
                  perBundleQty: bundledItem?.perBundleQty ?? "0",
                  bundle_menu_item_id: bundledItem?.bundle_menu_item_id
                    ? parseFloat(bundledItem?.bundle_menu_item_id)
                    : 0,
                  bundle_menu_item_name:
                    bundledItem?.bundle_menu_item_name ?? "",
                  menu_item_pos_name: bundledItem?.menu_item_pos_name ?? "",
                  bundledMenuitemModifers: bundleModifiers,
                  comment: bundledItem?.comment,
                  id: bundledItem?.id ? parseFloat(bundledItem?.id) : 0,
                  bundle_hide_fixed_items:
                    bundledItem?.bundle_hide_fixed_items ?? "no",
                  is_fired: bundledItem?.is_fired == 1 ? true : false,
                  is_hold: bundledItem?.is_hold == 1 ? true : false,
                  fired_by: bundledItem?.fired_by ?? "",
                  firetime: bundledItem?.firetime ?? "",
                  offlineID: bundledItem?.offlineID ?? "",
                  option_series_id: bundledItem?.option_series_id
                    ? parseFloat(bundledItem?.option_series_id)
                    : 0,
                  option_series_name: bundledItem?.option_series_name ?? "",
                  quantity: bundledItem?.quantity
                    ? parseFloat(bundledItem?.quantity)
                    : 0,
                  void: bundledItem?.void ? parseFloat(bundledItem?.void) : 0,
                };
              })
            : [];
        const promoItem =
          checkArray(order?.promotions) && order?.promotions?.[0]
            ? order?.promotions?.[0]
            : undefined;
        let item = {
          id: order?.menu_item_id,
          offline_id: order?.offlineID,
          name: order?.menu_item_name,
          pos_name: order?.pos_name,
          Cancelmenu_item: order?.Cancelmenu_item,
          void_menu_ticket: order?.void_menu_ticket,
          KitchenOrders: order?.KitchenOrders ?? [],
          course_List: order?.course_List ?? [],

          kitchen_docket_language: order?.kitchen_docket_language,
          chinese_menu_item_name: order?.chinese_menu_item_name,
          default_course:order?.default_course||"",
          bundleName: order?.menu_item_name ?? "",
          bundlePosName: order?.bundlePosName ?? "",
          image: order?.image ?? "",
          is_added:
            order?.is_fired == true
              ? true
              : order?.is_server_fired == "yes"
              ? true
              : false,
          void: order?.void ?? 0,
          total_tip: order?.tips ? parseFloat(order?.tips) : 0,
          discount: order?.discount ?? 0,
          discountType: order?.discountType ?? "",
          is_service_charge: order?.service_charge == 1 ? true : false,
          is_sales_tax: order?.sales_tax == 1 ? true : false,
          quantity: exactQyt,
          amount: menuItemPrice,
          amount_without_modifiers: parseFloat(menuPriceForOne),
          modifersPrice: modifersPrice,
          item_amount: actualMenuPrice,
          comment: order?.comment,
          posordering: order?.posordering ?? 0,
          total:
            menuTotalPrice - parseFloat(promoItem?.promotionTotaldiscount ?? 0),
          actual_menu_price: actualMenuPrice,
          menu_item_price: parseFloat(menuPriceForOne),
          doubleActualAmout: parseFloat(menuDoubleActualAmout),
          firetime: order?.firetime ?? null,
          createDate: order?.createDate ?? "",
          is_fired: order?.is_fired == 1 ? true : false,
          is_hold: order?.is_hold == 1 ? true : false,
          menu_item_type: order?.menu_item_type ?? "",
          menu_price: order?.menu_price ?? "",
          service_charge_amount: order?.serviceChargeAmount
            ? parseFloat(order?.serviceChargeAmount)
            : 0,
          sales_tax_amount: order?.salesTaxAmount
            ? parseFloat(order?.salesTaxAmount)
            : 0,
          total_tip: order?.tips ? parseFloat(order?.tips) : 0,
          catID: order?.catID ?? "",
          sub_category_id: order?.subCategoryid ?? "",

          modifierArray:
            modifierArray?.length > 0
              ? modifierArray.sort(function (a, b) {
                  return a.id - b.id;
                })
              : [],
          BundledMenuItem:
            BundledMenuItemArray?.length > 0
              ? BundledMenuItemArray.sort(function (a, b) {
                  return a.id - b.id;
                })
              : [],
          selectedBundleItem:
            BundledMenuItemArray?.length > 0
              ? BundledMenuItemArray.sort(function (a, b) {
                  return a.id - b.id;
                })
              : [],
        };
        if (order?.menu_item_type == "bundled") {
          item.hide_fixed_items = order?.hide_fixed_items ?? "no";
          item.hide_fixed_items_qr = order?.hide_fixed_items_qr ?? "no";
        }
        if (checkArray(order?.promotions) && order?.promotions?.[0]) {
          item.promotions = order?.promotions?.[0];
          item.promotions.accutalPrice = actualMenuPrice;
        }
        items.push(item);
      });
    }
  });

  return items;
}

export function parseRound(num, digits = 2, isDecimal = false) {
  if (typeof num == "number" || typeof num == "string") {
    let stringNum = typeof num == "number" ? num.toString() : num;
    if (countDecimals(num) > 2 && lastDecimalPlace(num) == 5) {
      stringNum = addLastDigitToNum(stringNum);
    }
    num = (
      Math.round(parseFloat(stringNum) * padEndingZeros(1, digits + 1)) /
      padEndingZeros(1, digits + 1)
    ).toFixed(digits);
  }
  if (isDecimal) {
    return parseFloat(num);
  }
  return num;
}

function makeRandomString(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function getRandomString() {
  return uniqid() + makeRandomString(5) + "." + moment().valueOf();
}

export function getUniTimeString() {
  const millis = Date.now();
  const time = millis / 1000;
  return time;
}

export function countDecimals(value) {
  // if (value % 1 != 0) return value.toString().split(".")?.[1].length;
  return 0;
}

export function lastDecimalPlace(value) {
  if (value % 1 != 0) return parseFloat(value.toString().slice(-1));
  return 0;
}

export function addLastDigitToNum(num, value = 1) {
  if (typeof num == "number" || typeof num == "string") {
    let stringNum = typeof num == "number" ? num.toString() : num;
    num = parseFloat(stringNum + `${value}`);
  }
  return num;
}

export function padEndingZeros(num, size) {
  var s = num + "";
  while (s.length < size) s = s + "0";
  return s;
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function checkExpiredSession(table_detail) {
  let isExpiredServicePeriod = false;
  // check service period
  const servicePeriodExpire = getSessionStorageOrDefault(
    "servicePeriodExpire",
    undefined
  );
  if (servicePeriodExpire) {
    isExpiredServicePeriod = checkExpireMerchantTime(
      servicePeriodExpire,
      table_detail?.Merchant?.timezone_offset ?? "00:00"
    );
  }
  return isExpiredServicePeriod;
  // check service period END
}

export function checkInactiveSession() {
  // check service period
  let isExpired = false;
  const inactiveSession = getSessionStorageOrDefault(
    "session_start_time",
    undefined
  );
  const timeFormat = "YYYY-MM-DD HH:mm:ss";
  if (inactiveSession) {
    const inactiveSessionTime = moment(inactiveSession, timeFormat);
    isExpired = moment().isAfter(inactiveSessionTime);
  } else {
    sessionStorage.setItem(
      "session_start_time",
      JSON.stringify(moment().add(20, "minutes").format(timeFormat))
    );
  }
  return isExpired;
  // check service period END
}

export function getMerchantDateTime(
  timezone = "00:00",
  format = "yyyy/MM/DD HH:mm:ss"
) {
  return moment(new Date()).utc().utcOffset(timezone).format(format);
}
export function getCartBody({
  recent_order_id,
  cart_detail,
  pos_cart_detail,
  user_detail,
  table_detail,
  totalTip,
  applied_voucher,
  totalDiscount,
  promoDiscount,
  finalTotalAmt,
  applied_promocode,
  xgateDetails,
  onlyTip = false,
  uuid = undefined,
  qr_customer_id = undefined,
  loyaltyToken = undefined,
  newOrderItems = false,
}) {
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  let items = [];
  const offlineId =
    recent_order_id !== undefined && recent_order_id !== ""
      ? recent_order_id
      : getRandomString();
  const isNewOrder =
    recent_order_id !== undefined && recent_order_id !== "" ? "no" : "yes";
  if (
    cart_detail &&
    onlyTip !== true &&
    cart_detail.filter((p) => p.is_added !== true || newOrderItems === true)
      .length > 0
  ) {
    cart_detail
      .filter((p) => p.is_added !== true || newOrderItems === true)
      .map((item) => {
        const modifierDetail = [];
        let discountModifierId = "";
        let discountModifierQty = null;
        if (item.modifierArray.length > 0) {
          item.modifierArray.map((modifier, index) => {
            if (modifier?.action == "discount") {
              discountModifierQty = modifier?.modifier_quantity ?? 1;
              discountModifierId = modifier?.modifier_id ?? "";
            } else {
              modifierDetail.push({
                name: modifier?.name ?? "",
                modifier_id: modifier?.modifier_id ?? "",
                modifier_group_id: modifier?.modifier_group_id ?? "",
                modifier_price: modifier?.modifier_price ?? 0,
                actual_modifier_price: modifier?.modifier_price ?? 0,
                action: modifier?.action ?? "",
                // modifier_quantity: modifier?.modifier_quantity ?? 1,
                modifier_quantity: item?.quantity ?? 1,
              });
            }
          });
        }
        let promoItem = item?.promotions;
        const amountWithoutModifiers = promoItem?.accutalPrice
          ? parseFloat(promoItem?.accutalPrice)
          : item?.amount_without_modifiers;
        const menuItemPrice =
          discountModifierQty !== null
            ? amountWithoutModifiers / discountModifierQty
            : amountWithoutModifiers ?? 0;

        let itemDetail = {
          BundledMenuItem:
            item?.menu_item_type == "bundled" &&
            item?.BundledMenuItem?.length > 0
              ? item?.BundledMenuItem
              : [],
          comment: item?.comment ?? "",
          image: item?.image ?? "",
          discount: 0,
          doubleActualAmout: item?.doubleActualAmout,
          firetime: item?.firetime ?? null,
          createDate: item?.createDate ?? "",
          is_fired: item?.is_fired ?? false,
          is_hold: item?.is_hold ?? false,
          menu_item_id: item?.id ?? "",
          menu_class_name: item?.menu_class_name ?? "",
          menu_item_name: item?.name ?? "",
          pos_name: item?.pos_name,
          kitchen_docket_language: item?.kitchen_docket_language,
          chinese_menu_item_name: item?.chinese_menu_item_name,
          default_course:item?.default_course || "",
          void: item?.void,
          posordering: item?.posordering ?? 0,
          bundleName:
            item?.menu_item_type == "bundled" ? item?.bundleName ?? "" : "",
          discountModifierID: discountModifierId,
          menu_item_price: item?.doubleActualAmout,
          menu_item_quantity:
            discountModifierQty !== null && item?.quantity
              ? discountModifierQty * item?.quantity
              : item?.quantity ?? 0,
          quantityMultipleBy:
            discountModifierQty !== null ? discountModifierQty : 1,
          menu_item_type: item?.menu_item_type ?? "",
          offlineID: item?.offline_id,
          promo141Quantity: 0,
          promotionTotaldiscount: promoItem?.promotionTotaldiscount ?? 0,
          promotions: promoItem ? [promoItem] : [],
          salesTaxAmount: item?.sales_tax_amount ?? 0,
          sales_tax: item?.is_sales_tax == true ? 1 : 0,
          serviceChargeAmount: item?.service_charge_amount ?? 0,
          service_charge: item?.is_service_charge == true ? 1 : 0,
          menu_price: item?.menu_price !== undefined ? item?.menu_price : "{}",
          subCategoryid: item?.sub_category_id ?? "",
          catID: item?.catID ?? "",
          modifiers: modifierDetail?.length > 0 ? modifierDetail : [],
        };
        // if (item?.menu_item_type == "standard") {
        //   if (promoItem) {
        //     // itemDetail obj changes
        //     if (promoItem?.type == "Re-Price") {
        //       itemDetail.menu_item_price = item?.amount_without_modifiers;
        //     }
        //   }
        // }
        if (item?.menu_item_type == "bundled") {
          itemDetail.bundlePosName = item?.bundlePosName ?? "";
          itemDetail.hide_fixed_items = item?.hide_fixed_items ?? "";
          itemDetail.hide_fixed_items_qr = item?.hide_fixed_items_qr ?? "";
          // itemDetail.menu_item_price =
          //   discountModifierQty !== null
          //     ? item?.amount / discountModifierQty
          //     : item?.amount ?? 0;
          // itemDetail.doubleActualAmout =
          //   discountModifierQty !== null
          //     ? item?.amount / discountModifierQty
          //     : item?.amount ?? 0;
          itemDetail.actual_menu_price =
            discountModifierQty !== null
              ? item?.actual_menu_price / discountModifierQty
              : item?.actual_menu_price ?? 0;
          itemDetail.kitchen_print_preference =
            item?.kitchen_print_preference ?? null;
          itemDetail.modifiers = [];
        }
        // promotion changes
        if (promoItem) {
          // itemDetail obj changes
          if (promoItem?.type == "Re-Price") {
            if (item?.menu_item_type == "bundled") {
              itemDetail.menu_item_price =
                item.amount_without_modifiers + item.modifersPrice;
              // itemDetail.actual_menu_price = promoItem?.accutalPrice;
            } else {
              itemDetail.menu_item_price = item?.amount_without_modifiers;
              // itemDetail.menu_item_price = promoItem.discountPerItem;
            }
          }
          // itemDetail obj changes END

          promoItem.discountPerItem =
            promoItem?.type == "Re-Price" ? 0 : promoItem.discountPerItem;
          promoItem.promotionTotaldiscount =
            promoItem.discountPerItem * item?.quantity;
          // loyality voucher
          const voucherKey = applied_voucher.findIndex(
            (p) => p.Code == promoItem?.code
          );
          if (voucherKey > -1) {
            const voucherItem = applied_voucher[voucherKey];
            promoItem.isLoyaltyVoucher = "yes";
            promoItem.voucherName = voucherItem?.Code;
          }
          // loyality voucher END
        }
        // promotion changes END

        items.push(itemDetail);
      });
  }

  if (pos_cart_detail?.items && pos_cart_detail?.items.length > 0) {
    items = [...items, ...pos_cart_detail?.items];
  }

  const loyalityCardName =
    applied_voucher.length > 0
      ? applied_voucher.map((voucher) => voucher?.VoucherNo)
      : [];
  let totalPromoArr = [];
  if (
    checkArray(applied_voucher) &&
    checkArray(applied_voucher.filter((p) => p.Type == "Discount"))
  ) {
    const newTotalPromoArr = applied_voucher
      .filter((p) => p.Type == "Discount")
      .map((voucher) => {
        return {
          amount: voucher?.TypeValue ?? 0,
          code: voucher?.VoucherNo ?? "",
          combo_allowed: "",
          id: getRandomString(),
          isLoyaltyVoucher: "",
          name: voucher?.Name ?? "",
          promotionTotaldiscount: voucher?.discountAmt ?? 0,
          type: "loyalty",
          voucherName: "",
        };
      });
    totalPromoArr = [...newTotalPromoArr];
  }
  if (applied_promocode && applied_promocode !== null) {
    const newTotalPromoArr = [
      {
        amount: applied_promocode?.amount ?? "0",
        code: applied_promocode?.code ?? "",
        combo_allowed: applied_promocode?.combo_allowed ?? "",
        id: getRandomString(),
        isLoyaltyVoucher: applied_promocode?.loyalty_only_promotion ?? "",
        name: applied_promocode?.name ?? "",
        promotionTotaldiscount: totalDiscount ?? 0,
        type: applied_promocode?.type ?? "",
        voucherName: applied_promocode?.promotion_name,
      },
    ];
    totalPromoArr = [...totalPromoArr, ...newTotalPromoArr];
  }
  const itemLevelDiscount = checkArray(items)
    ? parseFloat(
        items.reduce(function (sum, current) {
          let discountedPrc = current?.discount ?? 0;
          const itemTotalAmt =
            parseFloat(current?.amount ?? 0) *
            parseFloat(current?.quantity ?? 0);
          if (
            current?.discountType != "amt" &&
            discountedPrc > 0 &&
            itemTotalAmt > 0
          ) {
            discountedPrc = percentage(discountedPrc, itemTotalAmt, 4);
          }
          return sum + discountedPrc;
        }, 0)
      )
    : 0;
  const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";
  const currDateTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy/MM/DD HH:mm:ss");
  let body = {
    uuid: uuid ?? user_detail?.device_id,
    qr_customer_id: qr_customer_id ?? user_detail?.id,
    merchant_id: table_detail?.Merchant?.id,
    is_new_order: isNewOrder,
    no_of_seats: table_detail?.PosTableMaster?.maximum_seat,
    order_offline_id: offlineId,
    order_time: currDateTime,
    promotions: totalPromoArr,
    payment_type: pos_cart_detail?.payment_type ?? [],
    order_status: "new",
    is_refund: false,
    service_charge: defaultServiceCharge,
    finishTime: null,
    tips: totalTip,
    refund: 0,
    discount: itemLevelDiscount + totalDiscount + promoDiscount,
    method_type_id: "",
    payment_method: "",
    payment_method_id: "",
    paid_amount: 0,
    sales_tax: defaultTotalSaleTax,
    order_total:
      defaultTotalCartAmount +
      itemLevelDiscount +
      totalDiscount +
      promoDiscount,
    table_no: table_detail?.PosTableMaster?.table_number ?? "",
    channel_name: "Walk-in",
    finalTotalAmt:
      parseFloat(finalTotalAmt) - itemLevelDiscount > 0
        ? parseFloat(finalTotalAmt) - itemLevelDiscount
        : 0,
    receiver: {
      customer_name: null,
      customer_phone: null,
      customer_email: user_detail?.email ?? null,
      customer_address: null,
    },
    menu_item: items,
  };
  if (loyaltyToken || xgateDetails?.membership?.code) {
    body.loyaltyToken = loyaltyToken ?? xgateDetails?.membership?.code;
  }
  if (applied_voucher && applied_voucher.length > 0) {
    body.pointToRedeem = applied_voucher.reduce(function (sum, current) {
      return sum + parseFloat(current.TypeValue);
    }, 0);
    body.redeemPointDiscount = totalDiscount;
    body.loyaltyToken = user_detail?.card_token ?? "";
    body.loyaltyCardNumber = user_detail?.CardNo ?? "";
    body.cvc = user_detail?.card_cvc ?? "";
    body.loyaltyVouchers =
      loyalityCardName.length > 0 ? loyalityCardName.join(",") : "";
  }

  return body;
}

export function getPosCartBody(item) {
  let data = [];

  let itemDetail = {
    BundledMenuItem:
      item?.menu_item_type == "bundled" && item?.BundledMenuItem?.length > 0
        ? item?.BundledMenuItem
        : [],
    comment: item?.comment ?? "",
    image: item?.image ?? "",
    discount: item?.discount,
    doubleActualAmout: item?.doubleActualAmout,
    actual_menu_price:item?.actual_menu_price,
    firetime: item?.firetime ?? null,
    createDate: item?.createDate ?? "",
    is_fired: item?.is_fired ?? false,
    is_hold: item?.is_hold ?? false,
    is_server_fired: item?.is_server_fired ? item?.is_server_fired : "",
    menu_item_id: item?.id || item?.menu_item_id ||   "",
    menu_class_name: item?.menu_class_name ?? "",
    menu_item_name: item?.menu_item_name ?? "",
    pos_name: item?.pos_name,
    kitchen_docket_language: item?.kitchen_docket_language,
    chinese_menu_item_name: item?.chinese_menu_item_name,
    default_course:item?.default_course||"",
    void: item?.void,
    posordering: item?.posordering ?? 0,
    bundleName: item?.menu_item_type == "bundled" ? item?.bundleName ?? "" : "",
    discountModifierID: item?.discountModifierID,
    menu_item_price: item?.menu_item_price,
    menu_item_quantity: item?.menu_item_quantity,
    // menu_price :item?.menu_price,
    quantityMultipleBy:
      item?.quantityMultipleBy !== null ? item?.quantityMultipleBy : 1,
    menu_item_type: item?.menu_item_type ?? "",
    offlineID: item?.offlineID,
    promo141Quantity: item?.promo141Quantity,
    promotionTotaldiscount: item?.promotionTotaldiscount ?? 0,
    promotions: item?.promotions ? item?.promotions : [],
    salesTaxAmount: item?.salesTaxAmount ?? 0,
    // sales_tax: item?.is_sales_tax == true ? 1 : 0,
    sales_tax: item?.sales_tax,

    serviceChargeAmount: item?.serviceChargeAmount?? 0,
    // service_charge: item?.is_service_charge == true ? 1 : 0,
    service_charge: item?.service_charge,
   


    menu_price: item?.menu_price !== undefined ? item?.menu_price : "{}",
    subCategoryid: item?.subCategoryid?? "",
    catID: item?.catID ?? "",
    modifiers: item?.modifiers?.length > 0 ? item?.modifiers : [],
    fired_by: item?.fired_by ?? "",
    hide_fixed_items_qr:item?.hide_fixed_items_qr??"",
    hide_fixed_items : item?.hide_fixed_items ?? "",
    kitchen_print_preference:item?.kitchen_print_preference??"",
    bundlePosName:item?.bundlePosName??"",
    discountType:item?.discountType??"",
    item_sales_type:item?.item_sales_type??"",
    sort_order:item?.sort_order??"",
    orderID:item?.orderID??"",
    sub_cat_name:item?.sub_cat_name??"",
    not_applying_serv_char:item?.not_applying_serv_char??""
    
  };

  // if (item?.menu_item_type == "bundled") {
  //   itemDetail.bundlePosName = item?.bundlePosName ?? "";
  //   itemDetail.hide_fixed_items = item?.hide_fixed_items ?? "";
  //   itemDetail.hide_fixed_items_qr = item?.hide_fixed_items_qr ?? "";

  //   itemDetail.actual_menu_price =
  //     discountModifierQty !== null
  //       ? item?.actual_menu_price / discountModifierQty
  //       : item?.actual_menu_price ?? 0;
  //   itemDetail.kitchen_print_preference =
  //     item?.kitchen_print_preference ?? null;
  //   itemDetail.modifiers = [];
  // }

  // if (promoItem) {

  //   if (promoItem?.type == "Re-Price") {
  //     if (item?.menu_item_type == "bundled") {
  //       itemDetail.menu_item_price =
  //         item.amount_without_modifiers + item.modifersPrice;

  //     } else {
  //       itemDetail.menu_item_price = item?.amount_without_modifiers;

  //     }
  //   }

  //   promoItem.discountPerItem =
  //     promoItem?.type == "Re-Price" ? 0 : promoItem.discountPerItem;
  //   promoItem.promotionTotaldiscount =
  //     promoItem.discountPerItem * item?.quantity;

  //   const voucherKey = applied_voucher.findIndex(
  //     (p) => p.Code == promoItem?.code
  //   );
  //   if (voucherKey > -1) {
  //     const voucherItem = applied_voucher[voucherKey];
  //     promoItem.isLoyaltyVoucher = "yes";
  //     promoItem.voucherName = voucherItem?.Code;
  //   }
  // }

  data.push(itemDetail);
  return data[0];
}

export function sortCartItems(cart) {
  if (cart && cart !== null) {
    let newCart = [...cart];
    let fireTimeCart = [];
    let normalCart = [];
    if (
      newCart.findIndex(
        (p) => p.firetime && p.firetime != "" && p.firetime != null
      ) > -1
    ) {
      fireTimeCart = newCart
        .filter((p) => p.firetime && p.firetime != "" && p.firetime != null)
        .sort(function (a, b) {
          return (
            moment(a.firetime).format("x") - moment(b.firetime).format("x")
          );
        });
    }
    normalCart = newCart
      .filter((p) => !p.firetime || p.firetime == "" || p.firetime == null)
      .sort(function (a, b) {
        return (
          moment(a.createDate).format("x") - moment(b.createDate).format("x")
        );
      });
    return fireTimeCart.concat(normalCart);
  }
  return cart;
}

export function parseStringToDate(
  date,
  from = "YYYY-MM-DD HH-mm-ss",
  to = "YYYY-MM-DD"
) {
  let res = undefined;
  try {
    if (date && typeof date === "string" && date !== "") {
      res = moment(date, from).format(to);
    }
  } catch (e) {}
  return res;
}

export function parseStringToFloat(num, to = 2) {
  let res = 0;
  try {
    if (num && num !== null) {
      const parseNum = parseFloat(num);
      if (parseNum) {
        res = parseNum.toFixed(to);
      }
    }
  } catch (e) {}
  return res;
}

export function groupByArray(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}

export const imageExists = (url) =>
  new Promise(function (resolve, reject) {
    var img = new Image();
    img.src = url;

    img.onload = function () {
      resolve(url);
    };
    img.onerror = function () {
      reject(url);
    };
  });

export const checkTrendingAndPopularApplied = (filters, item) => {

  // let res = filters && filters !== "" ? false : true;
  let res = true;

  const filterArr = filters && filters !== "" ? filters?.split(",") : [];
  
  if (filterArr.includes("0") && item.is_popular != 1) {
    res = false;
  } else if (filterArr.includes("-1") && item.is_trending != 1) {
    res = false;
  }
 
  return res;
};

export function checkSubCatAvailable(subCat, table_detail = null) {
  let res = false;
  const dates = subCat?.ApplicablePeriod;
  const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";
  const dayFormat = "yyyy-MM-DD";
  const timeFormat = "HH:mm:ss";
  const currDateTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy-MM-DD HH:mm:ss");
  const currDate = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy-MM-DD");
  const currTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("HH:mm:ss");
  const currDay = moment(new Date()).utc().utcOffset(timezone).format("dddd");


  if (dates && dates !== null) {
    if (dates?.length > 0) {
      for (let index = 0; index < dates?.length; index++) {
        const element = dates[index];
        const dayArr = element?.applicable_days;
        let holidayVerified = false;
        if (
          table_detail?.TodayHoliday === true
          || table_detail?.EveHoliday === true
        ) {
          holidayVerified = false;
          if (
            table_detail?.TodayHoliday === true &&
            element?.public_holiday == true
          ) {
            holidayVerified = true;
          }
          if (
            table_detail?.EveHoliday === true &&
            element?.eve_public_holiday ==  true
          ) {
            holidayVerified = true;
          }
        }
        
        if (
          element?.start_time &&
          element?.end_time &&
          element?.start_date &&
          element?.end_date &&
          ((dayArr.length > 0 && dayArr.indexOf(currDay) > -1) ||
            holidayVerified)
        ) {
          const beforeDay = moment(element?.start_date, dayFormat);
          const afterDay = moment(element?.end_date, dayFormat);
          let beforeTime = moment(element?.start_time, timeFormat);
          let afterTime = moment(element?.end_time, timeFormat);
          if (beforeTime.isAfter(afterTime)) {
            // if before time is greater then after time add one day
            const fullFormat = "yyyy-MM-DD HH:mm:ss";
            beforeTime = moment(`${currDate} ${beforeTime.format(timeFormat)}`);
            afterTime = moment(
              `${moment(currDate, dayFormat)
                .add(1, "days")
                .format(dayFormat)} ${beforeTime.format(timeFormat)}`
            );
            if (
              moment(currDate, dayFormat).isBetween(
                beforeDay,
                afterDay,
                null,
                "[]"
              ) &&
              moment(currDateTime, fullFormat).isBetween(
                beforeTime,
                afterTime,
                null,
                "[]"
              )
            ) {
              res = true;
              break;
            }
          } else {
           
            if (
              moment(currDate, dayFormat).isBetween(
                beforeDay,
                afterDay,
                null,
                "[]"
              ) &&
              moment(currTime, timeFormat).isBetween(
                beforeTime,
                afterTime,
                null,
                "[]"
              )
            ) {
              res = true;
              break;
            }
          }
        }
      }
    } else {
      res = true;
    }
  }
  
  if (
    !(
      res &&
      checkArray(subCat?.MenuItem) &&
      subCat?.MenuItem.findIndex((p) =>
        checkItemAvailable(p, timezone, table_detail)
      ) > -1
    )
  ) {
    res = false;
  }
  return res;
}

export function checkArray(arr, res = true, val = false) {
  return arr && Array.isArray(arr) && arr.length > 0 ? res : val;
}

export function checkItemAvailable(
  item,
  timezone = "00:00",
  table_detail = null
) {
  let res = false;
  // const day_format = "YYYY-MM-DD";
  // const day_currTime = moment(new Date())
  //   .utc()
  //   .utcOffset(timezone)
  //   .format(day_format);
  // if (
  //   item?.servicePeriod?.[0]?.period_start !== "" &&
  //   item?.servicePeriod?.[0]?.period_end
  // ) {
  //   const beforeDay = moment(
  //     item?.servicePeriod?.[0]?.period_start,
  //     day_format
  //   );
  //   const afterDay = moment(item?.servicePeriod?.[0]?.period_end, day_format);
  //   res = moment(day_currTime, day_format).isBetween(beforeDay, afterDay);
  // }
  // return res;

  const format = "HH:mm:ss";
  const currTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("HH:mm:ss");
  const currDay = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("ddd")
    .toLowerCase();
  let isInTimeSlot = false;
  
  if (checkArray(item?.applicablePeriod)) {
    for (let index = 0; index < item?.applicablePeriod?.length; index++) {
      const key = Object.keys(item?.applicablePeriod[index])[0];
      const element = item?.applicablePeriod[index][key];

      const timeString = element?.time;
      const dayString = element?.availableday;
      const timeArr = timeString.split("-");
      const dayArr = dayString.split(",");

      if (timeArr.length > 1) {
        const beforeTime = moment(timeArr?.[0], format);
        const afterTime = moment(timeArr?.[1], format);
        if (
          moment(currTime, format).isBetween(beforeTime, afterTime, null, "[]")
        ) {
          isInTimeSlot = true;
          if (dayArr.length > 0 && dayArr.indexOf(currDay) > -1) {
            res = true;
            break;
          }
        }
      }
    }
  } else {
    res = true;
  }

  if (
    !res &&
    !isInTimeSlot &&
    table_detail?.service_period?.period_name &&
    item?.applicablePeriod.findIndex((p) => {
      let curRes = false;
      const conDayStr =
        p?.[table_detail?.service_period?.period_name]?.availableday;
      const curDayArr = conDayStr ? conDayStr.split(",") : [];
     
      if (curDayArr.indexOf(currDay) > -1) curRes = true;
      return curRes;
    }) > -1
  ) {
    res = true;
  }
  return res;
}

export function checkExpireMerchantTime(date, timezone = "00:00") {
  let res = false;
  const day_format = "yyyy-MM-DD HH:mm:ss";
  const currTime = moment(
    moment(new Date()).utc().utcOffset(timezone).format(day_format),
    day_format
  );
  const merchantStartDate = moment(date, "yyyy-MM-DD 00:00:00");
  const merchantEndDate = moment(
    `${moment(date, "yyyy-MM-DD")
      .add(1, "days")
      .format("yyyy-MM-DD")} 05:00:00`,
    day_format
  );
  
  if (!currTime.isBetween(merchantStartDate, merchantEndDate)) {
    res = true;
  }
  return res;
}

export function getAllMenuIds(cart_details) {
  let res = [];
  if (checkArray(cart_details)) {
    const itemIds = pluckArray(cart_details, "id", true);
    let bundleMenuIds = [];
    cart_details
      .filter((p) => p?.menu_item_type == "bundled")
      .map((item) => {
        bundleMenuIds = bundleMenuIds.concat(
          pluckArray(item?.BundledMenuItem, "bundle_menu_item_id", true)
        );
      });
    res = itemIds.concat(bundleMenuIds);
    if (checkArray(res)) {
      res = res.filter((item, pos) => res.indexOf(item) == pos);
    }
  }
  return res;
}

export function checkVoucherAvailable(
  dates,
  table_detail = null,
  timezone = "00:00"
) {
  let res = false;
  const dayFormat = "yyyy-MM-DD";
  const timeFormat = "HH:mm:ss";
  const format = "yyyy-MM-DD HH:mm:ss";
  const currDate = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy-MM-DD");
  const currDateTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy-MM-DD HH:mm:ss");
  const currTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("HH:mm:ss");
  const currDay = moment(new Date()).utc().utcOffset(timezone).format("dddd");

  if (dates && dates !== null) {
    let holidayVerified = false;
    if (
      table_detail?.TodayHoliday === true
      // || table_detail?.EveHoliday === true
    ) {
      holidayVerified = false;
      if (
        table_detail?.TodayHoliday === true &&
        dates?.public_holiday == "Yes"
      ) {
        holidayVerified = true;
      }
      // if (
      //   table_detail?.EveHoliday === true &&
      //   dates?.eve_public_holiday == "Yes"
      // ) {
      //   holidayVerified = true;
      // }
    }
    if (dates?.length > 0) {
      for (let index = 0; index < dates?.length; index++) {
        const element = dates[index];
        const dayString = element?.days;
        const timeArr = [element?.start_datetime, element?.end_datetime];
        const dayArr = dayString.split(",");

        if (
          timeArr.length > 1 &&
          element?.start_datetime &&
          element?.end_datetime &&
          ((dayArr.length > 0 && dayArr.indexOf(currDay) > -1) ||
            holidayVerified)
        ) {
          const beforeDay = moment(
            element?.start_datetime.split(" ")?.[0],
            dayFormat
          );
          const afterDay = moment(
            element?.end_datetime.split(" ")?.[0],
            dayFormat
          );
          let beforeTime = moment(
            element?.start_datetime.split(" ")?.[1],
            timeFormat
          );
          let afterTime = moment(
            element?.end_datetime.split(" ")?.[1],
            timeFormat
          );
          if (beforeTime.isAfter(afterTime)) {
            // if before time is greater then after time add one day
            const fullFormat = "yyyy-MM-DD HH:mm:ss";
            beforeTime = moment(`${currDate} ${beforeTime.format(timeFormat)}`);
            afterTime = moment(
              `${moment(currDate, dayFormat)
                .add(1, "days")
                .format(dayFormat)} ${beforeTime.format(timeFormat)}`
            );
            if (
              moment(currDate, dayFormat).isBetween(
                beforeDay,
                afterDay,
                null,
                "[]"
              ) &&
              moment(currDateTime, fullFormat).isBetween(
                beforeTime,
                afterTime,
                null,
                "[]"
              )
            ) {
              res = true;
              break;
            }
          } else {
            if (
              moment(currDate, dayFormat).isBetween(
                beforeDay,
                afterDay,
                null,
                "[]"
              ) &&
              moment(currTime, timeFormat).isBetween(
                beforeTime,
                afterTime,
                null,
                "[]"
              )
            ) {
              res = true;
              break;
            }
          }
        }
      }
    } else {
      res = true;
    }
  }
  return res;
}

// Axios API call

// let getToken = async () => await AsyncStorage.getItem("access-token");
let getToken;

const client = axios.create({
  baseURL: "",
  // auth: { Authorization: "Bearer " + { getToken } },
});

export const apiCall = function (method, route, body = null, token = null) {
  getToken = token;
  const onSuccess = function (response) {
    console.debug("Request Successful!", response);
    return response.data;
  };

  const onError = function (error) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  let reqObj = {
    method,
    url: `${route}`,
    data: body,
  };
  if (token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    client.defaults.headers.common["Content-Type"] = `application/json`;
    client.defaults.headers.common[
      "SoapAction"
    ] = `https://matrixapis/JSONCommand2`;
    console.log("common-headers", client.defaults.headers.common);

    // client.defaults.headers.common["origin"] = `http://localhost:3000`;
    // client.defaults.headers.common[
    //   "Content-Type"
    // ] = `application/x-www-form-urlencoded`;
    // client.defaults.proxy.host = "http://www.demoapp.com";
    // reqObj.headers = {
    //   // "Content-Type": `application/json`,
    //   // Accept: `application/json`,
    //   Authorization: `Bearer ${token}`,
    //   // SoapAction: "https://matrixapis/JSONCommand2",
    //   // "Access-Control-Allow-Credentials": "true",
    //   // "Access-Control-Allow-Methods":
    //   //   "GET, POST, PATCH, PUT, DELETE, OPTIONS, HEAD",
    //   // "Access-Control-Allow-Origin": "*",
    //   // "Access-Control-Allow-Headers":
    //   //   "Origin, X-Requested-With, Content-Type, Accept",
    // };
  }

  return client(reqObj).then(onSuccess).catch(onError);
};

export const PAYMENT_TYPE_ENUM = {
  APPLE_PAY: 1,
  CARD_PAY: 2,
  GOOGLE_PAY: 3,
};

export const gateTime = () => {
  const originalDate = new Date();
  const year = originalDate.getFullYear();
  const month = originalDate.getMonth() + 1;
  const day = originalDate.getDate();
  const formattedDate = `${year}/${month.toString().padStart(2, "0")}/${day
    .toString()
    .padStart(2, "0")}`;
  const hours = originalDate.getHours();
  const minutes = originalDate.getMinutes();
  const seconds = originalDate.getSeconds();
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  const formattedDateTime = `${formattedDate},${formattedTime}`;
  return formattedDateTime;
};

export const removeTicketPayload = (data, index, combined_menu, merchantId) => {
  const offlineIDString =
    index == 0 ? "" : data?.menu_item.map((item) => item.offlineID);
  

  const payload = {
    Cancelmenu_item: data?.Cancelmenu_item || [],
    void_menu_ticket: data?.void_menu_ticket || [],
    printjobCount: data?.printjobCount || 1,
    merchant_id: merchantId,
    is_new_order: "no",
    no_of_seats: data?.no_of_seats,
    order_offline_id: data?.order_offline_id,
    course_List:data?.course_List,
    order_time: gateTime(),
    // "order_time": data?.order_time,
    promotions: [],
    payment_type: [],
    order_status: index == 0 ? "open" : "cancelled",
    is_refund: data?.is_refund,
    service_charge: data?.service_charge,
    finishTime: data?.finishTime,
    tips: data?.tips,
    refund: data?.Acceptrefund,
    discount: data?.discount,
    method_type_id: data?.method_type_id,
    payment_method: data?.payment_method,
    payment_method_id: data?.payment_method_id,
    paid_amount: data?.paid_amount,
    // printjobCount: data?.printjobCount,
    sales_tax: data?.sales_tax,
    order_total: data?.order_total,
    table_no: data?.table_no,
    channel_name: data?.channel_name,
    finalTotalAmt: data?.finalTotalAmt,
    receiver: data?.receiver,
    menu_item: index == 0 ? combined_menu : [],
    employee_id: data?.employee_id,
    menuitem_offline_ids: index == 0 ? "" : offlineIDString.join(","),
    qr_customer_id: data?.qr_customer_id,
    order_type: data?.qr_customer_id ? "qr" : "server",
    is_new_order: "no",
    refund: data.data,
  };
  return payload;
};

export const filter_CombineOrder_Data = (combined_order_Data) => {
  const mergedMenuItems = [];
  for (const order of combined_order_Data) {
    for (const menuItem of order.menu_item) {
      mergedMenuItems.push(menuItem);
    }
  }
  return mergedMenuItems;
};

export const updateCartTemporaryData = (
  table_detail,
  payload,
  localCartDetails,
  dispatch
) => {
  const data = localCartDetails;
  const id = table_detail?.PosTableMaster?.table_id;
  const index = data.findIndex((obj) => obj.id == id);
  if (index !== -1) {
    data.splice(index, 1);
  }
  if (payload) {
    data.push(payload);
  }

  dispatch(saveCartTemporary(data));
};

export const shortArrayTable = (data) => {
  data.sort((a, b) => {
    const aRecentOrderDate = getRecentOrderDate(a?.OpenOrders);
    const bRecentOrderDate = getRecentOrderDate(b?.OpenOrders);

    return new Date(bRecentOrderDate) - new Date(aRecentOrderDate);
  });
};

export const getRecentOrderDate = (openOrders) => {
  if (openOrders.length > 0) {
    return openOrders.reduce((recentDate, order) => {
      const orderDate = new Date(order?.order_create_date);
      return orderDate > recentDate ? orderDate : recentDate;
    }, new Date(0));
  }
  return new Date(0);
};

export const getEberURL = (payload) => {
  const str = API.eber_Login;
  if (payload?.merchant_id) {
    str = str + "?merchant_id=" + payload?.merchant_id;
  } else if (payload?.email) {
    str = str + "&email=" + payload?.merchant_id;
  } else if (payload?.phone) {
    str = str + "&email=" + payload?.merchant_id;
  } else if (payload?.external_member_id) {
    str = str + "&email=" + payload?.merchant_id;
  }

  return "str";
};

export function getCartItems(cart_detail, table_detail) {
  if (
    checkArray(cart_detail) &&
    checkArray(cart_detail.filter((p) => p.is_added == true))
  ) {
    return sortCartItems(cart_detail.filter((p) => p.is_added == true)).map(
      function (item, itemIndex) {
        const itemPrice =
          item?.promotions && item?.promotions?.type !== "Re-Price"
            ? item?.promotions?.accutalPrice
            : item?.item_amount;
        const discountedTotalPrice =
          parseFloat(
            item?.promotions && item?.promotions?.type !== "Re-Price"
              ? item?.promotions?.discountPerItem
              : 0
          ) * item.quantity;
        return (
          <div className="draggable_bg">
          <div className="new_items" key={itemIndex}>
            <div
              className={`menu-box card-padding  ${
                item?.is_added == true ? "added" : ""
              }`}
            >
              <div className="d-flex orderMenu px-0">
                <figure className="menu-img">
                  <ImageTag
                    src={item.image}
                    defaultimage={table_detail?.Merchant?.logo}
                    removeImage={true}
                  />
                </figure>
                <div className="col-7 menu-content pl-0">
                  <h4>
                    <span
                      className="txt-green"
                      style={{
                        color: table_detail?.QrOrdering?.brand_color,
                      }}
                    >
                      {item.quantity}x
                    </span>{" "}
                    {item.pos_name} @{parseFloat(itemPrice).toFixed(2)}{" "}
                  </h4>
                  {getModifierItems(item, table_detail)}
                </div>
                <div className="col-2 px-0 text-right">
                  <p className="pricing-lg">
                    {" "}
                    <small className="fw-bolder" >{`${table_detail?.currency ?? ""}`}</small>
                    {parseFloat(item.total + discountedTotalPrice).toFixed(
                      2
                    )}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          </div>
        );
      }
    );
  }
  return null;
}

export function getModifierItems(item, table_detail) {
  let res = null;
  if (item?.menu_item_type == "standard" && item?.modifierArray?.length > 0) {
    res = item.modifierArray
      .filter((p) => p.action != "discount")
      .map((modifier, modifierIndex) => {
        const priceTag = `(${getModifierActionSign(modifier.action)}${
          table_detail?.currency ?? ""
        }${parseFloat(modifier.unit_price).toFixed(2)})`;
        return (
          <ul className="other-options" key={modifierIndex}>
            {modifier.unit_price !== 0 && (
              <li>
                {`${getModifierActionName(modifier.action)} ${modifier.name}`} (
                {checkNegNum(modifier.unit_price) ? "- " : ""}
                {`${table_detail?.currency ?? ""}`}
                {parseFloat(
                  checkNegNum(modifier.unit_price)
                    ? makeNegToPosNum(modifier.unit_price)
                    : modifier.unit_price
                ).toFixed(2)}
                )
              </li>
            )}
            {modifier.unit_price === 0 && <li>{`${modifier.name}`}</li>}
          </ul>
        );
      });
  } else if (
    item?.menu_item_type == "bundled" &&
    item?.BundledMenuItem?.length > 0
  ) {
    res = item.BundledMenuItem.filter((bunItem) => {
      let filterRes = true;
      if (
        item?.hide_fixed_items_qr == "yes" &&
        bunItem?.option_series_name == "Fixed Items" &&
        bunItem?.bundledMenuitemModifers?.length == 0
      ) {
        filterRes = false;
      }
      return filterRes;
    })
      .sort(
        (a, b) => parseInt(a.option_series_id) - parseInt(b.option_series_id)
      )
      .map((bundle, bundleIndex) => {
        let modiferArr = null;
        if (bundle?.bundledMenuitemModifers?.length > 0) {
          modiferArr = bundle.bundledMenuitemModifers
            .filter((p) => p.action != "discount")
            .map((modifier, modifierIndex) => {
              const priceTag =
                modifier.unit_price > 0
                  ? `(@${parseFloat(modifier.unit_price).toFixed(2)})`
                  : "";
              return (
                <ul className="other-options" key={modifierIndex}>
                  <li>
                    {modifier.unit_price !== 0 && (
                      <>
                        {`${getModifierActionName(modifier.action)} ${
                          modifier.name
                        }`}{" "}
                        ({checkNegNum(modifier.unit_price) ? "- " : ""}
                        {`${table_detail?.currency ?? ""}`}
                        {parseFloat(
                          checkNegNum(modifier.unit_price)
                            ? makeNegToPosNum(modifier.unit_price)
                            : modifier.unit_price
                        ).toFixed(2)}
                        )
                      </>
                    )}
                    {modifier.unit_price === 0 && (
                      <>{`${
                        !["add", "remove", "swap"].includes(modifier.action)
                          ? `${modifier.action} `
                          : ""
                      }${modifier.name}`}</>
                    )}
                    {`${
                      modifierIndex + 1 != bundle.bundledMenuitemModifers.length
                        ? ","
                        : ""
                    }`}
                  </li>
                </ul>
              );
            });
        }
        const bundlePriceTag =
          bundle.amount > 0 ? `(@${parseFloat(bundle.amount).toFixed(2)})` : "";
        const bundleQuantityTag =
          bundle.quantity > 1 ? ` x ${parseInt(bundle.quantity)} ` : " ";
        let alreadyExist = getSameBundleItemIndex(item, bundle);
        const showComma =
          alreadyExist > -1 && alreadyExist < bundleIndex ? true : false;
        return (
          <ul className="other-options" key={bundleIndex}>
            <li>
              {showComma && alreadyExist > -1 ? "," : ""}
              {!showComma ? `${bundle?.option_series_name}:` : ""}{" "}
              {bundle?.bundle_menu_item_name}
              {`${bundleQuantityTag}`}
              {`${bundlePriceTag}`}
              {modiferArr}
            </li>
          </ul>
        );
      });
  }
  return res;
}

export function getSameBundleItemIndex(item, bundle) {
  let res = -1;
  if (item?.BundledMenuItem?.length > 0) {
    for (let index = 0; index < item?.BundledMenuItem?.length; index++) {
      const element = item.BundledMenuItem[index];
      if (
        element.id != bundle.id &&
        element.option_series_id == bundle.option_series_id
      ) {
        res = index;
        break;
      }
    }
  }
  return res;
}

export const STRINGS = {
  PROMOTION: "Promotions",
  CUSTOMER: "Customer",
  EXISTING: "Existing",
  NEW: "New",
  ARE_YOU: "Are you a new customer or an existing customer?",
  OR: "OR",
  BACK: "Back",
  TABLE: "Table",
  CONTINUE_AS_GUEST: "Continue as guest",
  SUBMIT: "Submit",
  RESEND: "Resend",
  QR_SCAN: "Scan QR",
  MEMBER_ID: "Scan QR",
  FOR_NEW_CUSTOMER:
    "For new customers, you may choose to provide your email to receive non-loyalty member future offers & promotions or simply continue as a guest.",
  SUBMIT_EMAIL: "Submit email for OTP verification",
  MOBILE_NUMBER: "Mobile Number",
  MOBILE_VALUE: "mobile",
  EMAIL: "Email Address",
  EMAIL_VALUE: "email",
  MEMBER_ID: "Member ID",
  MEMBER_ID_VALUE: "memberID",
  SCAN_QR: "Scan QR",
  SCAN_QR_CAMERA: "Camera",
  SCAN_QR_GALLERY: "Gallery",

  SCAN_QR_VALUE: "qr",
  SCAN_QR_VALUE_CAMERA: "qr",
  SCAN_QR_VALUE_GALLERY: "qr",

  INPUT_TYPE_EMAIL: "email",
  INPUT_TYPE_PASSWORD: "password",
  INPUT_TYPE_TEXT: "text",
  INPUT_TYPE_NUMBER: "number",
  INPUT_NAME_EMAIL: "email",
  OTP: "otp",
  ENTER_OTP: "Enter OTP",
  INTERNET_ERROR: "Please Check Your Internet ",
  BASKET_SUMMARY: "Basket Summary",
  ADD_TO_BASKET: "Add to Basket",
  BASKET: "Basket",
  LOYALTY: "Loyalty",
};

export const LOGIN_EBER_COMO_INPUT_TYPE = [
  {
    id: 1,
    label: STRINGS.MOBILE_NUMBER,
    value: STRINGS.MOBILE_VALUE,
    icon: phonerotary,
  },
  {
    id: 2,
    label: STRINGS.EMAIL,
    value: STRINGS.EMAIL_VALUE,
    icon: envelope,
  },
  {
    id: 3,
    label: STRINGS.MEMBER_ID,
    value: STRINGS.MEMBER_ID_VALUE,
    icon: idbadge,
  },
  {
    id: 4,
    label: STRINGS.SCAN_QR,
    value: STRINGS.SCAN_QR_VALUE,
    icon: qrcode,
    subLabel: [
      {
        lable: STRINGS.SCAN_QR_CAMERA,
        icon: qrcode,
        value: STRINGS.SCAN_QR_CAMERA,
      },
      {
        lable: STRINGS.SCAN_QR_GALLERY,
        icon: qrcode,
        value: STRINGS.SCAN_QR_GALLERY,
      },
    ],
  },
];

export const LOGIN_XGATE_INPUT_TYPE = [
  {
    id: 1,
    label: STRINGS.MOBILE_NUMBER,
    value: STRINGS.MOBILE_VALUE,
    icon: phonerotary,
  },
  {
    id: 2,
    label: STRINGS.MEMBER_ID,
    value: STRINGS.MEMBER_ID_VALUE,
    icon: idbadge,
  },
];

export const LOGIN_DEFAULT_INPUT_TYPE = [
  {
    id: 1,
    label: STRINGS.EMAIL,
    value: STRINGS.EMAIL_VALUE,
    icon: envelope,
  },
];
export const selectLogin = (data) => {
  switch (data) {
    case "xgate":
      return LOGIN_XGATE_INPUT_TYPE;
    case "como":
      return LOGIN_EBER_COMO_INPUT_TYPE;
    case "eber":
      return LOGIN_EBER_COMO_INPUT_TYPE;
    default:
      return LOGIN_DEFAULT_INPUT_TYPE;
  }
};




export const  isTokenExpired = (tokenExpirationTime) =>{
  
  // const expirationDate = new Date("2024-03-08 10:45:38");
  const expirationDate = new Date(tokenExpirationTime);



// const test = new date()
  const currentTime =  new Date().toUTCString().slice(0, -4);

  


 
  return moment(currentTime).format("yyyy-MM-DD HH:mm:ss") > moment(expirationDate).format("yyyy-MM-DD HH:mm:ss");
}

export const  capitalizeWordInString = (inputString) => {
  // Remove the word "notes" from the input string
  const stringWithoutNotes = inputString.replace('notes', '');

  // Split the modified string into words
  const words = stringWithoutNotes.split(" ");

  // Iterate through the words
  for (let i = 0; i < words.length; i++) {
      // Capitalize the words
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back together into a string
  return words.join(" ");
}