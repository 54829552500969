import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { Motion, spring, presets } from "react-motion";
import { useSwipeable, config } from "react-swipeable";
import UnableToLoadData from "../Components/UnableToLoadData";
import ImageTag from "../Components/ImageTag";
import ImageViewer from "../Components/ImageViewer";
import ImageSlider from "../Components/ImageSlider";
import {
  addItemCartDetail,
  updateItemCartDetail,
  removeItemCartDetail,
  getTableDetailByFilter,
  emptyEditedItemDetail,
  showNotificationWithTimeout,
} from "../../Redux/actions";
import { squreImgLgPngIcon } from "../../Assets";
import {
  getModifierActionName,
  getModifierTypeName,
  getModifierActionSign,
  checkNegNum,
  makeNegToPosNum,
  checkItemExistInCart,
  groupByArray,
  getRandomString,
  imageExists,
  getUniTimeString,
  checkExpiredSession,
  checkInactiveSession,
  checkArray,
  checkNull,
  checkIsNum,
} from "../../helpers";
import moment from "moment";
import ReactTooltip from "react-tooltip";

function GetItemsDetails({
  loading,
  selected_item_detail,
  edited_item_detail,
  table_detail,
  filters,
  filter_tag_detail,
  cart_detail,
  show_complete_page,
  outletID,
  addItemCartDetail,
  updateItemCartDetail,
  getTableDetailByFilter,
//   emptyEditedItemDetail,
  removeItemCartDetail,
  recent_order_detail,
  show_session_expired_page,
  pos_cart_detail,
  showNotificationWithTimeout,
  is_payment_cleared,
  server_detail,
  server_user_detail,
}) {
  const history = useHistory();
  const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";
  const currDateTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format("yyyy/MM/DD HH:mm:ss");
  const showPostTaxPrice =
    table_detail?.Merchant?.MerchantFinanceInfo?.menu_pricing_default == "2"
      ? true
      : false;
  const isContainDiscountArr =
    selected_item_detail?.PosMenuitemsModifier &&
    selected_item_detail?.PosMenuitemsModifier.findIndex(
      (p) => p.action == "discount"
    ) > -1
      ? true
      : false;
  const serviceChargePer = parseFloat(table_detail?.ServiceCharges ?? 0);
  const salesTaxPer = parseFloat(table_detail?.SalesTaxRate ?? 0);
  const showNoteCon =
    table_detail?.QrOrdering?.hide_note !== "yes" ||
    server_detail?.CurrentRoom?.merchant_id
      ? true
      : false;

  const [wrapperClass, setWrapperClass] = useState("");
  const cartItemKey =
    cart_detail && cart_detail.length > 0 && selected_item_detail?.id
      ? cart_detail.findIndex((p) => p.id == selected_item_detail.id)
      : -1;
  const editedItemSelectedKey = edited_item_detail?.modifierArray?.findIndex(
    (p) => p.action == "discount"
  );
  const defaultSelectedItemPrice =
    editedItemSelectedKey !== undefined && editedItemSelectedKey > -1
      ? parseFloat(
          edited_item_detail?.modifierArray?.[editedItemSelectedKey]
            ?.modifier_price
        )
      : parseFloat(
          selected_item_detail?.dinein_price?.DineIn
            ? selected_item_detail?.dinein_price?.DineIn
            : 0
        );
  const [currentItemQytEdit, setCurrentItemQytEdit] = useState(false);
  const [currentItemQyt, setCurrentItemQyt] = useState(
    edited_item_detail?.quantity != undefined ||
      edited_item_detail?.quantity > 0
      ? edited_item_detail?.quantity
      : 1
  );
  const currentItemQytRef = useRef(currentItemQyt);
  currentItemQytRef.current = currentItemQyt;

  const [selectedItemPrice, setSelectedItemPrice] = useState(
    defaultSelectedItemPrice
  );
  const [totalPrice, setTotalPrice] = useState(parseFloat(selectedItemPrice));
  const [totalPriceWithTax, setTotalPriceWithTax] = useState(
    parseFloat(selectedItemPrice)
  );
  const [hideHeader, setHideHeader] = useState(false);
  const [hideImages, setHideImages] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [showImageView, setShowImageView] = useState(false);
  const [modifierChanged, setModifierChanged] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [modifierArray, setModifierArray] = useState([]);
  const [imageArr, setImageArr] = useState([]);
  const imageArrRef = useRef(imageArr);
  imageArrRef.current = imageArr;

  const qytEditInputRef = useRef(null);

  useEffect(() => {
    if (currentItemQytEdit) {
      qytEditInputRef?.current?.focus();
    }
  }, [currentItemQytEdit]);

  useEffect(() => {
    if (show_complete_page) {
      history.push("/completed");
    }
  }, [show_complete_page]);

  const showBackWhite =
    (selected_item_detail?.StandardModifier &&
      selected_item_detail?.StandardModifier.length > 0) ||
    (selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier.length > 0) ||
    (selected_item_detail?.StandardModifier &&
      selected_item_detail?.StandardModifier.length > 0)
      ? false
      : true;

  const getPostSaleTaxPrice = (val) => {
    let res = parseFloat(checkIsNum(val, 0));
    if (selected_item_detail?.dinein_price?.sales_tax == true) {
      const resTax = (res * salesTaxPer) / 100;
      res = res + resTax;
    }
    return res;
  };

  const getPreModifiers = () => {
    let res = [];
    if (
      edited_item_detail?.modifierArray != null ||
      edited_item_detail?.modifierArray?.length > 0
    ) {
      res = edited_item_detail?.modifierArray;
    } else if (
      selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier?.length > 0 &&
      selected_item_detail?.recomItem?.modifier_ids &&
      selected_item_detail?.recomItem?.modifier_ids?.length > 0
    ) {
      selected_item_detail?.recomItem?.modifier_ids?.map((modItemId) => {
        const modIndex = selected_item_detail?.PosMenuitemsModifier?.findIndex(
          (p) => p.id == modItemId
        );
        if (modIndex > -1) {
          const modItem =
            selected_item_detail?.PosMenuitemsModifier?.[modIndex];
          const modifierType = modItem?.type ?? "";
          const modifierAction = modItem?.action ?? "";
          const modifierName = getModifierTypeName(modifierType, modItem);
          const unitPrice =
            modItem?.unit_price &&
            modItem?.unit_price !== null &&
            modItem?.unit_price !== ""
              ? parseFloat(modItem?.unit_price)
              : 0;
          res.push({
            id: modItem?.id,
            modifier_id: modItem?.id,
            modifier_group_id: modItem?.modifier_group_id,
            action: modifierAction,
            name: modifierName,
            unit_price: parseFloat(unitPrice),
            modifier_price: parseFloat(unitPrice),
            modifier_quantity: 1,
          });
        }
      });
    }
    if (res.length > 0) {
      setModifierArray(res);
      setModifierChanged(!modifierChanged);
    }
    return res;
  };

  function getImages(images) {
    let arr = images?.map(async (img) => {
      if (img?.file_name) {
        let a = await imageExists(img?.file_name);
        return a;
      }
    });
    Promise.all(arr)
      .then((resp) => {
        if (resp && resp?.length > 0) {
          setImageArr(resp);
        } else {
          setHideImages(true);
          setHideHeader(true);
          setWrapperClass("scroll-active");
        }
      })
      .catch((er) => {
        setHideImages(true);
        setHideHeader(true);
        setWrapperClass("scroll-active");
      });
  }

  const [comment, setComment] = useState(edited_item_detail?.comment ?? "");
 

  function calTotalPrice() {
    const currentQyt = currentItemQytRef.current;
    const modifierAddPrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "add" || item.action == "swap")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierRemovePrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "remove")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const modifierSizePrice =
      modifierArray.length > 0
        ? modifierArray
            .filter((item) => item.action == "size")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
    const priceWithModifier =
      selectedItemPrice * currentQyt +
      modifierAddPrice * currentQyt -
      modifierRemovePrice * currentQyt +
      modifierSizePrice * currentQyt;
    setTotalPrice(parseFloat(priceWithModifier.toFixed(2)));
    setTotalPriceWithTax(getPostSaleTaxPrice(priceWithModifier));
  }

  const calculateIsToolBarStatus = () => {
    let res = false;
    let maxHeight = window.outerHeight - window.innerHeight;
    if (maxHeight > 120) {
      res = true;
    }
    return res;
  };

  const [totalHeight, setTotalHeight] = useState(window.innerHeight);
  const totalHeightRef = useRef(totalHeight);
  totalHeightRef.current = totalHeight;
  const [isToolBarHide, setIsToolBarHide] = useState(
    calculateIsToolBarStatus()
  );

  useEffect(() => {
    setIsToolBarHide(calculateIsToolBarStatus());
  }, [totalHeight]);

  useEffect(() => {
    getImages(
      selected_item_detail?.content?.length > 0
        ? selected_item_detail?.content
        : selected_item_detail?.image
        ? [{ file_name: selected_item_detail?.image }]
        : []
    );
    getPreModifiers();

    /* check expired session */
    // const checkExpired = checkExpiredSession(table_detail);
    // const checkInactive =
    //   recent_order_detail === null && !server_detail
    //     ? checkInactiveSession()
    //     : false;
    // if (checkExpired || checkInactive) {
    //   if (server_detail?.CurrentRoom) {
    //     history.push(
    //       `/get_server_details/${server_detail.CurrentRoom.merchant_id}/${server_detail.CurrentRoom.server_code}`
    //     );
    //   } else {
    //     history.push("/session-expired");
    //   }
    // }
    // /* check expired session END */

    // window.addEventListener("resize", function (event) {
    //   setTotalHeight(window.innerHeight);
    // });

    // return () => {
    //   emptyEditedItemDetail();
    //   window.removeEventListener("resize", (e) => {});
    // };
  }, []);

//   useEffect(() => {
//     if (show_session_expired_page) {
//       if (server_detail?.CurrentRoom) {
//         history.push(
//           `/get_server_details/${server_detail.CurrentRoom.merchant_id}/${server_detail.CurrentRoom.server_code}`
//         );
//       } else {
//         history.push("/session-expired");
//       }
//     }
//   }, [show_session_expired_page]);

//   useEffect(() => {
//     calTotalPrice();
//   }, [selectedItemPrice, modifierChanged]);

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (
        hideHeader == true &&
        eventData.dir == "Down" &&
        hideImages == false &&
        isHeaderAllUp === true
      ) {
        setWrapperClass("");
        setHideHeader(false);
      }
      if (hideHeader == false && eventData.dir == "Up") {
        setWrapperClass("scroll-active");
        setHideHeader(true);
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (headerPageOffset === 0 && hideHeader === true) {
      setIsHeaderAllUp(true);
    }
    if (headerPageOffset > 0 && isHeaderAllUp === true) {
      setIsHeaderAllUp(false);
    }
  }

  const editItemQyt = (quantity) => {
    setCurrentItemQyt(quantity);
    setModifierChanged(!modifierChanged);
  };

  const increaseItem = () => {
    const currentQyt = currentItemQyt + 1;
    setCurrentItemQyt(currentQyt);
    setModifierChanged(!modifierChanged);
  };

  const decreaseItem = () => {
    const currentQyt = currentItemQyt > 1 ? currentItemQyt - 1 : currentItemQyt;
    setCurrentItemQyt(currentQyt);
    setModifierChanged(!modifierChanged);
  };

  function getItemModifiersItems(modifers, action) {
    let res = null;
    if (modifers?.length > 0) {
      res = modifers
        .sort(function (a, b) {
          return parseInt(a?.is_sort ?? 0) - parseInt(b?.is_sort ?? 0);
        })
        .filter(
          (p) =>
            p.action == "size" ||
            p.action == "add" ||
            p.action == "remove" ||
            p.action == "swap"
        )
        // .filter((p) =>
        //   action === "size"
        //     ? p.action == "size"
        //     : p.action == "add" || p.action == "remove" || p.action == "swap"
        // )
        .map((element, index) => {
          const modifierType = element?.type ?? "";
          const modifierAction = element?.action ?? "";
          if (
            modifierType == "ingredient" ||
            modifierType == "recipe" ||
            modifierAction == "add" ||
            modifierAction == "remove" ||
            modifierAction == "swap" ||
            modifierAction == "size"
          ) {
            const modifierName = getModifierTypeName(modifierType, element);
            const elUnitPrice = showPostTaxPrice
              ? getPostSaleTaxPrice(element?.unit_price)
              : element?.unit_price;
            return (
              <div  key={index}>
                {/* <input
                  id={`modifier_${element?.id}`}
                  type="checkbox"
                  className="check"
                  checked={
                    modifierArray.findIndex((p) => p.id == element?.id) > -1
                  }
                  onChange={(el) => {
                    if (el?.target?.checked) {
                      const unitPrice =
                        element?.unit_price &&
                        element?.unit_price !== null &&
                        element?.unit_price !== ""
                          ? parseFloat(element?.unit_price)
                          : 0;

                      const elArr = {
                        id: element?.id,
                        modifier_id: element?.id,
                        modifier_group_id: element?.modifier_group_id,
                        action: modifierAction,
                        name: modifierName,
                        unit_price: parseFloat(unitPrice),
                        modifier_price: parseFloat(unitPrice),
                        modifier_quantity: 1,
                      };
                      const existIndex = modifierArray.findIndex(
                        (p) => p.action == modifierAction
                      );
                      if (existIndex > -1 && modifierAction == "size") {
                        setModifierArray([
                          ...modifierArray.slice(0, existIndex),
                          elArr,
                          ...modifierArray.slice(existIndex + 1),
                        ]);
                      } else {
                        setModifierArray([...modifierArray, elArr]);
                      }
                      setModifierChanged(!modifierChanged);
                    } else if (
                      modifierArray.findIndex((p) => p.id == element?.id) > -1
                    ) {
                      setModifierArray(
                        modifierArray.filter(
                          (modifier) => modifier.id != element?.id
                        )
                      );
                      setModifierChanged(!modifierChanged);
                    }
                  }}
                /> */}
                <label htmlFor={`modifier_${element?.id}`}>
                  {" "}
                  {getModifierActionName(modifierAction)}{" "}
                  {modifierAction === "remove" ? "" : ""}
                  {getModifierTypeName(modifierType, element)}
                </label>

                {elUnitPrice !== null &&
                  elUnitPrice !== "" &&
                  (elUnitPrice > 0 || element?.action == "size") && (
                    <span className="price-sm">
                      {" "}
                      {/* size action todo */}
                      {getModifierActionSign(modifierAction)}{" "}
                      {checkNegNum(elUnitPrice) ? "- " : ""}
                      {`${table_detail?.currency ?? ""}`}
                      {parseFloat(
                        checkNegNum(elUnitPrice)
                          ? makeNegToPosNum(elUnitPrice)
                          : elUnitPrice
                      ).toFixed(2)}
                    </span>
                  )}
              </div>
            );
          }
        });
    }
    return res;
  }

  function getItemModifiers(action = "") {
    let res = null;
    if (
      selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier.length > 0
    ) {
      const groupedModifiers = groupByArray(
        selected_item_detail?.PosMenuitemsModifier.filter(
          (p) =>
            p.action == "size" ||
            p.action == "add" ||
            p.action == "remove" ||
            p.action == "swap"
        ),
        // selected_item_detail?.PosMenuitemsModifier.filter((p) =>
        //   action === "size"
        //     ? p.action == "size"
        //     : p.action == "add" || p.action == "remove" || p.action == "swap"
        // ),
        "modifier_group_id"
      );
      const groupedModifiersArr = Object.values(groupedModifiers);
      if (groupedModifiersArr) {
        res = groupedModifiersArr.map((group) => {
          if (group.length > 0)
            return (
              <>
                <h5 className="sm-title">
                  {group?.[0]?.["modifier_group_name"]}
                </h5>
                <div className="selectors">
                  {getItemModifiersItems(group, action)}
                </div>
              </>
            );
        });
      }
    }
    return res;
  }

  function getItemDiscountModifiers() {
    let res = null;
    if (
      selected_item_detail?.PosMenuitemsModifier &&
      selected_item_detail?.PosMenuitemsModifier.length > 0
    ) {
      const discountModifiersArr =
        selected_item_detail?.PosMenuitemsModifier.filter(
          (p) => p.action == "discount"
        ).map((element, index) => {
          const modifierType = element?.type ?? "";
          const modifierAction = element?.action ?? "";
          const modifierName = getModifierTypeName(modifierType, element);
          const unitPrice =
            element?.unit_price &&
            element?.unit_price !== null &&
            element?.unit_price !== ""
              ? parseFloat(element?.unit_price)
              : 0;
          return (
            <li>
              {/* <input
                type="radio"
                name="select_options"
                id={`modifier_${element?.id}`}
                className="check"
                onClick={() => {
                  const discountEl = {
                    id: element?.id,
                    modifier_id: element?.id,
                    modifier_group_id: element?.modifier_group_id,
                    action: modifierAction,
                    name: modifierName,
                    unit_price: parseFloat(unitPrice),
                    modifier_price: parseFloat(unitPrice),
                    modifier_quantity: element?.amount_per_item,
                  };
                  const existIndex = modifierArray.findIndex(
                    (p) => p.action == "discount"
                  );
                  if (existIndex > -1) {
                    setModifierArray([
                      ...modifierArray.slice(0, existIndex),
                      discountEl,
                      ...modifierArray.slice(existIndex + 1),
                    ]);
                  } else {
                    setModifierArray([...modifierArray, discountEl]);
                  }
                  setSelectedItemPrice(parseFloat(unitPrice));
                }}
                checked={
                  modifierArray.findIndex((p) => p.id == element?.id) > -1
                }
              /> */}
              <label htmlFor={`modifier_${element?.id}`}>
                {element?.amount_per_item} x{" "}
                {selected_item_detail?.menu_item_name}
              </label>
              <span className="price">
                {`${table_detail?.currency ?? ""}`}
                {parseFloat(unitPrice).toFixed(2)}
              </span>
            </li>
          );
        });
      res = (
        <div >
          <ul>
            <li>
              {/* <input
                type="radio"
                name="select_options"
                id={`default_options`}
                className="check"
                onClick={() => {
                  setModifierArray(
                    modifierArray.filter(
                      (modifier) => modifier.action != "discount"
                    )
                  );
                  setSelectedItemPrice(defaultSelectedItemPrice);
                }}
                checked={
                  modifierArray.findIndex((p) => p.action == "discount") < 0
                }
              /> */}
              <label htmlFor="default_options">
                1 x {selected_item_detail?.menu_item_name}
              </label>
              <span className="price">
                {`${table_detail?.currency ?? ""}`}
                {parseFloat(defaultSelectedItemPrice).toFixed(2)}
              </span>
            </li>
            {discountModifiersArr}
          </ul>
        </div>
      );
    }
    return res;
  }

  function getItemStandardModifiers() {
    let res = null;
    if (
      selected_item_detail?.StandardModifier &&
      selected_item_detail?.StandardModifier.length > 0
    ) {
      res = selected_item_detail?.StandardModifier.map((stanEl, stanIndex) => {
        return (
          <>
            <h5 className="sm-title" key={stanIndex}>
              {stanEl?.ModifierClass?.name ?? ""}
            </h5>
            <div className="selectors">
              {stanEl?.ModifierClass?.Modifier?.map((element, index) => {
                const modifierId = element?.id ? `SM-${element?.id}` : "";
                const modifierType = element?.type ?? "";
                const modifierAction = stanEl?.ModifierClass?.name ?? "";
                const modifierName = element?.name ?? "";
                return (
                  <div  key={index}>
                    {/* <input
                      id={`standard_modifier_${modifierId}`}
                      type="checkbox"
                      className="check"
                      checked={
                        modifierArray.findIndex((p) => p.id == modifierId) > -1
                      }
                      onChange={(el) => {
                        if (el?.target?.checked) {
                          const addEl = {
                            id: modifierId,
                            modifier_id: modifierId,
                            action: modifierAction,
                            name: modifierName,
                            unit_price: 0,
                            modifier_price: 0,
                            modifier_quantity: 1,
                          };
                          const existIndex = modifierArray.findIndex(
                            (p) => p.action == modifierAction
                          );
                          if (existIndex > -1) {
                            setModifierArray([
                              ...modifierArray.slice(0, existIndex),
                              addEl,
                              ...modifierArray.slice(existIndex + 1),
                            ]);
                          } else {
                            setModifierArray([...modifierArray, addEl]);
                          }
                        } else if (
                          modifierArray.findIndex((p) => p.id == modifierId) >
                          -1
                        ) {
                          setModifierArray(
                            modifierArray.filter(
                              (modifier) => modifier.id != modifierId
                            )
                          );
                        }
                      }}
                    /> */}
                    <label htmlFor={`standard_modifier_${modifierId}`}>
                      {" "}
                      {getModifierActionName(modifierAction)}{" "}
                      {modifierName}
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        );
      });
    }
    return res;
  }

  function getItemTags() {
    let res = null;
    if (
      selected_item_detail?.is_popular &&
      selected_item_detail?.is_popular === 1
    ) {
      res = <span className="tags">Popular</span>;
    } else if (
      selected_item_detail?.is_trending &&
      selected_item_detail?.is_trending === 1
    ) {
      res = <span className="tags blue">Trending</span>;
    }
    return res;
  }

  function getItemFilterTags(item) {
    let res = null;
    if (item?.allergy_tags && item?.allergy_tags.length > 0) {
      res = (
        <ul>
          {item?.allergy_tags.map(function (tag, index) {
            const filterTagKey =
              filter_tag_detail && filter_tag_detail.length > 0
                ? filter_tag_detail.findIndex((p) => p.id == tag)
                : null;
            const filterTag =
              filterTagKey !== null && filterTagKey !== undefined
                ? filter_tag_detail[filterTagKey]
                : null;
            const style =
              filterTag?.color && filterTag?.color !== ""
                ? { background: filterTag?.color ?? "" }
                : {};
            const className =
              filterTag?.color && filterTag?.color !== "" ? "" : "gray";
            const fullFilterName =
              filterTag?.name && filterTag?.name !== "" ? filterTag?.name : "";

            return (
              <li
                style={style}
                className={className}
                key={index}
                data-tip={`${fullFilterName}`}
              >
                {filterTag?.symbol ?? ""}
              </li>
            );
          })}
        </ul>
      );
    }
    return res;
  }


  function getAddItemDetail() {
    if (  table_detail?.Merchant) {
      return (
        <div
          className={`wrapper ${wrapperClass} ${
            isToolBarHide ? "" : "toolbar-vh"
          }`}
        >
          <ReactTooltip effect="solid" />
          <section className="add-items_sec ">
            <Motion
              defaultStyle={{ x: 270 }}
              style={{
                x: spring(hideHeader === false ? 270 : 0, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <div
                  className="image-container-sec"
                  style={{
                    height: `${x}px`,
                  }}
                >
                  <div
                    onClick={() =>
                     
                      history.push(
                        `/qr-menu/${outletID}`
                      )
                    }
                    className="cross-btn"
                  >
                    {" "}
                  </div>
                  <div className="image-sec">
                    <ImageSlider
                      images={imageArr}
                      onOpen={(index) => {
                        setShowImageView(true);
                        setCurrentImageIndex(index);
                      }}
                      // isOpen={true}
                    />
                    {showImageView === true && (
                      <ImageViewer
                        images={imageArr}
                        isOpen={showImageView}
                        currentIndex={currentImageIndex}
                        onClose={() => setShowImageView(false)}
                      />
                    )}
                    {/* <figure>
                      {" "}
                      <ImageTag
                        src={selected_item_detail?.image}
                        defaultimage={table_detail?.Merchant?.logo}
                      />
                    </figure> */}
                    {getItemTags()}
                  </div>
                </div>
              )}
            </Motion>
            <Motion
              defaultStyle={{ x: 0 }}
              style={{
                x: spring(hideHeader === false ? 0 : 100, {
                  precision: 1,
                  stiffness: 300,
                  damping: 44,
                }),
              }}
            >
              {({ x }) => (
                <div
                  className={`foobar-header row mx-0 ${
                    hideHeader === true ? "touch-top" : ""
                  }`}
                  style={{
                    height: `${x}%`,
                  }}
                >
                  <div
                    onClick={() =>
                        history.push(
                            `/qr-menu/${outletID}`
                          )
                    }
                    className="cross-btn"
                  >
                    {" "}
                  </div>
                  <h5 className="name col-9">
                    {selected_item_detail?.menu_item_name}
                  </h5>
                  <span className="price col-3 px-0 text-right h5">
                    {" "}
                    {!isContainDiscountArr && (
                      <>
                        <small className="currency">{`${
                          table_detail?.currency ?? ""
                        }`}</small>
                        {`${
                          showPostTaxPrice
                            ? selected_item_detail?.dinein_price?.DineInPostTax
                              ? parseFloat(
                                  selected_item_detail?.dinein_price
                                    ?.DineInPostTax
                                ).toFixed(2)
                              : "0.00"
                            : selected_item_detail?.dinein_price?.DineIn
                            ? parseFloat(
                                selected_item_detail?.dinein_price?.DineIn
                              ).toFixed(2)
                            : "0.00"
                        }`}
                      </>
                    )}
                  </span>
                  {/* <p className="description col-12">
                    {selected_item_detail?.description}
                  </p> */}
                </div>
              )}
            </Motion>
            <div
              className={`item-details${ " wht-bg" }${
                hideHeader === true ? " top-scroll" : ""
              }`}
              onScroll={scrolledElement}
              {...handlers}
            >
              <div className="col-12 product-details">
                {!hideHeader && (
                  <>
                    <h5 className="name col-9">
                      {" "}
                      {selected_item_detail?.menu_item_name}{" "}
                    </h5>
                    <span className="price col-3">
                      {" "}
                      {!isContainDiscountArr && (
                        <>
                          <small className="currency">{`${
                            table_detail?.currency ?? ""
                          }`}</small>
                          {`${
                            showPostTaxPrice
                              ? selected_item_detail?.dinein_price
                                  ?.DineInPostTax
                                ? parseFloat(
                                    selected_item_detail?.dinein_price
                                      ?.DineInPostTax
                                  ).toFixed(2)
                                : "0.00"
                              : selected_item_detail?.dinein_price?.DineIn
                              ? parseFloat(
                                  selected_item_detail?.dinein_price?.DineIn
                                ).toFixed(2)
                              : "0.00"
                          }`}
                        </>
                      )}
                    </span>
                  </>
                )}
                <p className={`description`}>
                  {selected_item_detail?.description}
                </p>
                <div className="labels">
                  {getItemFilterTags(selected_item_detail)}
                </div>
                {isContainDiscountArr && getItemDiscountModifiers()}
              </div>
              {((selected_item_detail?.PosMenuitemsModifier &&
                selected_item_detail?.PosMenuitemsModifier.length > 0) ||
                (selected_item_detail?.StandardModifier &&
                  selected_item_detail?.StandardModifier.length > 0)) && (
                <>
                  {/* <h5 className="sm-title"> Modifiers </h5> */}

                  {/* <div className="selectors"> */}
                  {/* {selected_item_detail?.PosMenuitemsModifier.findIndex(
                        (p) => p.action == "size"
                      ) > -1 && <>{getItemModifiers("size")}</>} */}
                  {getItemModifiers("")}
                  {getItemStandardModifiers()}
                  {/* </div> */}
                </>
              )}
              {showNoteCon && (
                <>
                  <h5 className="sm-title">Notes:</h5>
                  <div className="message_col mt-2">
                    <textarea
                      id={`comment_${selected_item_detail?.id}`}
                      name={`comment_${selected_item_detail?.id}`}
                      className={"form-control"}
                      placeholder="Add note here."
                      type="text"
                      onChange={(el) => {
                        setComment(el.target.value);
                      }}
                    >
                      {comment}
                    </textarea>
                  </div>
                </>
              )}
            </div>
          </section>

          {/* <section className="bottom-sec pt-0 center-btn">
            <div className="adding-items">
              <div className="items-sec">
                <button className="remove-btn" onClick={decreaseItem}>
                  {" "}
                </button>
                <input
                  ref={qytEditInputRef}
                  type="number"
                  step={1}
                  max={999}
                  pattern="[0-9]*"
                  inputmode="numeric"
                  onBlur={() => {
                    if (isNaN(currentItemQyt) || currentItemQyt <= 0) {
                      editItemQyt(1);
                    }
                    setCurrentItemQytEdit(false);
                  }}
                  onChange={(e) => {
                    const qytVal = parseInt(
                      String(e.target.value ?? 1).slice(0, 3)
                    );
                    editItemQyt(qytVal);
                  }}
                  className="items-num"
                  value={currentItemQyt}
                  style={{ display: currentItemQytEdit ? "block" : "none" }}
                />
                <input
                  type="text"
                  className="items-num"
                  value={currentItemQyt}
                  onClick={() => {
                    setCurrentItemQytEdit(true);
                  }}
                  readOnly
                  style={{ display: currentItemQytEdit ? "none" : "block" }}
                />

                <button className="add-btn" onClick={increaseItem}>
                  {" "}
                </button>
              </div>
              <div className="price-sec">
                <strong>
                  {" "}
                  <small> {`${table_detail?.currency ?? ""}`}</small>
                  {parseFloat(
                    showPostTaxPrice ? totalPriceWithTax : totalPrice
                  ).toFixed(2)}
                </strong>
                <p> item total </p>
              </div>
            </div>
            <button
              className="green-btn"
              onClick={() => {
                if (
                  checkArray(pos_cart_detail?.payment_type) &&
                  pos_cart_detail?.payment_type.findIndex(
                    (p) => p.isPaid == true
                  ) > -1
                ) {
                  showNotificationWithTimeout(
                    `Order has been split can not add items now`,
                    "error"
                  );
                } else if (!isNaN(currentItemQyt)) AddToCartHandler();
              }}
            >
              {" "}
              Add to Basket{" "}
            </button>
          </section> */}
        </div>
      );
    } else {
      return (
        <div className="wrapper error_container">
          <UnableToLoadData />
        </div>
      );
    }
  }

  return <>{getAddItemDetail()}</>;
}
const mapStateToProps = (state) => ({
  selected_item_detail: state.tableDetailReducer.selected_item_detail,
  edited_item_detail: state.tableDetailReducer.edited_item_detail,
  table_detail: state.tableDetailReducer.table_detail,
  filters: state.tableDetailReducer.filters,
  filter_tag_detail: state.tableDetailReducer.filter_tag_detail,
  cart_detail: state.cartDetailReducer.cart_detail,
  show_complete_page: state.cartDetailReducer.show_complete_page,
  pos_cart_detail: state.cartDetailReducer?.pos_cart_detail,
  loading: state.loaderReducer.loading,
  recent_order_detail: state.cartDetailReducer.recent_order_detail,
  show_session_expired_page: state.tableDetailReducer.show_session_expired_page,
  is_payment_cleared: state.paymentDetailReducer.is_payment_cleared,
  server_detail: state.tableDetailReducer.server_detail,
  server_user_detail: state.authDetailReducer.server_user_detail,
  outletID:state.authDetailReducer.outletID

});

const mapDispatchToProps = {
  addItemCartDetail: addItemCartDetail,
//   updateItemCartDetail: updateItemCartDetail,
//   removeItemCartDetail: removeItemCartDetail,
  getTableDetailByFilter: getTableDetailByFilter,
//   emptyEditedItemDetail: emptyEditedItemDetail,
  showNotificationWithTimeout: showNotificationWithTimeout,
};

GetItemsDetails = connect(mapStateToProps, mapDispatchToProps)(GetItemsDetails);
export default GetItemsDetails;
