import React from "react";
import { connect, useSelector, useDispatch } from "react-redux";

import { image2Icon, userImgIcon } from "../../Assets";
import OrderHistoryDetails from "../Components/OrderHistoryDetails";

function LoyalityHistory(params) {
  const orderDetail = useSelector(
    (state) => state?.cartDetailReducer?.order_history_detail
  );
  const table_detail = useSelector(
    (state) => state.tableDetailReducer?.table_detail
  );
  const recordNotFound = (
    <div className="history_details text-center">
      <p>no records found</p>
    </div>
  );
  return (
    <section className="info-sec">
      <div className="history-info" id="accordionExample">
        <h4 className="member-info-title Px-15 bg-white paddingLoyality pt-5"> Transaction History </h4>
        {orderDetail &&
          orderDetail?.length > 0 &&
          orderDetail.map((order, index) => {
            return <OrderHistoryDetails order={order} table_detail={table_detail} key={index} />;
          })}
        {(orderDetail == undefined ||
          orderDetail == null ||
          orderDetail?.length === 0) &&
          recordNotFound}
      </div>
    </section>
  );
}
export default LoyalityHistory;
