import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { serverDetailsLogo } from "../../Assets";
import {
  checkArray,
  checkNull,
  getRecentOrderDate,
  parseRound,
  shortArrayTable,
} from "../../helpers";
import {
  combineOrderStatus,
  getComoCustomer,
  getServerDetail,
  getServerOrderId,
  redirectTable,
  setOrderData,
  setVoidEmptyMenuItem,
  showNotificationWithTimeout,
} from "../../Redux/actions";
import AccountPopup from "../Components/AccountPopup";
import CustomPopup from "../Components/CustomPopUp";
// import CusttomPopup from "../Components/CustomPopUp";
import ImageTag from "../Components/ImageTag";
import LoginInput from "../Components/LoginInput";
import SearchableSelect from "../Components/SearchableSelect";

const TableViewer = ({ style }) => {
  const { server_detail } = useSelector((state) => state.tableDetailReducer);
  const { server_user_detail, merchantID, serverId } = useSelector(
    (state) => state.authDetailReducer
  );
  const { order_id, order_offline_id } = useSelector(
    (state) => state.tableDetailReducer
  );

  const [openDropDownID, setOpenDropDownID] = useState(0);
  const [selectTicket, setSelectTicket] = useState([]);

  const [id, setId] = useState("");
  const [index, setIndex] = useState(0);

  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    const rootEl = document.getElementById("root");
    dispatch(redirectTable(false));

    if (rootEl) {
      rootEl.classList.add("scroll-table");
    }
    const delay = 1000;
    const timer = setTimeout(() => {
      dispatch(
        getServerDetail({
          merchant_id: merchantID,
          server_code: serverId,
        })
      );
      // const serverData ={
      //   merchantId: merchantID,
      //   serverId: serverId,

      // }
      // localStorage.setItem("serverData", JSON.stringify(serverData));
    }, delay);
    return () => {
      clearTimeout(timer);
      if (rootEl) {
        rootEl.classList.remove("scroll-table");
      }
    };
  }, []);

  const [selectedRoom, setSelectedRoom] = useState({
    value: server_detail?.CurrentRoom?.id,
    label: server_detail?.CurrentRoom?.name,
  });

  const [selectedTable, setSelectedTable] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [tableOrder, setTableOrder] = useState([]);

  const getRoomsOptions = useCallback(() => {
    let res = [];
    if (server_detail?.PosRoom && checkArray(server_detail?.PosRoom)) {
      res = server_detail?.PosRoom.map((room) => ({
        value: room?.id,
        label: room?.name,
      }));
    }
    return res;
  }, [server_detail]);

  const getTablesOptions = useCallback(
    (isButton = false) => {
      let res = isButton ? null : [];
      if (
        checkNull(selectedRoom?.value, false) &&
        server_detail?.PosRoom &&
        checkArray(server_detail?.PosRoom) &&
        server_detail?.PosRoom.findIndex((p) => p?.id == selectedRoom?.value) >
          -1
      ) {
        const selectRoomInx = server_detail?.PosRoom.findIndex(
          (p) => p?.id == selectedRoom?.value
        );
        const selectRoomObj = server_detail?.PosRoom?.[selectRoomInx];
        if (selectRoomObj && checkArray(selectRoomObj?.PosTableMaster)) {
          selectRoomObj?.PosTableMaster.sort((a, b) => {
            const aRecentOrderDate = getRecentOrderDate(a?.OpenOrders);
            const bRecentOrderDate = getRecentOrderDate(b?.OpenOrders);
            return new Date(bRecentOrderDate) - new Date(aRecentOrderDate);
          });

          res = selectRoomObj?.PosTableMaster.map((table) => {
            const isActive = table.OpenOrders.length >= 1 ? true : false;
            // table.OpenOrders || checkArray(table.OpenOrders)
            //   ? table.OpenOrders.findIndex(
            //       (p) =>
            //         p?.pos_order_data?.employee_id == server_user_detail?.id
            //     ) > -1
            //   : false;
            return isButton ? (
              <button
                className={`round-button shadow-none${
                  table?.id == selectedTable?.value || isActive
                    ? isActive
                      ? " filled-green"
                      : " active"
                    : ""
                }`}
                onClick={() => {
                  if (table?.id == selectedTable?.value || isActive) {
                   
                    if (table.OpenOrders.length >= 1) {
                      setShowModal(true);
                      setTableOrder(table.OpenOrders);
                      setSelectedTable({
                        value: table?.id,
                        label: table?.table_number,
                      });
                    } else {
                      setSelectedTable({
                        value: table?.id,
                        label: table?.table_number,
                      });
                    }
                  } else {
                    setSelectedTable({
                      value: table?.id,
                      label: table?.table_number,
                    });
                  }
                }}
              >
                {table?.table_number}
              </button>
            ) : (
              {
                value: table?.id,
                label: table?.table_number,
              }
            );
          });
        }
      }
      return res;
    },
    [server_detail, selectedRoom, selectedTable]
  );

  const tableSubmitHandler = (orderId = null, type = 1) => {
    dispatch(redirectTable(false));

    // el.preventDefault();
    if (selectedRoom?.value) {
      const selectRoomInx = server_detail?.PosRoom.findIndex(
        (p) => p?.id == selectedRoom?.value
      );
      const selectRoomObj = server_detail?.PosRoom?.[selectRoomInx];

      if (
        selectRoomObj &&
        checkArray(selectRoomObj?.PosTableMaster) &&
        selectedTable?.value
      ) {
        const selectTableInx = selectRoomObj?.PosTableMaster.findIndex(
          (p) => p?.id == selectedTable?.value
        );
        const selectTableObj = selectRoomObj?.PosTableMaster?.[selectTableInx];

        if (selectTableObj && selectTableObj?.qr_code_id) {
          if (checkArray(selectTableObj?.OpenOrders) && type == 3) {
            const payload = {
              orderId: orderId ? orderId : selectTableObj?.OpenOrders?.[0]?.id,
              customerId: 0,
              tableNumber: selectTableObj?.table_number,
              merchantId: server_detail?.Merchant?.id,
            };
            const merchantId = server_detail?.Merchant?.id;
            const pos_order_data =
              selectTableObj?.OpenOrders?.[index]?.pos_order_data;

            const phone =
              pos_order_data?.loyalty_login_type == "mobile"
                ? pos_order_data?.loyaltyCardNumber
                : "";
            const email =
              selectTableObj?.OpenOrders?.[index]?.pos_order_data
                ?.loyalty_login_type == "email"
                ? ""
                : "";
            //  dispatch(getComoCustomer({ merchantId, phone, email, appClientId: "otp" }));

            const orderObject = {
              order_id: pos_order_data?.order_id ?? "",
              order_offline_id: pos_order_data?.order_offline_id ?? "",
            };
            dispatch(setOrderData(orderObject));
            dispatch(combineOrderStatus(true));

            return dispatch(
              getServerOrderId(payload, (data) => {
                if (data?.status) {
                 
                  return history.push(
                    `/get_table_details/server/${selectTableObj?.qr_code_id}`
                  );
                }
              })
            );
          } else {

            return history.push(
              `/get_table_details/server/${selectTableObj?.qr_code_id}`
            );
          }
        }
        return dispatch(
          showNotificationWithTimeout("Invalid table selected!", "error")
        );
      }
      return dispatch(
        showNotificationWithTimeout("Please select table!", "error")
      );
    }
    return dispatch(
      showNotificationWithTimeout(" Please select room!", "error")
    );
  };
  const checkAmount = (value) => {
    const ammount = checkArray(value?.pos_order_data?.menu_item)
      ? value?.pos_order_data?.menu_item.reduce(
          (partialSum, billMenu) =>
            partialSum +
            parseFloat(billMenu?.doubleActualAmout) *
              parseFloat(billMenu?.menu_item_quantity),
          0
        )
      : 0;
    return parseRound(ammount);
  };

  const getSelectedOpenOrder = () => {
  };

  const getTotalAmount = (data) => {
    var res = 0;
    data?.menu_item.map((val) => {
      res =
        res +
        (val?.menu_item_price - val?.promotionTotaldiscount) *
          val?.menu_item_quantity;
    });
    return res;
  };

  return (
    <div className="wrapper cart_sidebar" style={{ background: "#f5f6f6" }}>
      {/* <div className="overlay"> </div> */}
      <section
        className="varification-sec default middleClass new-table-screen container-flex"
        style={{ background: "#f5f6f6", paddingTop: "0px" }}
      >
        {/* <a href="#_" className="cross-icon"></a> */}
        <div className="flex-top">
          <div className="text-right">
            <ImageTag
              src={serverDetailsLogo}
              alt=""
              style={{
                width: "85px",
                paddingRight: "15px",
                paddingTop: "12px",
                transform: "translateY(15px)",
              }}
            />
          </div>
          <div className="container row pb-3">
            <div className="circle-img default col-3">
              <figure className="">
                <ImageTag src={server_detail?.Merchant?.logo} alt="" />
              </figure>
            </div>

            <div className="circle-img default col-9">
              <h3 className="boat-text">
                {server_detail?.Merchant?.account_name}
              </h3>
            </div>
          </div>
        </div>

        <div className="login-form pt-4  px_15 new-table-form">
          {/* <div className="group-sec mt-2">
            <div className="title-heading bold text-green">Take Order</div>
          </div> */}

          <div className="container-fluid mb-5">
            <div className="row">
              <div className="col-6 pl-0  pr-table">
                <div className="pin-text mb-3">Room</div>
                <SearchableSelect
                  selectedOption={selectedRoom}
                  handleSelectChange={(value) => {
                    setSelectedRoom(value);
                    setSelectedTable(null);
                  }}
                  options={getRoomsOptions()}
                  placeholder="Select a room"
                  color={ server_detail?.QrOrdering?.brand_main_color}
                />
              </div>
              <div className="col-6 pr-0 pl-table">
                <div className="pin-text mb-3">All Tables</div>
                <SearchableSelect
                  className="table-selectBtn"
                  selectedOption={selectedTable}
                  handleSelectChange={(value) => {
                    setSelectedTable(value);
                  }}
                  options={getTablesOptions()}
                  placeholder="Select a table"
                  classNamePrefix="table-PrefixControl"
                  color={ server_detail?.QrOrdering?.brand_main_color}

                />
              </div>
            </div>
          </div>

          <div className="container-fluid" key={server_detail}>
            <div className="row">
              {/* <div className="col-12 px-0">
                <div className="pin-text mb-3">Seated tables</div>
              </div> */}
              <div className="d-flex pin-sec">{getTablesOptions(true)}</div>
            </div>
          </div>
        </div>
        <form
          className="login-form fixedBottom new-log-form"
          onSubmit={(e) => {
            e.preventDefault();
            tableSubmitHandler(id, 1);
          }}
        >
          <div className="">
            <div className="resend_btn_row">
              <button type="submit" className="btn green-btn new-green">
                Submit
              </button>
            </div>
          </div>
        </form>
      </section>
      {/* <AccountPopup
              show={true}
              // tabType={accountTabType}
              // onCancel={() => {
              //   setShowAccountPopUp(0);
              //   setAccountTabType(0);
              // }}
            /> */}
      (
      <CustomPopup
        show={showModal}
        onCancel={() => {
          setShowModal(false);
          setSelectTicket([]);
        }}
      >
        <div className="flex-top">
          <div className="text-right">
            <ImageTag
              src={serverDetailsLogo}
              alt=""
              style={{
                width: "85px",
                paddingRight: "15px",
                paddingTop: "12px",
                transform: "translateY(15px)",
              }}
            />
          </div>
          <div className="new-table-screen OrderHeadsPadding  container row pb-3">
            <div className="circle-img default col-3">
              <figure className="">
                <ImageTag src={server_detail?.Merchant?.logo} alt="" />
              </figure>
            </div>

            <div className="new-log-screen circle-img default col-9">
              <h3 className="boat-text">
                {server_detail?.Merchant?.account_name}
              </h3>
              <p>Table {tableOrder[0]?.table_no} Ticket List</p>
            </div>
          </div>
        </div>
        {/* <h3 className="h3-popup">Table No - {tableOrder[0]?.table_no}</h3> */}
        <div className="OrderHeads">
          <h3 class="title"> Pick Your Ticket </h3>
          <p> Pick ticket would you like to go in ? </p>
        </div>
        {/* <h3 className="h3-popup">Table No - {tableOrder[0]?.table_no}</h3>
        <h3 className="h3-popups">
          No of Seats - {tableOrder[0]?.no_of_seats}
        </h3> */}

        {tableOrder.map((value, index) => {
          return (
            <div class="accordion accorDion" id="accordionExample">
              <div
                class={
                  selectTicket.includes(value.id)
                    ? `accordion-item`
                    : `accorDion accordion-item default`
                }
                onClick={() => {
                  // setOpenDropDown(!openDropDown)
                  dispatch(setVoidEmptyMenuItem(value?.pos_order_data));
                  const index = selectTicket.findIndex((id) => id === value.id);
                  setId(value.id);

                  if (index !== -1) {
                    setIndex(index);
                    const updatedTicket = [...selectTicket];
                    updatedTicket.splice(index, 1);
                    setSelectTicket(updatedTicket);
                  } else {
                    const updatedTicket = [...selectTicket, value.id];
                    setSelectTicket(updatedTicket);
                  }
                }}
              >
                <div
                  id="headingOne"
                  className="accordion-button accordion-header"
                  // type="button"
                  data-toggle="collapse"
                  data-target={`#collapse${value.id}`}
                  aria-expanded={value?.id == openDropDownID ? "true" : "false"}
                  aria-controls="collapseOne"
                >
                  <p># {value?.order_id}</p>
                  <div className="d-flex align-items-center">
                    <p className="">
                      ${" "}
                      {
                        // checkAmount(value)
                        // parseRound(value?.pos_order_data?.finalTotalAmt)- parseRound(value?.pos_order_data?.discount)
                        parseRound(getTotalAmount(value?.pos_order_data))
                      }
                    </p>
                    <span
                      className={`accordion-buttonSpan d-flex  ${
                        value?.id == openDropDownID && "accordion-buttonSpans"
                      } `}
                      // style={value?.id==openDropDownID?}
                      aria-expanded={
                        value?.id == openDropDownID ? "false" : "true"
                      }
                      onClick={() =>
                        value?.id == openDropDownID
                          ? setOpenDropDownID(0)
                          : setOpenDropDownID(value?.id)
                      }
                    />
                    {/* <i
                  onClick={()=>  value?.id==openDropDownID?setOpenDropDownID(0):setOpenDropDownID(value?.id)}
                    class={`fa fa-chevron-up ac-accordion-icon${
                      openDropDownID == value?.id ? "transform: rotate(-180deg);" : "transform: rotate(180deg);"
                    }`}
                    // className={"fa fa-chevron-up ac-accordion-icon"}
                    // style={`${openDropDownID == value?.id} ? "transform: rotate(-180deg);" : "transform: rotate(180deg)`}
                  /> */}
                  </div>
                </div>
                <div
                  id={`#collapse${value.id}`}
                  class={
                    openDropDownID == value?.id
                      ? "accordion-collapse collapse show"
                      : "accordion-collapse collapse "
                  }
                  aria-labelledby="headingOne"
                >
                  <div class="accordion-body">
                    {value?.pos_order_data?.menu_item.map((data, index) => {
                      return (
                        <div
                          className="newOrderPopup d-flex justify-content-between"
                          style={{ display: "block !important", margin: 0 }}
                        >
                          <p
                            className="dropDown-Text"
                            style={{ display: "inline" }}
                          >
                            {data.menu_item_quantity} x {data?.menu_item_name}{" "}
                          </p>
                          <p
                            className="dropDown-Text"
                            style={{ display: "inline" }}
                          >
                            {" "}
                            $
                            {data?.promotions[0]?.id?
                              // ? parseRound(parseFloat(data?.menu_item_quantity) *
                              //   parseRound(data?.promotionTotaldiscount))
                              parseRound((parseFloat(data?.menu_item_price) - parseFloat(data?.promotionTotaldiscount)) *
                              data?.menu_item_quantity)
                              :parseRound(parseFloat(data?.doubleActualAmout) *
                                parseFloat(data?.menu_item_quantity))}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="accordion-btn-table">
          <div className="btm-buttons">
            {/* {selectedBillArr.length > 1 && ( */}
            <button
              disabled={selectTicket.length > 1}
              className={
                `input-btn mt-5 mb-0 OrderHeadsBtn ` +
                `${selectTicket.length != 1 && " Disabled_btn"}`
              }
              onClick={() => {
                if (selectTicket.length == 1) {
                  tableSubmitHandler(selectTicket[0], 3);
                  // getSelectedOpenOrder()
                }
              }}
            >
              {" "}
              GO INTO SELECTED TICKET
            </button>

            <button
              // className="input-btn mt-5 mb-0 hollow OrderHeadsBtn"
              disabled={selectTicket.length <= 1}
              className={
                `input-btn mt-5 mb-0 OrderHeadsBtn ` +
                `${selectTicket.length <= 1 && " Disabled_btn"}`
              }
              onClick={() => {
                tableSubmitHandler(selectTicket, 3);
              }}
            >
              {" "}
              COMBINE SELECTED TICKETS
            </button>

            {/* )} */}
            {/* {selectedBillArr.length === 1 && ( */}

            <button
              className="input-btn mt-5 mb-0 hollow OrderHeadsBtn"
              onClick={() => {
                tableSubmitHandler();
              }}
            >
              {" "}
              + ADD NEW TICKET
            </button>
            {/* )} */}
          </div>
        </div>

        {/* {tableOrder.map((value, index) => {
          return (
            <div
              className="newOrderPopup"
              onClick={() => tableSubmitHandler(value?.id)}
              style={{ display: "block !important", margin: 0 }}
            >
              <p className="new-p1">OrderNo - {value?.order_id}</p>
              <p>
                <span className="new-p2 w-100 d-block">
                  Price - ${parseRound(value?.pos_order_data?.finalTotalAmt)}
                </span>
              </p>
            </div>
          );
        })}  */}
      </CustomPopup>
      )
    </div>
  );
};
export default TableViewer;
