import {
  ADD_ITEM_CART_DETAIL,
  REMOVE_ITEM_CART_DETAIL,
  EMPTY_CART_DETAIL,
  UPDATE_ITEM_CART_DETAIL,
  CART_DETAIL_RECEIVED,
  ORDER_PLACE_DETAIL_RECEIVED,
  EMPTY_ORDER_PLACE_DETAIL,
  CART_TAX_DETAIL_RECEIVED,
  EMPTY_CART_TAX_DETAIL,
  CART_DETAIL_STATUS_CHANGED,
  CHANGE_ORDER_SOCKET_EMIT,
  ORDER_HISTORY_DETAIL_RECEIVED,
  EMPTY_ORDER_HISTORY_DETAIL,
  VOUCHER_LIST_DETAIL_RECEIVED,
  EMPTY_VOUCHER_LIST_DETAIL,
  RECENT_ORDER_ID_RECEIVED,
  EMPTY_RECENT_ORDER_ID,
  GET_APPLY_VOUCHER_DETAIL,
  APPLY_VOUCHER_DETAIL_RECEIVED,
  EMPTY_APPLY_VOUCHER_DETAIL,
  TOGGLE_CART_PAGE,
  REMOVE_ITEM_VOUCHER_DETAIL,
  REORDER_ORDER_IDS_RECEIVED,
  EMPTY_REORDER_ORDER_IDS,
  ADD_PROMOCODE,
  EMPTY_PROMOCODE,
  CHANGE_ORDER_TIP,
  POS_CART_DETAIL_RECEIVED,
  EMPTY_POS_CART_DETAIL,
  SHOW_COMPLETE_PAGE_TOGGLE,
  CHECK_QUANTITY_SUCCEEDED,
  CHECK_QUANTITY_FAILED,
  CHECK_QUANTITY_MODAL_CLOSE,
  CART_INITIAL_STATE,
  SERVER_ORDER_ID,
  ADD_QUANTITY,
  ACTIVE_ITEM_DATA,
  COMBINED_TICKET_DATA,
  SAVE_CART_DETAILS,
  SET_VOID_CANCLE_MENU_ITEM,
  EMPTY_VOID_CANCLE_MENU_ITEM,
  GET_OPER_ORDER_DATA,
  RECIVED_OPEN_ORDER_DATA,
  TEMPORARY_POS_DATA,
  REMOVE_86_ITEM_CART_DETAIL,
  QUANTITY_CHECK_CART_ITEMS_DETAIL,
  UPDATE_ORDER_SUB_MENUS,
  UPDATE_COURSE_LIST,
  UPDATE_CALL_SERVER_DATA,
  ACKNOWLEDGE_CALL_SERVER,
} from "../actions/types";

const initialState = {
  cart_detail: [],
  pos_cart_detail: [],
  void_menu_ticket: [],
  Cancelmenu_item: [],
  printjobCount: 1,

  recent_order_detail: null,
  recent_order_id: "",
  combined_order_Data: "",
  order_history_detail: null,
  voucher_list_detail: null,
  order_socket_emit: false,
  show_cart_popup: false,
  total_cart_amount: null,
  total_service_charge: null,
  total_sale_tax: null,
  total_discount: null,
  total_tip: 0,
  applied_voucher: [],
  reorder_item_ids: [],
  applied_promocode: null,
  show_complete_page: false,
  quantityChecked: false,
  showQuantityErr: false,
  showQuantityErrMsg: "",
  addMultipeItem: false,
  activeItemData: {},
  localCartDetails: [],
  open_order: [],
  temp_pos_cart_detail: [],
  cart_items_86: [],
  KitchenOrders: [],
  course_List: [],
  callServerData: {},
  acknowledge: false,
};

const cartDetailReducer = (state = initialState, action) => {
  const defaultErrMsg = "Something went wrong!";
  switch (action.type) {
    case CART_INITIAL_STATE:
      return initialState;
    case RECIVED_OPEN_ORDER_DATA:
      console.log(action.orders)
      return { ...state, open_order: action.orders };
    case SET_VOID_CANCLE_MENU_ITEM: {
      return {
        ...state,
        void_menu_ticket: action.item?.void_menu_ticket,
        Cancelmenu_item: action?.item.Cancelmenu_item,
        printjobCount: action?.item.printjobCount,
      };
    }
    case UPDATE_ORDER_SUB_MENUS: {
      return {
        ...state,
        void_menu_ticket: action?.pos_order?.void_menu_ticket,
        Cancelmenu_item: action?.pos_order?.Cancelmenu_item,
        KitchenOrders: action?.pos_order?.KitchenOrders,
        course_List: action?.pos_order?.course_List,
      };
    }
    case UPDATE_COURSE_LIST: {
      return {
        ...state,
        course_List: action?.pos_order?.course_List,
      };
    }

    case EMPTY_VOID_CANCLE_MENU_ITEM:
      return {
        ...state,
        void_menu_ticket: [],
        Cancelmenu_item: [],
        printjobCount: 1,
      };
    case ADD_ITEM_CART_DETAIL:
      return { ...state, cart_detail: [...state.cart_detail, action.item] };

    case UPDATE_ITEM_CART_DETAIL:
      return {
        ...state,
        cart_detail: [
          ...state.cart_detail.slice(0, action.index),
          action.item,
          ...state.cart_detail.slice(action.index + 1),
        ],
      };

    case REMOVE_ITEM_CART_DETAIL:
      return {
        ...state,
        cart_detail: state.cart_detail.filter(
          (item, index) => index !== action.index
        ),
      };
    case REMOVE_86_ITEM_CART_DETAIL:
      return {
        ...state,
        // cart_detail: state.cart_detail.filter(
        //   (item, index) => index !== action.index
        // ),
        cart_detail: state.cart_detail.filter((itemObj) => {
          return !state.cart_items_86.some(
            (dataObj) =>
              dataObj.menu_item_id === itemObj.id &&
              dataObj.is_86_item === "yes"
          );
        }),
      };
    case CART_DETAIL_RECEIVED:
      return { ...state, cart_detail: action.cart_detail };

    case TOGGLE_CART_PAGE:
      return { ...state, show_cart_popup: action.payload };
    case CART_DETAIL_STATUS_CHANGED:
      return {
        ...state,
        cart_detail: state.cart_detail.map((item) => {
          item.is_added = true;
          return item;
        }),
      };
    case EMPTY_CART_DETAIL:
      return { ...state, cart_detail: [] };
    case POS_CART_DETAIL_RECEIVED:
      return { ...state, pos_cart_detail: action.cart_detail };
    case TEMPORARY_POS_DATA:
      return { ...state, temp_pos_cart_detail: action.cart_detail };

    case EMPTY_POS_CART_DETAIL:
      return { ...state, pos_cart_detail: [] };
    case EMPTY_REORDER_ORDER_IDS:
      return { ...state, reorder_item_ids: [] };
    case REORDER_ORDER_IDS_RECEIVED:
      return { ...state, reorder_item_ids: action.payload };
    case ORDER_PLACE_DETAIL_RECEIVED:
         console.log(action.payload)
      return { ...state, recent_order_detail: action.payload };
    case EMPTY_ORDER_PLACE_DETAIL:
      return { ...state, recent_order_detail: null };
    case RECENT_ORDER_ID_RECEIVED:
      return { ...state, recent_order_id: action.payload };
    case EMPTY_RECENT_ORDER_ID:
      return { ...state, recent_order_id: "" };
    case COMBINED_TICKET_DATA:
      return { ...state, combined_order_Data: action.payload };
    case ADD_PROMOCODE:
      return { ...state, applied_promocode: action.payload };
    case EMPTY_PROMOCODE:
      return { ...state, applied_promocode: null };
    case UPDATE_CALL_SERVER_DATA:
      return { ...state, callServerData: action.payload };
    case ACKNOWLEDGE_CALL_SERVER:
      return { ...state, acknowledge: action.payload };

    case CART_TAX_DETAIL_RECEIVED:
      return {
        ...state,
        total_cart_amount: action.total_cart_amount ?? null,
        total_service_charge: action.total_service_charge ?? null,
        total_sale_tax: action.total_sale_tax ?? null,
        total_discount: action.total_discount ?? null,
        total_tip: action.total_tip ?? 0,
      };
    case EMPTY_CART_TAX_DETAIL:
      return {
        ...state,
        total_cart_amount: null,
        total_service_charge: null,
        total_sale_tax: null,
        total_discount: null,
        total_tip: 0,
      };
    case ORDER_HISTORY_DETAIL_RECEIVED:
      return { ...state, order_history_detail: action.payload };
    case CHANGE_ORDER_TIP:
      return { ...state, total_tip: action.payload };
    case EMPTY_ORDER_HISTORY_DETAIL:
      return { ...state, order_history_detail: null };
    case VOUCHER_LIST_DETAIL_RECEIVED:
      return { ...state, voucher_list_detail: action.payload };
    case EMPTY_VOUCHER_LIST_DETAIL:
      return { ...state, voucher_list_detail: null };
    case APPLY_VOUCHER_DETAIL_RECEIVED:
      return { ...state, applied_voucher: action.payload };
    case EMPTY_APPLY_VOUCHER_DETAIL:
      return { ...state, applied_voucher: [] };
    case SHOW_COMPLETE_PAGE_TOGGLE:
      return { ...state, show_complete_page: action.payload };
    case REMOVE_ITEM_VOUCHER_DETAIL:
      return {
        ...state,
        applied_voucher: state.applied_voucher.filter(
          (item, index) => index !== action.index
        ),
      };
    case CHANGE_ORDER_SOCKET_EMIT:
      return { ...state, order_socket_emit: action.payload };
    case CHECK_QUANTITY_SUCCEEDED:
      return {
        ...state,
        quantityChecked: true,
      };
    case QUANTITY_CHECK_CART_ITEMS_DETAIL:
      return {
        ...state,
        cart_items_86: action.payload,
      };

    case CHECK_QUANTITY_FAILED:
      return {
        ...state,
        quantityChecked: false,
        showQuantityErr: action.payload?.showError ?? false,
        showQuantityErrMsg: action.payload?.errorMsg ?? defaultErrMsg,
      };
    case CHECK_QUANTITY_MODAL_CLOSE:
      return {
        ...state,
        quantityChecked: false,
        showQuantityErr: false,
        showQuantityErrMsg: "",
      };
    case ADD_QUANTITY:
      return {
        ...state,
        addMultipeItem: action.payload,
      };
    case ACTIVE_ITEM_DATA:
      return {
        ...state,
        activeItemData: action.payload,
      };
    case SAVE_CART_DETAILS:
      return {
        ...state,
        localCartDetails: action.payload,
      };

    default:
      return state;
  }
};
export default cartDetailReducer;
