import { toast } from "react-toastify";
import {
  LOADING_STATUS,
  GET_PDF_MENU,
  EMPTY_TOAST_MSG,
  TOAST_MSG,
  HIDE_TOAST_MSG,
  SHOW_NOTIFICATION_WITH_TIMEOUT,
  GET_TABLE_DETAIL,
  EMPTY_PDF_MENU,
  EMPTY_TABLE_DETAIL,
  ADD_FILTER_TAG,
  GET_SELECTED_ITEM_DETAIL,
  SEND_OTP_DETAIL,
  EMPTY_OTP_DETAIL,
  ADD_ITEM_CART_DETAIL,
  UPDATE_ITEM_CART_DETAIL,
  REMOVE_ITEM_CART_DETAIL,
  EMPTY_CART_DETAIL,
  ADD_SEARCH_TAG_RECEIVED,
  GET_USER_DETAIL,
  GET_LOYALITY_DETAIL,
  EMPTY_USER_DETAIL,
  EMAIL_INPUT_RECEIVED,
  OTP_INPUT_RECEIVED,
  ADD_SEARCH_TEXT,
  CART_DETAIL_RECEIVED,
  ORDER_PLACE_DETAIL,
  CART_TAX_DETAIL_RECEIVED,
  EMPTY_CART_TAX_DETAIL,
  CHANGE_TABLE_NOT_FOUND_MODAL,
  CHANGE_TABLE_ALREADY_INUSE_MODAL,
  CHANGE_ORDER_SOCKET_EMIT,
  GET_ORDER_HISTORY_DETAIL,
  EDITED_ITEM_DETAIL_RECEIVED,
  EMPTY_EDITED_ITEM_DETAIL,
  EMPTY_ORDER_PLACE_DETAIL,
  DELETE_RECENT_AND_CART_ORDER,
  GET_APPLY_VOUCHER_DETAIL,
  PHONE_INPUT_RECEIVED,
  SEND_PHONE_DETAIL,
  PHONE_CODE_INPUT_RECEIVED,
  PHONE_PIN_INPUT_RECEIVED,
  SHOW_PHONE_PIN_INPUT_TOGGLE,
  SHOW_PHONE_AGREEMENT_INPUT_TOGGLE,
  PHONE_AGREEMENT_INPUT_RECEIVED,
  SEND_RATING_INPUT,
  CHECK_ACTIVE_ORDER,
  REMOVE_ITEM_VOUCHER_DETAIL,
  CHANGE_CLEAR_CACHE_STATUS,
  ADD_PROMOCODE,
  EMPTY_PROMOCODE,
  CHANGE_ORDER_TIP,
  POS_CART_DETAIL_RECEIVED,
  EMPTY_POS_CART_DETAIL,
  ITEM_86_DETAIL_RECIEVED,
  EMPTY_ITEM_86_DETAIL,
  INACTIVE_ITEM_UNAVALIABLE_MODAL,
  ACTIVE_ITEM_UNAVALIABLE_MODAL,
  CHANGE_SESSION_EXPIRED_PAGE,
  POS_SOCKET_ORDER_RECIEVE,
  SHOW_COMPLETE_PAGE_TOGGLE,
  PAYMENT_INTENT_REQUESTED,
  PAYMENT_TOKEN_REQUESTED,
  CONFIRM_PAYMENT_REQUESTED,
  CHANGE_PAYMENT_DETAIL,
  GET_PAYMENT_DETAIL,
  XGATE_INPUT_RECEIVED,
  XGATE_INPUT_EMPTY,
  GET_XGATE_CUSTOMER,
  XGATE_DETAILS_EMPTY,
  GET_XGATE_COUPON,
  XGATE_COUPON_EMPTY,
  GET_XGATE_POINTS,
  XGATE_POINTS_EMPTY,
  TOGGLE_XGATE_POINTS_POPUP,
  CHANGE_REDIRECT_HOME_PAGE,
  GET_SERVER_DETAIL,
  EMPTY_SERVER_DETAIL,
  USER_DETAIL_RECEIVED,
  GET_OPEN_ORDERS,
  SEND_PAYMENT_MAIL,
  CHECK_QUANTITY_REQUESTED,
  CHECK_QUANTITY_MODAL_CLOSE,
  CHECK_QUANTITY_FAILED,
  UPDATE_PAYMENT_DETAIL,
  PAYMENT_INITIAL_DETAIL,
  GET_COMO_CUSTOMER,
  COMO_DETAILS_EMPTY,
  SERVER_USER_DETAIL_RECEIVED,
  EMPTY_SERVER_USER_DETAIL,
  SERVER_ORDER_ID,
  GET_SERVER_ORDER_ID,
  ADD_QUANTITY,
  ACTIVE_ITEM_DATA,

  REMOVE_COMBINED_ORDER,
  REDIRECT_TO_TABLE,
  SET_MERCHANT_SERVER_ID,
  ORDER_OBJECT,
  RESET_ORDER_OBJECT,
  SAVE_CART_DETAILS,
  SET_VOID_CANCLE_MENU_ITEM,
  EMPTY_VOID_CANCLE_MENU_ITEM,
  GET_OPER_ORDER_DATA,
  SESSION_CREATE,
  FINSERVE_PAYMENT,
  LOGINPOPUP,
  APPLE_PAY_SUCCED,
  GOOGLE_PAYMENT,
  UPDATE_GOOGLE_PAYDATA,
  COMBINE_ORDER_STATUS,
  TEMPORARY_POS_DATA,
  TABLE_INITIAL_STATE,
  START_NEW_SESSION,
  REMOVE_86_ITEM_CART_DETAIL,
  UPDATE_ORDER_SUB_MENUS,
  GET_QR_MENU_ITEMS,
  EBER_LOGIN,
  MEMBER_ID_INPUT_RECEIVED,
  ADD_TRENDING_POPULAR_TAGS,
  CHECK_ORDER_SUBMITTED,
  UPDATE_COURSE_LIST,
  CALL_SERVER_ACTION,
  UPDATE_CALL_SERVER_DATA,
  ACKNOWLEDGE_CALL_SERVER,
  PASSWORD_VERIFY,
  EMPTY_CUSTOMER,
  // COMO_DETAILS_EMPTY,

} from "./types";

// Loading
export const changeLoadingStatus = (status) => ({
  type: LOADING_STATUS,
  status,
});

// Cart Detail
export const addItemCartDetail = (item) => ({
  type: ADD_ITEM_CART_DETAIL,
  item,
});
export const setVoidEmptyMenuItem = (item) => ({
  type: SET_VOID_CANCLE_MENU_ITEM,
  item,
});
export const EmptyVoidEmptyMenuItem = () => ({
  type: EMPTY_VOID_CANCLE_MENU_ITEM,
  
});

export const updateItemCartDetail = (index, item) => ({
  type: UPDATE_ITEM_CART_DETAIL,
  index,
  item,
});
export const removeItemCartDetail = (index) => ({
  type: REMOVE_ITEM_CART_DETAIL,
  index,
});
export const remove_86_ItemCartDetail = () => ({
  type: REMOVE_86_ITEM_CART_DETAIL,
});
export const changeStatusCartDetail = (cartDetail) => ({
  type: CART_DETAIL_RECEIVED,
  cart_detail: cartDetail,
});

export const emptyCartDetail = () => ({
  type: EMPTY_CART_DETAIL,
});

export const changePosCartDetail = (cartDetail) => (
  console.log(cartDetail,"cart details recived from the changePosCartDetail"),
  {
  type: POS_CART_DETAIL_RECEIVED,
  cart_detail: cartDetail,
});

export const temproryPosData = (cartDetail) => (
  {
  type: TEMPORARY_POS_DATA,
  cart_detail: cartDetail,
});

export const emptyPosCartDetail = () => ({
  type: EMPTY_POS_CART_DETAIL,
});
export const emptyCustomerDetail=()=>({
     type:EMPTY_CUSTOMER
})
export const emptyOrderPlaceDetail = () => ({
  type: EMPTY_ORDER_PLACE_DETAIL,
});
export const deletRecentAndCartOrder = () => ({
  type: DELETE_RECENT_AND_CART_ORDER,
});
export const clearCacheStatus = (payload) => ({
  type: CHANGE_CLEAR_CACHE_STATUS,
  payload,
});
export const changeRedirectHome = (payload) => ({
  type: CHANGE_REDIRECT_HOME_PAGE,
  payload,
});
export const changeSessionExpiredPage = (payload) => ({
  type: CHANGE_SESSION_EXPIRED_PAGE,
  payload,
});

export const saveCartTaxDetail = (
  totalCartAmount,
  totalServiceCharge,
  totalSaleTax,
  totalDiscount,
  totalTip
) => ({
  type: CART_TAX_DETAIL_RECEIVED,
  total_cart_amount: totalCartAmount,
  total_service_charge: totalServiceCharge,
  total_sale_tax: totalSaleTax,
  total_discount: totalDiscount,
  total_tip: totalTip,
});

export const emptyCartTaxDetail = () => ({
  type: EMPTY_CART_TAX_DETAIL,
});
export const saveCartTemporary  = (payload) => (console.log(payload," --------- payload for save cart temprory ------------" ),{
  type: SAVE_CART_DETAILS,
  payload
});


export const orderPlaceDetail = (body, onlyTip, hideToast = false) => (
 {
  type: ORDER_PLACE_DETAIL,
  body: body,
  onlyTip,
  hideToast,
});

export const removeCombineOrder = (payload) => ({
  type: REMOVE_COMBINED_ORDER,
  payload,
});

export const changeOrderSocketEmit = (status) => ({
  type: CHANGE_ORDER_SOCKET_EMIT,
  payload: status,
});

export const addPromocodeDetail = (payload) => ({
  type: ADD_PROMOCODE,
  payload,
});

export const emptyPromocodeDetail = () => ({
  type: EMPTY_PROMOCODE,
});

export const changeTipDetail = (payload) => ({
  type: CHANGE_ORDER_TIP,
  payload,
});
export const posOrderRecieve = (pos_order) => ({
  type: POS_SOCKET_ORDER_RECIEVE,
  pos_order,
});
export const updateOrderSubMenu = (pos_order) => ({
  type: UPDATE_ORDER_SUB_MENUS,
  pos_order,
});


export const toggleShowCompletePage = (payload) => {
  return { type: SHOW_COMPLETE_PAGE_TOGGLE, payload };
};

// OTP verification
export const sendOtpDetail = (
  email,
  otp,
  merchantId,
  deviceId,
  tableNumber,is_existing_customer
) => ({
  type: SEND_OTP_DETAIL,
  email,
  otp,
  merchantId,
  deviceId,
  tableNumber,
  is_existing_customer,
});





export const emptyOtpDetail = () => ({
  type: EMPTY_OTP_DETAIL,
});

// PHONE verification
export const sendPhoneDetail = (
  phone,
  phone_code,
  phone_pin_input,
  phone_agreement_input,
  merchantId,
  deviceId,
  tableNumber
) => ({
  type: SEND_PHONE_DETAIL,
  phone,
  phone_code,
  phone_pin_input,
  phone_agreement_input,
  merchantId,
  deviceId,
  tableNumber,
});

// Rating Detail

export const updateRatingDetail = (payload) => ({
  type: SEND_RATING_INPUT,
  payload,
});

// Check open order
export const checkOpenOrder = (merchantId, tableNumber, customerId = 0) => ({
  type: CHECK_ACTIVE_ORDER,
  merchantId,
  tableNumber,
  customerId,
});

export const saveServerOrderId = (payload) => {
  return {
    type: SERVER_ORDER_ID,
    payload,
  };
};
export const getServerOrderId = (payload, callback) => {
  return {
    type: GET_SERVER_ORDER_ID,
    payload,
    callback,
  };
};


export const getOpenOrderData = (merchantId, tableNumber, customerId = 0) => ({
  type: GET_OPER_ORDER_DATA,
  merchantId,
  tableNumber,
  customerId,
});



// Check open orders by table number
export const getOpenOrdersByTable = (paymentId) => ({
  type: GET_OPEN_ORDERS,
  paymentId,
});
export const setOrderData = (orderObject) => ({
  type: ORDER_OBJECT,
  orderObject,
});
export const resetOrderData = () => ({
  type: RESET_ORDER_OBJECT,

});
export const startNewSession = () => ({
  type: START_NEW_SESSION,

});



// Table Detail

export const getTableDetail = (id, deviceId, resetAuth = false) => ({
  type: GET_TABLE_DETAIL,
  id,
  deviceId,
  resetAuth,
});

export const emptyTableDetail = () => ({
  type: EMPTY_TABLE_DETAIL,
});

export const getTableDetailByFilter = (table_id, ids, input) => (
  {
  type: ADD_FILTER_TAG,
  table_id,
  ids,
  input,
});
export const updateTrendingPopularTags = (id) => ({
  type: ADD_TRENDING_POPULAR_TAGS,
  id
});

export const updateSearchInput = (input) => ({
  type: ADD_SEARCH_TAG_RECEIVED,
  input,
});

export const updateSearchText = (input) => ({
  type: ADD_SEARCH_TEXT,
  input,
});

export const getSelectedItemDetail = (id) => ({
  type: GET_SELECTED_ITEM_DETAIL,
  id,
});

export const getEditedItemDetail = (item) => ({
  type: EDITED_ITEM_DETAIL_RECEIVED,
  payload: item,
});

export const emptyEditedItemDetail = (item) => ({
  type: EMPTY_EDITED_ITEM_DETAIL,
});

export const emptyChangeTableNotFound = () => ({
  type: CHANGE_TABLE_NOT_FOUND_MODAL,
  payload: false,
});
export const emptyChangeTableAlreadyInuse = () => ({
  type: CHANGE_TABLE_ALREADY_INUSE_MODAL,
  payload: false,
});
export const changeTableNotFound = () => ({
  type: CHANGE_TABLE_NOT_FOUND_MODAL,
  payload: true,
});
export const item86DetailRecieved = (payload) => ({
  type: ITEM_86_DETAIL_RECIEVED,
  payload,
});
export const emptyItem86Detail = () => ({
  type: EMPTY_ITEM_86_DETAIL,
});
export const showItemAvailableModal = (payload) => ({
  type: ACTIVE_ITEM_UNAVALIABLE_MODAL,
  payload,
});
export const dismissItemAvailableModal = () => ({
  type: INACTIVE_ITEM_UNAVALIABLE_MODAL,
});

// user detail

export const getUserDetail = ({
  id,
  email,
  merchantId,
  loyaltyType,
  deviceId,
  tableNumber,
  phoneDetail = null,
  code = "",
  isPasswordSaved,
  firstName,
  lastName,
  externalMemberId
    
}) => {
  return {
    type: GET_USER_DETAIL,
    id,
    loyaltyType,
    email,
    merchantId,
    deviceId,
    tableNumber,
    phoneDetail,
    code,
    isPasswordSaved,
    firstName,
    lastName,
    externalMemberId
  };
};

export const recieveUserDetail = (payload) => ({
  type: USER_DETAIL_RECEIVED,
  payload,
});
export const emptyUserDetail = () => ({
  type: EMPTY_USER_DETAIL,
});

export const serverUserDetai = (payload) => ({
  type: SERVER_USER_DETAIL_RECEIVED,
  payload,
});
export const redirectTable = (payload) => ({
  type: REDIRECT_TO_TABLE,
  payload,
});


export const emptyServerUserDetai = () => ({
  type: EMPTY_SERVER_USER_DETAIL,
});




export const updateEmailInput = (input) => {
  return { type: EMAIL_INPUT_RECEIVED, input };
};
export const updateMemberIdInput = (input) => {
  return { type: MEMBER_ID_INPUT_RECEIVED, input };
};

export const updateOtpInput = (input) => {
  return { type: OTP_INPUT_RECEIVED, input };
};

export const updatePhoneInput = (input) => {
  return { type: PHONE_INPUT_RECEIVED, input };
};
export const updatePhoneCodeInput = (input) => {
  return { type: PHONE_CODE_INPUT_RECEIVED, input };
};
export const updatePhonePinInput = (input) => {
  return { type: PHONE_PIN_INPUT_RECEIVED, input };
};
export const updatePhoneAgreementInput = (input) => {
  return { type: PHONE_AGREEMENT_INPUT_RECEIVED, input };
};
export const togglePhonePinInput = (input) => {
  return { type: SHOW_PHONE_PIN_INPUT_TOGGLE, input };
};
export const togglePhoneAgreementInput = (input) => {
  return { type: SHOW_PHONE_AGREEMENT_INPUT_TOGGLE, input };
};

// eber 

export const eberLogin = (payload,) => ({
  type: EBER_LOGIN,
  payload,
});


export const verifyPassword=(payload)=>
  ({
    type: PASSWORD_VERIFY,
    payload
  })




// xgate

export const changeXgateInput = (input, value) => {
  return { type: XGATE_INPUT_RECEIVED, input, value };
};
export const emptyXgateInput = () => {
  return { type: XGATE_INPUT_EMPTY };
};

export const getXgateCustomer = (payload, loginUser = true) => ({
  type: GET_XGATE_CUSTOMER,
  payload,
  loginUser,
});

export const emptyXgateDetails = () => ({
  type: XGATE_DETAILS_EMPTY,
});

export const getComoCustomer = (payload, loginUser = true) => ({
  type: GET_COMO_CUSTOMER,
  payload,
  loginUser,
});

export const emptyComoDetails = () => ({
  type: COMO_DETAILS_EMPTY,
});

export const getXgateCoupon = (payload) => ({
  type: GET_XGATE_COUPON,
  payload,
});

export const emptyXgateCoupon = () => ({
  type: XGATE_COUPON_EMPTY,
});

export const getXgatePoints = (payload) => ({
  type: GET_XGATE_POINTS,
  payload,
});

export const emptyXgatePoints = () => ({
  type: XGATE_POINTS_EMPTY,
});

export const toggleXgatePointsPopup = (payload) => {
  return { type: TOGGLE_XGATE_POINTS_POPUP, payload };
};

// loyality detail

export const getLoyalityDetail = (
  card,
  cvv,
  merchantId,
  deviceId,
  tableNumber
) => {
  return {
    type: GET_LOYALITY_DETAIL,
    card,
    cvv,
    merchantId,
    deviceId,
    tableNumber,
  };
};

// order history
export const getOrderHistoryDetail = (merchantId) => ({
  type: GET_ORDER_HISTORY_DETAIL,
  merchantId,
});

// apply voucher
export const getApplyVoucherDetail = (voucher, merchantId) => ({
  type: GET_APPLY_VOUCHER_DETAIL,
  voucher,
  merchantId,
});

export const removeItemVoucherDetail = (index) => ({
  type: REMOVE_ITEM_VOUCHER_DETAIL,
  index,
});


export const chnageLoginPop = (payload) => ({
  type: LOGINPOPUP,
  payload,
});

// pdf menu

export const getPdfMenu = (id) => {
  return { type: GET_PDF_MENU, id };
};

export const emptyPdfMenu = () => ({
  type: EMPTY_PDF_MENU,
});

// Notifications
export const emptyToastMsg = () => ({
  type: EMPTY_TOAST_MSG,
});

export const showNotification = (id, text, msgtype) => {
  return { type: TOAST_MSG, id, text, msgtype };
};

export const hideNotification = (id) => {
  return { type: HIDE_TOAST_MSG, id };
};

export const showNotificationWithTimeout = (text, msgtype) => {
  if (msgtype == "success") {
    toast.success(text);
  } else if (msgtype == "error") {
    toast.error(text);
  } else {
    toast(text);
  }
  return { type: SHOW_NOTIFICATION_WITH_TIMEOUT, text, msgtype };
};

// pdf menu

export const getServerDetail = (payload) => ({
  type: GET_SERVER_DETAIL,
  payload,
});

export const setMarchentServerID = (payload) => ({
  type: SET_MERCHANT_SERVER_ID,
  payload,
});

export const emptyServerDetail = () => ({
  type: EMPTY_SERVER_DETAIL,
});

// payment
export const getPaymentIntentDetail = (payload) => ({
  type: PAYMENT_INTENT_REQUESTED,
  payload,
});

export const paymentToken = (payload) => ({
  type: PAYMENT_TOKEN_REQUESTED,
  payload,
});

export const confirmPayment = (payload) => ({
  type: CONFIRM_PAYMENT_REQUESTED,
  payload,
});
export const paymentInitialState = () => ({
  type: PAYMENT_INITIAL_DETAIL,
});
export const changePaymentDetail = (payload) => ({
  type: CHANGE_PAYMENT_DETAIL,
  payload,
});
export const getPaymentDetail = (payload, getDetail = false) => ({
  type: GET_PAYMENT_DETAIL,
  payload,
  getDetail,
});

export const updatePaymentDetail = (payload) => ({
  type: UPDATE_PAYMENT_DETAIL,
  payload,
});

export const sendPaymentMail = (payload) => ({
  type: SEND_PAYMENT_MAIL,
  payload,
});

// CHECK_QUANTITY
export const quantityCheckedDetails = (payload = {}) => ({
  type: CHECK_QUANTITY_REQUESTED,
  payload,
});

export const quantityModalClose = () => ({
  type: CHECK_QUANTITY_MODAL_CLOSE,
});
export const quantityCheckedClose = () => ({
  type: CHECK_QUANTITY_FAILED,
});

// REPEATE_CHECK
export const addSameProductCheck = (payload) => (
  {
    type: ADD_QUANTITY,
    payload,
  }
);
export const setActiveItemData = (payload) => (
  {
    type: ACTIVE_ITEM_DATA,
    payload,
  }
);

//APPLE_PAYMENT

export const getApplePaySession = (payload, callback) => {
  return {
    type: SESSION_CREATE,
    payload,
    callback,
  };
};

export const finservePayment = (payload, callback) => {
  return {
      type: FINSERVE_PAYMENT,
      payload,
      callback
  }
}
export const apple_pay_succed = (payload) => {
  return {
      type: APPLE_PAY_SUCCED,
      payload
  }
}
export const google_fiserve_payment = (payload,callback) => {
  return {
      type: GOOGLE_PAYMENT,
      payload,
      callback
  }
}

export const combineOrderStatus  = (payload) => {
  return {
      type: COMBINE_ORDER_STATUS,
      payload
  }
}

export const getMenuQR  = (payload) => {
  return {
      type: GET_QR_MENU_ITEMS,
      payload
  }
}
export const checkOrderrStatus  = (payload) => {
  return {
      type: CHECK_ORDER_SUBMITTED,
      payload
  }
}

export const updateCourselist  = (payload) => {
  return {
      type: UPDATE_COURSE_LIST,
      payload
  }
}


export const updateCallServer = (payload) => ({
  type: CALL_SERVER_ACTION,
  payload
});

export const updateServerData  = (payload) => {
  return {
      type: UPDATE_CALL_SERVER_DATA,
      payload
  }
}
export const acknowledgeCallServerData  = (payload) => {
  return {
      type: ACKNOWLEDGE_CALL_SERVER,
      payload
  }
}

