import React, {
  useEffect,
  useState,
  useRef,
  Fragment,
  useCallback,
} from "react";
import { useHistory } from "react-router-dom";
import { DeviceUUID } from "device-uuid";
import { cameraIcon, imagePlaceholderIcon, qrCodeIcon } from "../../Assets";
import QrInput from "../Components/QR_code/QrInput";
import QrCamera from "../Components/QR_code/QrCamera";
import {
  updateEmailInput,
  getUserDetail,
  updateOtpInput,
  showNotificationWithTimeout,
  updatePhoneInput,
  getComoCustomer,
  chnageLoginPop,
} from "../../Redux/actions";
import CountryCode from "../../Assets/country_codes.json";
import { connect, useSelector, useDispatch } from "react-redux";
import Modal from "../Components/Modal";
import LoginLayout from "../Components/LoginLayout";
import LoginInput from "../Components/LoginInput";
import { Motion, spring } from "react-motion";
import LoginNewCustomer from "./LoginNewCustomer";
const LoginComo = () => {
  var uuid = "not-valid";
  let history = useHistory();
  const loaderReducer = useSelector((state) => state.loaderReducer);
  const tableReducer = useSelector((state) => state.tableDetailReducer);
  const merchantId = tableReducer?.table_detail?.Merchant?.id;
  const loyaltyEnabled =
    tableReducer?.table_detail?.Merchant?.loyalty_enabled ?? false;
  const loyaltyPackage =
    tableReducer?.table_detail?.Merchant?.MerchantLoyaltySetting
      ?.LoyaltyPackage ?? null;
  const tableNumber =
    tableReducer?.table_detail?.PosTableMaster?.table_number ?? "";
  const deviceId = uuid;
  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authDetailReducer);
  const email = authReducer.email_input;
  const otp = authReducer.otp_input;
  const phone = authReducer.phone_input;
  const phone_pin_input = authReducer.phone_pin_input;
  const phone_agreement_input = authReducer.phone_agreement_input;
  const phone_code =
    authReducer.phone_code_input == "" ? "+65" : authReducer.phone_code_input;
  const [scannerModal, setScannerModal] = useState(false);
  const [scannerInvalidModal, setScannerInvalidModal] = useState(false);
  const [showFileInput, setShowFileInput] = useState(false);
  const scannerInvalidModalRef = useRef();
  scannerInvalidModalRef.current = scannerInvalidModal;
  const showFileInputRef = useRef();
  showFileInputRef.current = showFileInput;

  const [cameraInvalidModal, setCameraInvalidModal] = useState(false);
  const [loyaltyPopUp, setLoyaltyPopUp] = useState(
    authReducer.showNewLoginPopUp
  );
  
  const [loginNew, setLoginNew] = useState(false);

  const qrInputRef = useRef("qrInputRef");
  const qrCameraRef = useRef("qrCameraRef");

  useEffect(() => {
    // redirect / if not have table detail
    if (
      loaderReducer?.loading === false &&
      tableReducer?.table_detail?.show_table_not_found_modal === false &&
      (tableReducer?.table_detail?.Merchant?.id === "" ||
        tableReducer?.table_detail?.Merchant?.id === null ||
        tableReducer?.table_detail?.Merchant?.id === undefined ||
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id === "" ||
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id === null ||
        tableReducer?.table_detail?.PosTableMaster?.qr_code_id === undefined)
    ) {
      history.push("/404");
    }
    // if already login send to menu page
    if (
      tableReducer?.table_detail?.PosTableMaster?.qr_code_id !== undefined &&
      loaderReducer?.loading === false &&
      authReducer?.user_detail !== null &&
      authReducer?.user_detail !== undefined
    ) {
      history.push(
        `/get_table_details/${tableReducer?.table_detail?.PosTableMaster?.qr_code_id}`
      );
    }
  }, []);

  const emailSubmitHandler = (el) => {
    el.preventDefault();
    dispatch(getComoCustomer({ merchantId, phone, email, appClientId: otp }));
    // const xgateInputs = {
    //     merchantId: merchantId,
    //     tableNumber: tableNumber,
    //   };
    // dispatch(getXgateCustomer(xgateInputs));

    // dispatch(sendOtpDetail(email, otp, merchantId, deviceId, tableNumber));
  };

  const guestSubmitHandler = (el) => {
    dispatch(
      getUserDetail({
        email: "",
        merchantId: merchantId,
        deviceId: deviceId,
        tableNumber: tableNumber,
      })
    );
  };

  useEffect(() => {
    if (
      showFileInputRef.current == true &&
      scannerInvalidModalRef.current == false &&
      qrInputRef.current
    ) {
      qrInputRef.current.click();
    }
  }, [scannerInvalidModal, showFileInput]);

  const emailChangeHandler = (el) => {
    dispatch(updateEmailInput(el.target.value));
  };
  const phoneChangeHandler = (el) => {
    dispatch(updatePhoneInput(el.target.value));
  };

  const otpChangeHandler = (el) => {
    dispatch(updateOtpInput(el.target.value));
  };

  const scannerInvalidModalCancel = useCallback(() => {
    // setScannerInvalidModal(false);
  }, []);

  const scannerInvalidModalSubmit = useCallback(() => {
    // setScannerInvalidModal(false);
    setShowFileInput(true);
    // qrInputRef.current.click();
  }, []);

  const cameraInvalidModalCancel = useCallback(() => {
    setCameraInvalidModal(false);
  }, []);

  const cameraInvalidModalSubmit = useCallback(() => {
    setCameraInvalidModal(false);
    setScannerModal(true);
  }, []);

  const scannerHandleSuccess = useCallback(
    (res) => {
      if (res && res.length > 0) {
        dispatch(getComoCustomer({ merchantId, appClientId: res }));
      } else {
        setScannerModal(false);
        // setScannerInvalidModal(true);
        // dispatch(
        //   showNotificationWithTimeout(
        //     "Invalid QR code",
        //     "error"
        //   )
        // );
      }
    },
    [tableReducer]
  );
  const scannerHandleError = useCallback(
    (err) => {
      dispatch(showNotificationWithTimeout(err, "error"));
    },
    [tableReducer]
  );

  return (
    <>
      <form onSubmit={emailSubmitHandler}>
        <div className="group-sec">
          <div className="title-heading bold text-green"
            style={{color:tableReducer?.table_detail?.QrOrdering?.brand_main_color}}

           >
            WELCOME TO {tableReducer?.table_detail?.Merchant?.account_name}
          </div>
        </div>
        <div className="title-heading bold">ENTER EMAIL ADDRESS </div>
        <LoginInput
          placeholder="Email"
          value={authReducer?.email_input ?? ""}
          onChange={emailChangeHandler}
        />
        <div className="or">
          {" "}
          <span> Or </span>{" "}
        </div>
        <div className="title-heading bold">ENTER MOBILE NUMBER </div>
        <LoginInput
          placeholder="Mobile Number"
          value={authReducer.phone_input ?? ""}
          onChange={phoneChangeHandler}
        />
        <div className="or">
          {" "}
          <span> Or </span>{" "}
        </div>
        <div className="title-heading bold"> ENTER  LOYALTY MEMBER ID </div>
        <LoginInput
          placeholder="Member ID"
          value={authReducer.otp_input ?? ""}
          onChange={otpChangeHandler}
        />
        <div className="resend_btn_row my-4">
          <button type="submit" className="btn green-btn new-green">
            Submit
          </button>
        </div>
      </form>
      <div className="or">
        {" "}
        <span> Or </span>{" "}
      </div>
      <div className="title-heading bold">Member App QR Code Scan</div>

      <div className="qr-sec">
        {/* <figure className="m-0">
          <img src={qrCodeIcon} />
        </figure> */}

        <div className="btn-sec col-12 d-flex px-0 pb-0">
          <a
            href="javascript:void(0);"
            className="green-btn mr-2"
            onClick={() => {
              document.body.classList.add("modal-open");
              setScannerModal(true);
            }}
          style={{backgroundColor:tableReducer?.table_detail?.QrOrdering?.brand_main_color}}

          >
            <div className="cam">
              <img src={cameraIcon} />
              <br /> Use Camera{" "}
            </div>
          </a>

          <a
            href="javascript:void(0);"
            className="green-btn ml-2"
            onClick={() => {
              setShowFileInput(false);
              if (qrInputRef.current) qrInputRef.current.click();
            }}
          style={{backgroundColor:tableReducer?.table_detail?.QrOrdering?.brand_main_color}}

          >
            <div className="d-none">
              {!scannerInvalidModal && (
                <QrInput
                  handleScan={scannerHandleSuccess}
                  ref={qrInputRef}
                  onError={scannerHandleError}
                />
              )}
            </div>
            <div className="img">
              <img src={imagePlaceholderIcon} />
              <br /> Use Image
            </div>
          </a>
        </div>
      </div>
      <div className="or">
        {" "}
        <span> Or </span>{" "}
      </div>
      <div className="guest_login my-3 mb-80">
        <a
          href="javascript:void(0)"
          className="btn green-btn new-green"
          onClick={guestSubmitHandler}
          style={{backgroundColor:tableReducer?.table_detail?.QrOrdering?.brand_main_color}}

        >
          Continue as a guest
        </a>
      </div>
      <section className={`qrcode_modal ${scannerModal ? "show" : ""}`}>
        <div className="top">
          <h4>Scan QR Code</h4>
        </div>
        {scannerModal && (
          <QrCamera
            class="qr-camera-container"
            handleScan={(res) => {
              if (res && res.length > 0) {
                dispatch(getComoCustomer({ merchantId, appClientId: res }));
              } else {
                // setScannerModal(false);
                // setScannerInvalidModal(true);
                // dispatch(
                //   showNotificationWithTimeout(
                //     "Invalid QR code",
                //     "error"
                //   )
                // );
              }
            }}
            ref={qrCameraRef}
            onError={(err) =>
              dispatch(showNotificationWithTimeout(err, "error"))
            }
          />
        )}

        {/* <div className="screen">
            <p className="msg">Place code inside the box. </p>
          </div> */}
        <section class="bottom-sec">
          <div
            class="green-sec"
            onClick={() => {
              document.body.classList.remove("modal-open");
              setScannerModal(false);
            }}
          >
            Cancel
          </div>
        </section>
      </section>
      <Modal
        show={scannerInvalidModal}
        title="Invalid QR code!"
        onCancel={scannerInvalidModalCancel}
        OnSubmit={scannerInvalidModalSubmit}
        cancelBtnText="Cancel"
        submitBtnText="Retry"
      />

      <Modal
        show={cameraInvalidModal}
        title="Invalid QR code!"
        onCancel={cameraInvalidModalCancel}
        OnSubmit={cameraInvalidModalSubmit}
        cancelBtnText="Cancel"
        submitBtnText="Retry"
      />

      <Modal
        show={authReducer.showNewLoginPopUp}
        title="Email not recognized. Do you want to try again or signup for a non-loyalty customer account?"
        onCancel={() => dispatch(chnageLoginPop(false))}
        OnSubmit={() => {
          dispatch(chnageLoginPop(false));
          setLoginNew(true);
        }}
        cancelBtnText="Try Again"
        submitBtnText="Sign-up"
      />
      <Motion
        defaultStyle={{ x: 100 }}
        style={{
          x: spring(loginNew ? 0 : 100, {
            precision: 1,
            stiffness: 300,
            damping: 44,
          }),
        }}
      >
        {({ x }) => {
          return (
            <LoginNewCustomer
              style={{
                right: `-${x}%`,
              }}
              customerType={loginNew}
              setCustomerType={setLoginNew}
            />
          );
        }}
      </Motion>
    </>
  );
};

export default LoginComo;
