import React, { Suspense, useState, useEffect, useRef } from "react";
import { DeviceUUID } from "device-uuid";
import { connect, useDispatch, useSelector } from "react-redux";
import PageVisibility from "react-page-visibility";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";
import {
  changeOrderSocketEmit,
  emptyCartDetail,
  emptyOrderPlaceDetail,
  showNotificationWithTimeout,
  deletRecentAndCartOrder,
  clearCacheStatus,
  changeStatusCartDetail,
  emptyTableDetail,
  getTableDetail,
  changeLoadingStatus,
  changeTipDetail,
  checkOpenOrder,
  changePosCartDetail,
  posOrderRecieve,
  getOpenOrdersByTable,
  toggleShowCompletePage,
  item86DetailRecieved,
  getPaymentDetail,
  emptyUserDetail,
  redirectTable,
  resetOrderData,
  updateOrderSubMenu,
  updateServerData,
  acknowledgeCallServerData,
} from "../Redux/actions";
import io from "socket.io-client";
import Loading from "../Scenes/Components/Loading";
import ToastMessage from "../Scenes/Components/ToastMessage";
import { LoginScreen } from "../Scenes/AuthScreens";
import NotFoundPage from "../Scenes/Components/NotFoundPage";
import {
  setPageDetails,
  getCartSubmittedItems,
  getSessionStorageOrDefault,
  checkExpireMerchantTime,
  getPosCartSubmittedItems,
  checkArray,
  getDeviceId,
  checkNull,
} from "../helpers";
import { authRoutes, tableRoutes } from "../Utils";
import moment from "moment";
import { get } from "react-scroll/modules/mixins/scroller";
import OutletMenu from "../Scenes/AuthScreens/OutletMenu";
import { AddItemScreen } from "../Scenes/Screens";
import GetItemsDetails from "../Scenes/Screens/GetItemsDetails";
import { showMenuRoutes } from "../Utils/showMenuRoutes";
import { firebaseDB, ref, push } from "../Utils/firebase";
// import { TransitionGroup } from "react-transition-group";
// import { CSSTransition } from "react-transition-group";

const projectType = process?.env?.REACT_APP_TYPE;

let socketUrl = process?.env?.REACT_APP_SOCKET_URL;

const Main = ({
  user_detail,
  server_detail,
  server_user_detail,
  table_detail,
  page_title,
  loading,
  recent_order_detail,
  showNotificationWithTimeout,
  getTableDetail,
  server_order_id,
  checkOpenOrder,
  is_payment_started,
  paymentReq,
  updatedPaymentReq,
  getPaymentDetail,
  getOpenOrdersByTable,
  pausePaymentProcess,
  recent_order_id,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [socket, setSocket] = useState(null);
  // const { redirect_to_table } = useSelector((state) => state.authDetailReducer);
  const { order_id, order_offline_id } = useSelector(
    (state) => state.tableDetailReducer
  );

  // useEffect(async()=>{

  // },[])

  const [isConnected, setIsConnected] = useState(false);
  const [socket,setSocket] = useState(null);
  const [isPageVisible, setIsPageVisible] = useState(true);
  const [isServerLogin, setIsServerLogin] = useState(false);
  const [throttleTimeout, setThrottleTimeout] = useState(null);
  const throttleTimeoutRef = useRef(throttleTimeout);
  throttleTimeoutRef.current = throttleTimeout;




  var uuid = "not-valid";
  // const merchantId = server_user_detail?.merchant_id;
  const merchantId = table_detail?.Merchant?.id;

  const tableNumber = table_detail?.PosTableMaster?.table_number ?? "";
  const tableId = table_detail?.PosTableMaster?.qr_code_id;
  const userId = user_detail?.id;

  const recent_order_detailRef = useRef(recent_order_detail);
  recent_order_detailRef.current = recent_order_detail;
  const loadingRef = useRef(loading);
  loadingRef.current = loading;

  const checkExpiredSession = () => {
    // check service period
    const servicePeriodExpire = getSessionStorageOrDefault(
      "servicePeriodExpire",
      undefined
    );
    if (servicePeriodExpire) {
      const isExpiredServicePeriod = checkExpireMerchantTime(
        servicePeriodExpire,
        table_detail?.Merchant?.timezone_offset ?? "00:00"
      );
      if (isExpiredServicePeriod && !loading && !is_payment_started) {
        sessionExpiredHandler();
        // getTableDetail(tableId, uuid, true);
      }
    }
    // check service period END
  };

  const checkInactiveSession = () => {
    // check service period
    const inactiveSession = getSessionStorageOrDefault(
      "session_start_time",
      undefined
    );
    const timeFormat = "YYYY-MM-DD HH:mm:ss";
    if (inactiveSession) {
      const inactiveSessionTime = moment(inactiveSession, timeFormat);
      const isExpired = moment().isAfter(inactiveSessionTime);
      if (isExpired && !loading && !is_payment_started) {
        sessionExpiredHandler();
        // getTableDetail(tableId, uuid, true);
      }
    } else {
      sessionStorage.setItem(
        "session_start_time",
        JSON.stringify(moment().add(20, "minutes").format(timeFormat))
      );
    }
    // check service period END
  };

  // For Connect socket FirstTime
  useEffect(async()=>{
    const deviceID = await getDeviceId();
    const connectionPayload = { device_id: deviceID, merchant_id: merchantId,type:"qr" };
    if (merchantId && !socket) {
      console.log("Merchant ID received, connecting to socket...");
  
      const socketIntance = io(`${socketUrl}`, {
        // transports: ["polling", "websocket"],
        transports: ["websocket","polling"],
        // extraHeaders: {
        //     Upgrade: "websocket"
        // },
        withCredentials: true,
       query: connectionPayload
      });

     
  
      socketIntance.on("connect", () => {
        console.log("Connected to socket server!");
        setIsConnected(true);
      });
  
      socketIntance.on("disconnect", (reason) => {

        console.log("Disconnected from socket server.",reason);
        setIsConnected(false);
      });
  
      socketIntance.on("connect_error", (error) => {
        console.error("Connection Error:", error.message, error);
      })
      setSocket(socketIntance);
    }
    },
  [merchantId])

  //For Join the room
  useEffect(async () => {
    console.log(server_user_detail, isConnected, merchantId, isServerLogin);

    if (isConnected && merchantId) {
      const deviceID = await getDeviceId();
      const emitPayload = { device_id: deviceID, merchant_id: merchantId,type:"qr" };

      socket.on("qr_user_joined", (...args) => {
        console.log("qr_user_joined", args);
      });

      socket.emit("add_qr_user", emitPayload,(response) => {
        console.log("Server Response for add_qr_user:", response)});

      setIsServerLogin(true);

      if (server_user_detail?.id) {
        const loginPayload = {
          currentUserPin: server_user_detail?.user_pin ?? "",
          deviceUUID: deviceID,
          merchant_id: merchantId,
          env: projectType == "production" ? "live" : "uat",
        };

        socket.emit("is_login_with_other_user", loginPayload);
        socket.on("recieve_is_login_with_other_user", (...args) => {
          if (args?.[0]?.message?.currentUserPin) {
            const newPinLogin = args?.[0]?.message;

            if (
              newPinLogin?.currentUserPin == server_user_detail?.user_pin &&
              newPinLogin?.merchant_id == merchantId &&
              newPinLogin?.deviceUUID != deviceID
            ) {
              if (server_detail) {
                // redirectToServerLogin();
                dispatch(redirectTable(true));
              }
            }
          }
        });
      }
    }

    return () => {
      resetSocket();
    };
  }, [server_user_detail, isConnected, merchantId]);

  

  useEffect(() => {
    if(socket){
      setPageDetails({
        title: page_title,
      });
      if (isConnected) {
        resetSocket();
      }
  
      if (!isConnected) {
        socket.on("connect", () => {
          setIsConnected(true);
        });
      }
      socket.on("disconnect", () => {
        setIsConnected(false);
      });
    }
   
  }, [isConnected]);

  const redirectToServerLogin = () => {
    // window.location.href = " https://www.google.com/";

    window.location.href = `${window.location.origin}/table-Viewer/`;

    // window.location.href = `${window.location.origin}/get_server_details/${server_detail?.CurrentRoom.merchant_id}/${server_detail.CurrentRoom.server_code}`;
  };

  const redirectToTableSelection = () => {
    window.location.href = `http://localhost:3000/table-Viewer`;
    // window.location.href = `${window.location.origin}/get_server_details/${server_detail.CurrentRoom.merchant_id}/${server_detail.CurrentRoom.server_code}`;
  };

  const resetSocket = async () => {
    const deviceID = await getDeviceId();
    setIsServerLogin(false);
    if (socket) {
      const emitPayload = { device_id: deviceID, merchant_id: merchantId };
      socket.emit("leave_user", emitPayload);
      socket.off("connect");
      socket.off("disconnect");
      // socket.off(`pos_to_ordrr_${recent_order_id}`);
      socket.off("item_availability_" + merchantId);
    }
  };

  useEffect(async () => {
    if (isConnected && merchantId) {
      socket.on(`pos_to_ordrr_${recent_order_id}`, (...args) => {
        if (
          !loadingRef.current &&
          args?.[0]?.["message"]?.["order_offline_id"] !== undefined &&
          recent_order_detailRef.current?.order_list?.length > 0 &&
          recent_order_detailRef.current?.order_list?.findIndex(
            (p) =>
              p.order_offline_id != "" &&
              p.order_offline_id == args?.[0]?.["message"]?.["order_offline_id"]
          ) > -1
        ) {
          const pos_order = args?.[0]?.["message"];
          if (!loadingRef.current) {
            dispatch(updateOrderSubMenu(pos_order));
          }

          if (!loadingRef.current) {
            dispatch(
              changePosCartDetail({
                // payment_type: pos_order?.payment_type ?? [],
                // split_type_name:pos_order?.split_type_name ?? "",
                // promotions: pos_order?.promotions ?? [],
                // items: pos_order?.menu_item,
                // transaction_id: pos_order?.transaction_id ?? [],
                // fiserv_order_id: pos_order?.fiservorderId ?? [],
                // loyaltyToken: pos_order?.loyaltyToken,
                // customer_name: pos_order?.customer_name,
                // online_customer_id: pos_order?.online_customer_id,
                // loyalty_login_type: pos_order?.loyalty_login_type,
                channel_name: pos_order?.channel_name ?? "",
                reservationType: pos_order?.reservationType ?? "",
                addCustomTableName: pos_order?.addCustomTableName ?? "",
                reservation_id: pos_order?.reservation_id ?? "",
                payment_type: pos_order?.payment_type ?? [],
                split_type_name: pos_order?.split_type_name ?? "",
                promotions: pos_order?.promotions ?? [],
                items: pos_order?.menu_item,
                transaction_id: pos_order?.transaction_id ?? [],
                fiserv_order_id: pos_order?.fiservorderId ?? [],
                loyaltyToken: pos_order?.loyaltyToken,
                customer_name: pos_order?.customer_name,
                online_customer_id: pos_order?.online_customer_id,
                loyalty_login_type: pos_order?.loyalty_login_type,
                customer_tag_more_count: pos_order?.customer_tag_more_count,
                visit_no: pos_order?.visit_no,
                favourite_count: pos_order?.favourite_count,
                member_tier: pos_order?.member_tier,
                customer_tag: pos_order?.customer_tag,
                customer_tag_color: pos_order?.customer_tag_color,
                point_balance: pos_order?.point_balance,
                order_tags: pos_order?.order_tags,
              })
            );
          }

          // if (!loadingRef.current) {
          // dispatch(posOrderRecieve(pos_order));
          // }
          if (!loadingRef.current && pos_order?.order_status == "complete") {
            // completeOrderHandler();
            dispatch(toggleShowCompletePage(true));
          }
          if (
            (!loadingRef.current && pos_order?.order_status == "cancelled") ||
            pos_order?.table_no != table_detail?.PosTableMaster?.table_number
          ) {
            // completeOrderHandler();
            sessionExpiredHandler();
          }
        }
      });
      socket.on("item_availability_" + merchantId, (...args) => {
        console.log("item_availability_-----------------------", args);
        if (!loadingRef.current) {
          getTableDetail(tableId, uuid);
          dispatch(item86DetailRecieved(args?.[0]?.["message"] ?? []));
        }
      });
      // socket.on("disconnect", () => this.disconnect());
      socket.on("connect_error", (err) => {});
    }

    return () => {
      resetSocket();
    };
  }, [isConnected, merchantId, recent_order_id]);

  useEffect(() => {
     if(!socket) return
    socket.on("get_manage_call_server", (...args) => {
      if (args?.[0]?.["message"]?.merchant_id) {
        dispatch(updateServerData({}));
        dispatch(acknowledgeCallServerData(true));
      }
    });
  }, []);

  useEffect(() => {
    if(!socket) return
    socket.on("offline_order", (...args) => {
      if (
        args?.[0]?.["message"]?.merchant_id == server_user_detail?.merchant_id
      ) {
        const posOrderData = args?.[0]?.["message"]?.order_list[0];
        if (
          posOrderData?.order_offline_id &&
          recent_order_id == posOrderData?.order_offline_id
        ) {
          dispatch(
            changePosCartDetail({
              payment_type: posOrderData?.payment_type ?? [],
              split_type_name: posOrderData?.split_type_name ?? "",
              promotions: posOrderData?.promotions ?? [],
              items: posOrderData?.menu_item,
              transaction_id: posOrderData?.transaction_id ?? [],
              fiserv_order_id: posOrderData?.fiservorderId ?? [],
            })
          );
        }
      }
    });
  }, []);

  
  useEffect(async () => {
    const deviceID = await getDeviceId();
    if (order_offline_id && isConnected) {
      const data = {
        offlineOrderID: order_offline_id ?? "",
        orderID: order_id ?? "",
      };
      const emitPayload = {
        data: data,
        deviceUUID: deviceID,
        merchant_id: merchantId,
      };
      socket.emit("login_to_order", emitPayload);
    }
    return () => {
      resetSocket();
    };
  }, [order_offline_id, order_id]);

  useEffect(() => {
    if (isServerLogin) {
      socket.on("logout_from_order", (...args) => {
        if (args?.[0]?.message?.data) {
          const order = args?.[0]?.message?.data;
          if (
            order?.offlineOrderID == order_offline_id &&
            order?.orderID == order_id
            // order?.deviceUUID != deviceID
          ) {
            console.log("inside the if condition ");
            window.location.href = "/table-Viewer";
          } else {
            console.log("inside the else condition ");
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    const root = document.querySelector(":root");
    root.style.setProperty(
      "--store-color",
      `${
        table_detail?.QrOrdering?.brand_main_color ||
        server_detail?.QrOrdering?.brand_main_color ||
        "#3cc1c0"
      }`
    );
    root.style.setProperty(
      "--store-hyperlink-color",
      `${
        table_detail?.QrOrdering?.brand_highlight_color ||
        server_detail?.QrOrdering?.brand_highlight_color ||
        "#3cc1c0"
      }`
    );
  }, [
    table_detail?.QrOrdering?.brand_highlight_color,
    table_detail?.QrOrdering?.brand_main_color,
    server_detail?.QrOrdering?.brand_highlight_color,
    server_detail?.QrOrdering?.brand_main_color,
  ]);

  useEffect(async () => {
    if (isConnected && server_detail?.Merchant?.id && merchantId) {
      socket.on("logout_user", (...args) => {
        // debugger
        if (args?.[0]?.message?.merchant_id) {
          const logoutStatus = args?.[0]?.message;
          if (logoutStatus?.merchant_id == merchantId) {
            if (server_detail) {
              showNotificationWithTimeout("Day is already closed!", "error");
              setTimeout(() => {
                redirectToServerLogin();
              }, 5000);
            }
          }
        }
      });
    }

    return () => {
      resetSocket();
    };
  }, [server_detail, isConnected, merchantId]);

  

  const throttle = (func, limit) => {
    return function () {
      const args = arguments;
      const context = this;
      if (throttleTimeoutRef.current === null) {
        const trottleTime = setTimeout(() => {
          func.apply(context, args);
          setThrottleTimeout(null);
        }, limit);
        setThrottleTimeout(trottleTime);
      }
    };
  };
  const throttleNotification = throttle(function () {
    showNotificationWithTimeout("Order Completed!", "success");
  }, 3000);

  const completeOrderHandler = () => {
    dispatch(changeLoadingStatus(true));
    dispatch(clearCacheStatus(true));
    // deletRecentAndCartOrder();
    throttleNotification();
    window.location.href = "../completed";
  };
  const sessionExpiredHandler = () => {
    // dispatch(changeLoadingStatus(true));
    dispatch(clearCacheStatus(true));
    window.location.href = "../session-expired";
  };

  const handleVisibilityChange = (isVisible) => {
    if (isVisible) {
      if (isPageVisible == false) {
        if (server_user_detail && server_detail) {
          redirectToServerLogin();
        }

        const pathName = window.location.pathname;
        if (pathName.indexOf("payment") > -1 || is_payment_started === true) {
          if (pathName.indexOf("payment-detail") > -1) {
            const paymentIdArr = pathName.split("/");
            const paymentId = paymentIdArr?.[paymentIdArr.length - 1];
            getOpenOrdersByTable(paymentId);
          }
          if (
            !pausePaymentProcess &&
            updatedPaymentReq === true &&
            paymentReq !== null
          ) {
            const getPaymentBody = {
              merchant_id: table_detail?.Merchant?.id,
              storeId: table_detail?.QrOrdering?.merchant_partnerid,
              transactionId: paymentReq?.ipgTransactionId,
            };
            getPaymentDetail(getPaymentBody, true);
          }
        } else if (
          is_payment_started === false &&
          (user_detail?.id || checkNull(server_order_id, false))
        ) {
          // checkOpenOrder(merchantId, tableNumber, user_detail?.id);
        }
      }
      // setIsPageVisible(isVisible);
    } else {
      // setIsPageVisible(false);
    }
    // dispatch(emptyUserDetail())
  };

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      <Suspense fallback={<Loading show={true} />}>
        <Loading
          show={
            loading &&
            paymentReq?.transactionStatus !== "WAITING" &&
            paymentReq?.transactionResult !== "WAITING"
          }
        />
        <ToastMessage />
        <div>
          <Router history={history}>
            {/* <TransitionGroup>
          <CSSTransition
            // key={location.key}
            timeout={500}
            classNames="fade"
          > */}
            <Switch>
              <Route exact path={"/"} component={LoginScreen} />
              {showMenuRoutes.map((route, index) => {
                return (
                  <Route
                    exact
                    path={route.path}
                    component={route.component}
                    key={index}
                  />
                );
              })}

              <Route exact path={"/qr-menu/:id"} component={OutletMenu} />
              <Route exact path={"/get-details"} component={GetItemsDetails} />
              {authRoutes.map((route, index) => {
                return (
                  <Route
                    exact
                    path={route.path}
                    component={route.component}
                    key={index}
                  />
                );
              })}
              {(!(user_detail === null || user_detail === undefined) ||
                !(
                  server_user_detail === null ||
                  server_user_detail === undefined
                )) &&
                tableRoutes.map((route, index) => {
                  return (
                    <Route
                      exact
                      path={route.path}
                      component={route.component}
                      key={index}
                    />
                  );
                })}
              <Route component={NotFoundPage} />
            </Switch>
            {/* </CSSTransition>
            </TransitionGroup> */}
          </Router>
        </div>
      </Suspense>
    </PageVisibility>
  );
};
const mapStateToProps = (state) => ({
  isLogin: state.tableDetailReducer.isLogin,
  user_detail: state.authDetailReducer.user_detail,
  server_user_detail: state.authDetailReducer.server_user_detail,
  table_detail: state.tableDetailReducer.table_detail,
  show_session_expired_page: state.tableDetailReducer.show_session_expired_page,
  server_detail: state.tableDetailReducer.server_detail,
  selected_item_detail: state.tableDetailReducer.selected_item_detail,
  cart_detail: state.cartDetailReducer.cart_detail,
  loading: state.loaderReducer.loading,
  page_title: state.tableDetailReducer.page_title,
  recent_order_detail: state.cartDetailReducer.recent_order_detail,
  server_order_id: state.tableDetailReducer.server_order_id,
  order_socket_emit: state.cartDetailReducer.order_socket_emit,
  clear_cache: state.tableDetailReducer.clear_cache,
  is_payment_started: state.paymentDetailReducer.is_payment_started,
  updatedPaymentReq: state.paymentDetailReducer.updatedPaymentReq,
  paymentReq: state.paymentDetailReducer.paymentReq,
  pausePaymentProcess: state.paymentDetailReducer.pausePaymentProcess,
  recent_order_id: state.cartDetailReducer.recent_order_id,
});

const mapDispatchToProps = {
  changeOrderSocketEmit,
  changeStatusCartDetail,
  emptyCartDetail,
  emptyOrderPlaceDetail,
  showNotificationWithTimeout,
  deletRecentAndCartOrder,
  emptyTableDetail,
  getTableDetail,
  checkOpenOrder,
  getOpenOrdersByTable,
  getPaymentDetail,
};
export default connect(mapStateToProps, mapDispatchToProps)(Main);
