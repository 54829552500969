import React, { Fragment, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
// import Draggable from "react-draggable";
import { useSwipeable, config } from "react-swipeable";
import Slide from "react-reveal/Slide";
import ImageTag from "./ImageTag";
import { useSelector, useDispatch } from "react-redux";
import {
  getSelectedItemDetail,
  getEditedItemDetail,
  showNotificationWithTimeout,
} from "../../Redux/actions";
import {
  getModifierActionSign,
  getModifierActionName,
  checkNegNum,
  makeNegToPosNum,
  deepCopy,
} from "../../helpers";
import { userIcon, removeIcon } from "../../Assets";
import { TOGGLE_CART_PAGE } from "../../Redux/actions/types";

const DraggableItem = ({
  item,
  defaultimage,
  itemIndex,
  removeItemHandler,
  addBundlePopUp,
  addItemPopUp,
  ...props
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [wrapperClass, setWrapperClass] = useState("");
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isSlide, setIsSlide] = useState(false);
  const table_detail = useSelector(
    (state) => state?.tableDetailReducer?.table_detail
  );

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (!item?.is_added || (item?.is_added && item?.is_added == false)) {
        if (eventData.dir == "Left") {
          setIsSlide(true);
        } else if (eventData.dir == "Right") {
          setIsSlide(false);
        }
      }
    },
    ...config,
  });

  function DraggableEventHandler(event, data) {
    if (event.type === "touchend") {
      setPosition({ x: data.lastX < -50 ? -185 : 0, y: 0 });
      setWrapperClass("");
      // if (data.lastX <= -185) {
      //   removeItemHandler(itemIndex);
      // }
    }
    // else {
    //   setPosition({ x: data.deltaX, y: 0 });
    //   if (event.type === "touchmove") {
    //     if (data.deltaX < 0) {
    //       setWrapperClass("red");
    //     }
    //   }
    // }
  }

  function getSameBundleItemIndex(bundle) {
    let res = -1;
    if (item?.BundledMenuItem?.length > 0) {
      for (let index = 0; index < item?.BundledMenuItem?.length; index++) {
        const element = item.BundledMenuItem[index];
        if (
          element.id != bundle.id &&
          element.option_series_id == bundle.option_series_id
        ) {
          res = index;
          break;
        }
      }
    }
    return res;
  }

  function getModifierItems(item) {
    let res = null;
    if (item?.menu_item_type == "standard" && item?.modifierArray?.length > 0) {
      res = item.modifierArray
        .filter((p) => p.action != "discount")
        .map((modifier, modifierIndex) => {
          const priceTag = `(${getModifierActionSign(modifier.action)}${
            table_detail?.currency ?? ""
          }${parseFloat(modifier.unit_price).toFixed(2)})`;
          return (
            <ul className="other-options" key={modifierIndex}>
              {modifier.unit_price !== 0 && (
                <li>
                  {`${getModifierActionName(modifier.action)} ${modifier.name}`}{" "}
                  ({checkNegNum(modifier.unit_price) ? "- " : ""}
                  {`${table_detail?.currency ?? ""}`}
                  {parseFloat(
                    checkNegNum(modifier.unit_price)
                      ? makeNegToPosNum(modifier.unit_price)
                      : modifier.unit_price
                  ).toFixed(2)}
                  )
                </li>
              )}
              {modifier.unit_price === 0 && <li>{`${modifier.name}`}</li>}
            </ul>
          );
        });
    } else if (
      item?.menu_item_type == "bundled" &&
      item?.BundledMenuItem?.length > 0
    ) {
      res = item.BundledMenuItem.filter((bunItem) => {
        let filterRes = true;
        if (
          item?.hide_fixed_items_qr == "yes" &&
          bunItem?.option_series_name == "Fixed Items" &&
          bunItem?.bundledMenuitemModifers?.length == 0
        ) {
          filterRes = false;
        }
        return filterRes;
      })
        .sort(
          (a, b) => parseInt(a.option_series_id) - parseInt(b.option_series_id)
        )
        .map((bundle, bundleIndex) => {
          let modiferArr = null;
          if (bundle?.bundledMenuitemModifers?.length > 0) {
            modiferArr = bundle.bundledMenuitemModifers
              .filter((p) => p.action != "discount")
              .map((modifier, modifierIndex) => {
                const priceTag =
                  modifier.unit_price > 0
                    ? `(@${parseFloat(modifier.unit_price).toFixed(2)})`
                    : "";
                return (
                  <ul className="other-options" key={modifierIndex}>
                    <li>
                      {modifier.unit_price !== 0 && (
                        <>
                          {`${getModifierActionName(modifier.action)} ${
                            modifier.name
                          }`}{" "}
                          ({checkNegNum(modifier.unit_price) ? "- " : ""}
                          {`${table_detail?.currency ?? ""}`}
                          {parseFloat(
                            checkNegNum(modifier.unit_price)
                              ? makeNegToPosNum(modifier.unit_price)
                              : modifier.unit_price
                          ).toFixed(2)}
                          )
                        </>
                      )}
                      {modifier.unit_price === 0 && (
                        <>{`${
                          !["add", "remove", "swap"].includes(modifier.action)
                            ? `${modifier.action} `
                            : ""
                        }${modifier.name}`}</>
                      )}
                      {`${
                        modifierIndex + 1 !=
                        bundle.bundledMenuitemModifers.length
                          ? ","
                          : ""
                      }`}
                    </li>
                  </ul>
                );
              });
          }
          const bundlePriceTag =
            bundle.amount > 0
              ? `(@${parseFloat(bundle.amount).toFixed(2)})`
              : "";
          const bundleQuantityTag =
            bundle.quantity > 1 ? ` x ${parseInt(bundle.quantity)} ` : " ";
          let alreadyExist = getSameBundleItemIndex(bundle);
          const showComma =
            alreadyExist > -1 && alreadyExist < bundleIndex ? true : false;
          return (
            <ul className="other-options" key={bundleIndex}>
              <li>
                {/* {showComma && alreadyExist > -1 ? "," : ""} */}
                {!showComma ? `${bundle?.option_series_name}:` : ""}{" "}
                {bundle?.bundle_menu_item_name}
                {`${bundleQuantityTag}`}
                {`${bundlePriceTag}`}
                {modiferArr}
                {showComma && alreadyExist > -1 ? "," : ""}
                
              </li>
            </ul>
          );
        });
    }
    return res;
  }
  const checkValidImg = /\.(jpe?g|png|gif|bmp)$/i.test(item?.image);
  const itemPrice =
    item?.promotions && item?.promotions?.type !== "Re-Price"
      ? item?.promotions?.accutalPrice
      : item?.item_amount;
  const discountedTotalPrice =
    parseFloat(
      item?.promotions && item?.promotions?.type !== "Re-Price"
        ? item?.promotions?.accutalPrice < item?.promotions?.discountPerItem
          ? item?.promotions?.accutalPrice
          : item?.promotions?.discountPerItem
        : 0
    ) * item.quantity;
  return (
    <div className="draggable_bg">
      <div>
        <div className={` new_items ${wrapperClass}`} {...handlers}>
          <div
            className={` card-padding menu-box${item?.is_added == true && item?.is_server_fired == "yes"  ? " added" : ""}${
              isSlide == true ? " slide" : ""
            } Px-15`}
          >
            <div className="d-flex orderMenu px-0">
              {/* {checkValidImg && ( */}
                <figure className="menu-img">
                  <ImageTag
                    src={item.image?item.image:defaultimage}
                    defaultimage={defaultimage}
                    removeImage={true}
                    removeCol={true}
                    replaceColName={"col-9"}
                    removeColName={"col-6"}
                  />
                </figure>
              {/* )} */}
              <div
                className={"col-6 menu-content pl-0"}
              >
                <h4 className="mw-100">
                  <span 
                  // className="txt-green" 
                    style={{color:table_detail?.QrOrdering?.brand_color}}

                  >{item.quantity}x</span>{" "}
                  {item.pos_name} @{parseFloat(itemPrice).toFixed(2)}{" "}
                </h4>
                {getModifierItems(item)}
              </div>
              <div className="col-3 px-0 text-right">
                <p className="pricing-lg">
                  {" "}
                  <small className="fw-bolder">$</small>
                  {parseFloat(item.total + discountedTotalPrice).toFixed(
                    2
                  )}{" "}
                </p>
              </div>
            </div>
            <Slide duration={300} right when={isSlide}>
              <div class="col-6 edit_delete">
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    setPosition({ x: 0, y: 0 });
                    removeItemHandler(itemIndex);
                  }}
                  class="square-img delete-btn"
                >
                  <h5>Delete</h5>
                </a>
                <a
                  href="javascript:void(0);"
                  role="button"
                  onClick={() => {
                    const id = table_detail?.QrOrdering?.categorisation_preference==2?item?.sub_class_id: item?.sub_category_id

                    const selectedSubCatItemIndex =
                      table_detail?.SubCategory?.findIndex(
                        (p) => p.id == Number(id)
                      );

                    const selectedSubCatItem =
                      table_detail?.SubCategory?.[selectedSubCatItemIndex] ??
                      undefined;

                    const selectedItemIndex = selectedSubCatItem
                      ? selectedSubCatItem?.MenuItem?.findIndex(
                          (p) => p.id == item.id
                        )
                      : -1;

                    const selectedItem =
                      selectedItemIndex > -1
                        ? deepCopy(
                            selectedSubCatItem?.MenuItem?.[selectedItemIndex]
                          )
                        : undefined;
                    if (selectedItem) {
                      const accutalPrice = parseFloat(
                        selectedItem?.dinein_price?.DineIn ?? 0
                      );
                      let promoPrice = 0;
                      let discountPrice = 0;
                      if (item?.promotions) {
                        if (item?.promotions.type == "Amount") {
                          discountPrice = parseFloat(item?.promotions.amount);
                          promoPrice = accutalPrice - discountPrice;
                        } else if (item?.promotions.type == "Percentage") {
                          discountPrice =
                            (accutalPrice *
                              parseFloat(item?.promotions.amount)) /
                            100;
                          promoPrice = accutalPrice - discountPrice;
                        } else if (item?.promotions.type == "Re-Price") {
                          discountPrice = parseFloat(item?.promotions.amount);
                          promoPrice = discountPrice;
                        }
                        selectedItem.dinein_price.DineIn = promoPrice;
                        selectedItem.promotions = item?.promotions;
                      }
                      dispatch(getSelectedItemDetail(selectedItem));
                      dispatch(getEditedItemDetail(item));
                      dispatch({ type: TOGGLE_CART_PAGE, payload: true });
                      // if (selectedItem?.menu_item_type == "bundled")
                      //   history.push("/bundle-add-item");
                      // else history.push("/add-item");
                      if (item?.menu_item_type == "bundled") {
                        addBundlePopUp();
                      } else {
                        addItemPopUp();
                      }
                    } else {
                      dispatch(
                        showNotificationWithTimeout(`Invalid item`, "error")
                      );
                    }
                  }}
                  class="square-img mr-2 edit-btn"
                >
                  <h5>Edit</h5>
                </a>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DraggableItem;
